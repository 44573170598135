@use '_modules' as *;

tag-line {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  gap: $gap;
  font-weight: 400;
  letter-spacing: 5px;
  @include heading-h3;
  span {
    display: flex;
    align-items: center;
    font-size: 0.5em;
    color: white;
    grid-row: 1;
    line-height: 2;
  }
  svg {
    color: $red;
    display: block;
    width: 24px;
    height: 24px;
    margin-right: $gap;
  }
  @include break-in(800) {
    gap: $gap0;
    margin: $spacer0 0;
    span {
      letter-spacing: 1px;
    }
    svg {
      margin-right: $gap0;
    }
  }
}

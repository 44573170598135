////////////////////////////////////////////////////////////////////////////////
// Sidebar
////////////////////////////////////////////////////////////////////////////////

@use '_modules' as *;

side-bar {
  > div {
    > * {
      &:not(.white) {
        --font-colour: white;
        --heading-colour: white;
      }
      --background-colour: #{$primary};

      order: 3;
      border-radius: $border-radius;
      padding: $gutter2;
      background-color: var(--background-colour);
      border: 1px solid var(--border-colour, rgba(white, 0.2));
    }

    service-centre-finder {
      order: 1;
    }
    service-centre-finder ~ #grades-sidebar-list {
      order: 2;
    }

    .heading {
      margin-bottom: $gutter0;
      @include heading-h3();
    }

    > div.sidebar-logos {
      --background-colour: transparent;
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: 2rem;
      justify-content: center;
      img {
        max-width: 50%;
        object-fit: contain;
      }
      svg {
        max-width: 150px;
        max-height: 100px;
      }
    }
  }

  #powder-tubs,
  #greener-future,
  #liberty-powder {
    position: relative;
    overflow: hidden;
    h3 {
      span {
        display: block;
      }
    }
    div {
      z-index: 1;
      position: relative;
    }
    .background {
      opacity: 0.2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 100% 0%;
    }
    .image {
      img {
        object-fit: contain;
      }
    }
  }

  #liberty-powder {
    background: transparent;
    border: 1px solid $grey4;
    .image {
      height: 60px;
      margin-bottom: $gap2;
      img {
        width: auto;
      }
    }
  }

  #powder-tubs {
    background: $grey6;
    h3 {
      padding-bottom: 225px;
      font-size: 22px;
      span {
        color: $red;
      }
    }
    .background {
      opacity: 1;
    }
    .image {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 250px;
      width: 100%;
      img {
        width: 100%;
      }
    }
    @media (min-width: $break-medium) {
      h3 {
        padding-bottom: 300px;
      }
      .image {
        height: 350px;
      }
    }
  }

  #greener-future {
    --greener-green: #4ecb3d;
    h3 {
      margin-bottom: $gap2;
      span {
        color: var(--greener-green);
        line-height: 1;
      }
    }

    .image {
      height: 90px;
      img {
        width: auto;
      }
    }

    ul {
      margin-bottom: $gap;
      li {
        color: white;
        padding-left: $gap2;
        position: relative;
        margin-bottom: $gap;
        &:before {
          background: var(--greener-green);
          border-radius: 50%;
          height: 20px;
          width: 20px;
          position: absolute;
          top: 4px;
          left: 0;
          content: '';
        }
        &:after {
          height: 12px;
          width: 6px;
          content: '';
          border-bottom: 2px solid white;
          border-right: 2px solid white;
          position: absolute;
          left: 7px;
          top: 6px;
          transform: rotate(45deg);
        }
      }
    }
    @media (min-width: $break-medium) {
      ul {
        column-count: 2;
      }
    }
  }
}

.sidebar-block\:local-cta {
  background-color: #676767;
  color: white;
  background-repeat: no-repeat;
  background-position: -1rem bottom;
  background-size: auto 220px;
  display: flex;
  flex-direction: column;
  gap: var(--gutter0);
  p {
    font-size: 2rem;
    text-wrap: pretty;
  }
  @media (width > 1400px) {
    background-image: url('/assets/uploads/images/20mph-sign.png');
    padding-inline-start: 160px;
  }
}

.sidebar-block\:iso {
  background-color: #74bf03;
  border: initial;
  padding: 0;
  object-fit: cover;
  object-position: center center;
  border-radius: $border-radius;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
  }
}

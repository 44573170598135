@use '_modules' as *;

.text {

  a {
    --font-colour : #{$primary}; 
    @include hover { --font-colour : #{$secondary}; }
    a.button { text-decoration: none !important;}
  }

  ul { @include list-ul }
  ol { @include list-ol }

  p:empty, p > br { display:none; }

  > * {
    &:not(:last-child) { margin-bottom:$gap; }
    // &:first-child {
    //   ~ h2, ~ h3, ~ h4, ~ h5, ~ h6 {
    //     margin-top:$spacer0; 
    //   }
    // }
    ul, ol { margin-top:$gap; }
  }

  h2, h3, h4, h5, h6 {
    margin-bottom:$gap; 
  }

  .heading-h3 { color:$secondary; }
  [style*="text-align: center"] {
    width:100%; 
    max-width:800px; 
    margin-left:auto;
    margin-right:auto;
  }

  .quotes {
    border-left:5px solid $grey4; 
    padding-left:$gap; 
    font-style: italic;
  }

  figure {
    display: block;
    border-radius: $border-radius;
    background-color:white;
    padding:$gap; 
    img { 
      max-width: 100%;
    }
  }

  hr { margin-top:$spacer0 !important; margin-bottom:$spacer0 !important; }

  .note {
    margin:$gap2 0; 
  }

}

// Dark Theme ==================================================================

.dark {

  .text {

    color:white; 
    --heading-colour:white; 

    a { 
      &.button { --font-colour : white; }
      &:not(.button) { 
        --font-colour : #{rgba(white, 0.7)}; 
        text-decoration:underline; 
        text-underline-offset:2px; 
        @include hover { --font-colour : white; }
      }
    }
  }

  --hr-colour : #{rgba(white, 0.3)}; 
  --list-style-colour : white; 

  &.red {
    .text ul { @include list-style($white, $red) }
  }

  &.blue {
    .text ul { @include list-style($white, $blue) }
  }

}

.contains-svgs{

  .text{
    figure{
      display: block;
      margin: 0 auto;
      padding: 0 var(--gap);
      img{
        height: 120px;
      }
    }
  }
}
@use 'sass:math';

@use '_modules' as *;

footer#footer {
  --font-colour: white;

  font-size: font-size(14);
  padding-top: $spacer;
  padding-bottom: $spacer;
  background-color: $primary;
  grid-gap: $gutter;
  display: grid;
  position: relative;
  align-items: flex-start;
  grid-template-columns: 1fr 300px;

  a {
    text-decoration: none;
    position: relative;
    display: block;
    color: var(--font-colour);
    @include hover('.current') {
      color: $red-light;
    }
    &.current {
      pointer-events: none;
    }
  }

  .back-to-top {
    width: fit-content;
    margin: $gap- auto $gap auto;
    @include break-out(small) {
      display: none;
    }
  }

  .tag-line-sign-off {
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
    margin-bottom: $spacer0;
    @include heading-h2;
    tag-line {
      span {
        @include responsive-fontsize(12px, 0.4em);
      }
      @include break-in(900) {
        flex-direction: row;
        margin: 0;
        @include break-in(600) {
          flex-direction: column;
          gap: $gap0;
          span {
            font-size: 16px;
          }
        }
      }
    }
  }

  > nav {
    display: grid;
    flex-grow: 1;
    grid-gap: $gutter;
    align-items: flex-start;
    grid-template-columns: 1fr;

    ul {
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
      li + li {
        margin-bottom: $gap0;
      }
      &:last-of-type {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(333px, 1fr));
        grid-gap: 0 $gap;
        li:first-of-type {
          grid-column: 1 / -1;
        }
      }
    }
  }

  // Heading styling

  .heading {
    margin-bottom: $gap;
    color: $secondary;
    font-weight: bold;
    @include heading-h4;
    @include font-crop-remove;
    a {
      color: $secondary;
    }
  }

  company-details {
    display: flex;
    flex-direction: colunm;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $gap0 $gap;

    small,
    .small {
      font-size: font-size(10);
      color: rgba(white, 0.7);
      br {
        display: none;
      }
    }
  }

  .base {
    margin-top: $spacer;
    grid-column: 1 / -1;
    position: relative;
    display: flex;
    justify-content: space-between;

    a.logo {
      width: 100%;
      max-width: 240px;
      position: initial;
      img {
        display: block;
      }
      @include break-out(small-medium) {
        &::after {
          $bar-height: 3px;
          content: '';
          background-color: $red;
          height: $bar-height;
          width: 100%;
          position: absolute;
          bottom: math.div(-$bar-height, 2);
          left: 0;
        }
      }
    }

    a.yello {
      display: flex;
      position: absolute;
      top: calc(100% + #{$gap});
      font-size: font-size(14);
      right: 0;
      color: rgba(white, 0.5);
      transition: color $transition;
      span {
        margin-left: 0.3em;
        transition: color $transition;
      }
      @include hover {
        color: white;
        span {
          color: $yello;
        }
      }
    }

    admin-links {
      display: flex;
      position: absolute;
      top: calc(100% + #{$gap});
      left: 0;
      a {
        font-size: 12px;
        margin-right: $gap0;
      }
    }

    &:has(.cep-logo) {
      --logo-spacer: 200px;

      .cep-logo {
        filter: grayscale(1) invert(1);
      }
      @include break-out(small-medium) {
        margin-right: var(--logo-spacer);
        .cep-logo {
          position: absolute;
          height: calc(100% + #{$gap2});
          top: 0;
          right: calc(var(--logo-spacer) * -1);
        }
      }
      @include break-in(small-medium) {
        .cep-logo {
          margin-bottom: $gutter2;
        }
      }
    }
  }

  @include break-in(1400) {
    > nav {
      ul:not(:last-of-type) {
        display: none;
      }
    }

    company-details small br {
      display: none;
    }

    // company-details, > nav ul {
    // 	padding:$gap2;
    // 	border-radius: $border-radius;
    // 	background-color:rgba($blue-dark, 0.5);
    // }
  }

  @include break-in(small-medium) {
    grid-template-columns: 1fr;
    .base {
      flex-direction: column;
      align-items: center;
      a.logo {
        order: 1;
        width: 160px;
        img {
          max-width: 100%;
        }
      }
      social-media {
        margin-top: $spacer0-;
        margin-bottom: $spacer0;
      }
      admin-links {
        order: 3;
        position: initial;
        margin-top: $gap;
      }
    }
  }
}

@use '../_variables' as *;

@mixin styling() {

  // the scrollbar.
  &::-webkit-scrollbar {
    width:0.9em;
    height:0.9em;
  }

  // the buttons on the scrollbar (arrows pointing upwards and downwards).
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  // the draggable scrolling handle.
  &::-webkit-scrollbar-thumb {
    background-color: $grey;
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.15);
  }

  // the track (progress bar) of the scrollbar.
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  // the track (progress bar) NOT covered by the handle.
  &::-webkit-scrollbar-track-piece {

  }

  // the bottom corner of the scrollbar, where both horizontal and vertical scrollbars meet.
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  // the draggable resizing handle that appears at the bottom corner of some elements.
  &::-webkit-resizer {

  }

  @content;

}
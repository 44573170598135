@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p,
blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em,
img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i,
center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table,
caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

:root {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}

*, *:after, *:before {
  box-sizing: inherit;
}
*:focus, *:after:focus, *:before:focus {
  outline: 0;
}

html {
  height: 100%;
  font-size: 62.5%;
  block-size: 100%;
  inline-size: 100%;
}

body {
  min-block-size: 100%;
  min-inline-size: 100%;
}

html, body {
  min-height: 100%;
}

p:empty, pre:empty, blockquote:empty, ol:empty, ul:empty, li:empty, legend:empty, figcaption:empty, body:empty, article:empty, nav:empty, aside:empty, section:empty, header:empty, footer:empty, address:empty {
  display: none;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

svg, symbol {
  overflow: visible;
}

details {
  display: block;
}

summary {
  display: list-item;
  -webkit-appearance: none;
}
summary::-webkit-details-marker, summary::marker {
  display: none;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  width: 100%;
}

address {
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  font-weight: normal;
}

hr {
  border: none;
  height: 1px;
  box-sizing: content-box;
  overflow: visible;
}

blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
  background-color: white;
  color: black;
  padding: 16px;
  position: relative;
  z-index: 9999;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  touch-action: manipulation;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
button:focus,
[type=button]:focus,
[type=reset]:focus,
[type=submit]:focus {
  outline: 0;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[disabled] {
  cursor: default;
}

img {
  border-style: none;
}

em {
  font-style: italic;
}

a {
  background-color: transparent;
  text-decoration: none;
  touch-action: manipulation;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bold;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

textarea {
  resize: none;
  overflow: auto;
}

pre {
  background-color: white;
  color: black;
  text-transform: none;
  text-decoration: none;
  text-align: left;
  display: block;
  font-size: 16px;
  padding: 16px;
  position: relative;
  z-index: 999;
}

body.devmode img:not([alt]) {
  border: 2px red dashed;
}

@media (min-width: 321px) {
  body {
    overflow-x: hidden;
  }
}

:where(*) {
  scroll-margin-top: var(--navigation-height, 2rem);
}

.async-hide {
  opacity: 0 !important;
}

main:empty {
  display: none;
}

figure.image,
picture.image {
  display: block;
  background-position: var(--position, center 33%);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
}
figure.image img,
picture.image img {
  display: block;
  position: absolute;
  object-fit: cover;
  object-position: var(--image-position, center 33.33%);
  width: var(--image-size, var(--image-width-size, 100%));
  height: var(--image-size, var(--image-height-size, 100%));
  max-width: var(--image-size, var(--image-width-size, 100%));
  max-height: var(--image-size, var(--image-height-size, 100%));
  top: 0;
  left: 0;
}

video-container {
  --aspect-ratio: 16/9;
  aspect-ratio: var(--aspect-ratio);
  display: block;
  position: relative;
  width: 100%;
  background-color: black;
}
@supports not (aspect-ratio: 16/9) {
  video-container {
    aspect-ratio: initial;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
    height: 0;
  }
  video-container > * {
    position: absolute;
  }
}
video-container iframe, video-container canvas {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100% !important;
  width: 100% !important;
}

mark {
  background-color: #0095FF;
  color: white;
  border-radius: calc(var(--border-radius, 6px) / 2);
  padding: 0 5px;
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), background-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}

details {
  outline: none;
}

summary {
  -webkit-appearance: none;
}
summary::-webkit-details-marker, summary::marker {
  display: none;
}

.button {
  --font-colour: white;
  --background-colour: var(--primary);
  --border-colour: transparent;
  --padding: 8px 20px;
  --font-size: 16px;
  --border-radius: 50px;
  --hover-font-colour: var(--primary);
  --hover-background-colour: white;
  --hover-border-colour: initial;
  --hover-padding: var(--padding);
  --hover-font-size: var(--font-size);
  --hover-border-radius: var(--border-radius);
  --icon-size: 1.1em;
  cursor: pointer;
  display: inline-flex;
  position: relative;
  user-select: none;
  border-style: solid;
  border-width: 1px;
  word-break: break-all;
  font-weight: bold;
  place-content: center;
  place-items: center;
  align-self: flex-start;
  text-transform: uppercase;
  color: var(--font-colour);
  background-color: var(--background-colour);
  border-color: var(--border-colour);
  padding: var(--padding);
  font-size: var(--font-size);
  border-radius: var(--border-radius);
  transition: background-color var(--transition-duration) var(--transition-ease), color var(--transition-duration) var(--transition-ease), border var(--transition-duration) var(--transition-ease);
}
.button svg {
  width: var(--icon-size);
  height: var(--icon-size);
  order: 3;
  fill: var(--fill, currentColor);
  margin-left: calc(var(--icon-size) / 2);
  transition: fill var(--transition-duration) var(--transition-ease), stroke var(--transition-duration) var(--transition-ease);
}
.button svg[class*=arrow] {
  --icon-size: 0.9em;
}
.button svg.arrow-left {
  margin-right: calc(var(--icon-size) / 2);
  margin-left: 0 !important;
  order: 1;
}
.button > span {
  order: 2;
}
.button.small {
  --padding: 6px 18px;
  --font-size: 12px;
}
.button.small svg {
  margin-left: calc(var(--gap) * 0.5);
}
.button.small svg.arrow-left {
  margin-right: calc(var(--gap) * 0.5);
}
.button.large {
  --padding: 12px 24px;
  --font-size: 24px;
}
.button.outline {
  --background-colour: transparent;
  --font-colour: #131f6b;
  --border-colour: #131f6b;
  --hover-background-colour: #131f6b;
  --hover-font-colour: #131f6b;
  --hover-border-colour: transparent;
}
.button.blue {
  --background-colour: #131f6b;
  --font-colour: white;
  --hover-background-colour: white;
  --hover-font-colour: #131f6b;
}
.button.blue.outline {
  --background-colour: transparent;
  --font-colour: #131f6b;
  --border-colour: #131f6b;
  --hover-background-colour: #131f6b;
  --hover-font-colour: #FFFFFF;
  --hover-border-colour: transparent;
}
.button.red {
  --background-colour: #DF3035;
  --font-colour: white;
  --hover-background-colour: white;
  --hover-font-colour: #DF3035;
}
.button.red.outline {
  --background-colour: transparent;
  --font-colour: #DF3035;
  --border-colour: #DF3035;
  --hover-background-colour: #DF3035;
  --hover-font-colour: #FFFFFF;
  --hover-border-colour: transparent;
}
.button.green {
  --background-colour: #76bb28;
  --font-colour: white;
  --hover-background-colour: white;
  --hover-font-colour: #76bb28;
}
.button.green.outline {
  --background-colour: transparent;
  --font-colour: #76bb28;
  --border-colour: #76bb28;
  --hover-background-colour: #76bb28;
  --hover-font-colour: #FFFFFF;
  --hover-border-colour: transparent;
}
.button.white {
  --background-colour: #FFFFFF;
  --font-colour: var(--primary, #131f6b);
  --hover-background-colour: var(--primary, #131f6b);
  --hover-font-colour: #FFFFFF;
}
.button.white.outline {
  --background-colour: transparent;
  --font-colour: #FFFFFF;
  --border-colour: #FFFFFF;
  --hover-background-colour: #FFFFFF;
  --hover-font-colour: var(--primary, #131f6b);
  --hover-border-colour: transparent;
}
.button.disabled {
  pointer-events: none;
  --background-colour: #dcdcdc;
  --font-colour: white;
  --hover-background-colour: white;
  --hover-font-colour: #dcdcdc;
}
.button.disabled.outline {
  --background-colour: transparent;
  --font-colour: #dcdcdc;
  --border-colour: #dcdcdc;
  --hover-background-colour: #dcdcdc;
  --hover-font-colour: #dcdcdc;
  --hover-border-colour: transparent;
}
@media (hover: hover) {
  .button:hover {
    color: var(--hover-font-colour);
    background-color: var(--hover-background-colour);
    border-color: var(--hover-border-colour);
    border-radius: var(--hover-border-radius);
    padding: var(--hover-padding);
    font-size: var(--hover-font-size);
  }
}
.button.brochure {
  --font-colour: #131f6b;
  --background-colour: transparent;
  --padding: 5px 0px;
  --icon-size: 32px;
  --border-colour: transparent;
  --border-radius: 0;
  --hover-font-colour: #DF3035;
  --hover-border-colour: transparent;
  --hover-background-colour: transparent;
  flex-direction: row-reverse;
  text-transform: uppercase;
}
.button.brochure svg {
  margin-right: calc(var(--gap) * 0.5);
  margin-left: 0;
  fill: #DF3035;
}
.button.red svg.tick, .button.red svg.download {
  --stroke: #DF3035;
}
.button.blue svg.tick, .button.blue svg.download {
  --stroke: #131f6b;
}
.button.outline svg.tick, .button.outline svg.download {
  --stroke: #FFFFFF;
}
@media (hover: hover) {
  .button:hover.red svg.tick,
  .button:hover.red svg.download {
    --stroke: #FFFFFF;
  }
  .button:hover.red.outline svg.tick, .button:hover.red.outline svg.download {
    --stroke: #DF3035;
  }
  .button:hover.blue svg.tick,
  .button:hover.blue svg.download {
    --stroke: #FFFFFF;
  }
  .button:hover.blue.outline svg.tick, .button:hover.blue.outline svg.download {
    --stroke: #131f6b;
  }
  .button:hover.white svg.tick, .button:hover.white svg.download {
    --stroke: #131f6b;
  }
}

button-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
}
button-container .button {
  white-space: nowrap;
  margin: calc(var(--gap) * 0.5);
}

.sticky {
  position: sticky;
  top: 0px;
}

input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-results-button, input[type=search]::-webkit-search-results-decoration {
  display: none;
  -webkit-appearance: none;
}

#holding-page header svg {
  max-width: 400px;
}
@media (max-width: 980px) {
  #holding-page {
    display: flex;
    flex-direction: column;
    padding: var(--gutter1);
  }
}

body#error-404 header h2 {
  max-width: 900px;
}
body#error-404 search-bar {
  margin-bottom: var(--spacer0);
}
body#error-404 search-bar input[type=search] {
  border: 1px solid #131f6b;
}

body > .back-to-top {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: var(--gutter1);
  right: var(--gutter1);
  background: #292B68;
  border: 2px solid white;
  border-radius: 50%;
  z-index: 9999;
  opacity: 0;
  display: flex;
  pointer-events: none;
  transition: opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), background-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), bottom var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  place-content: center;
  place-items: center;
}
body > .back-to-top svg {
  position: relative;
  color: white;
  width: 50%;
  top: -4%;
}
@media (hover: hover) {
  body > .back-to-top:hover {
    background-color: #DF3035;
  }
}

footer#footer.in-view ~ .back-to-top {
  bottom: 120px;
}

navigation-bar:not(.in-view) ~ .back-to-top {
  opacity: 1;
  pointer-events: all;
}

:root {
  --primary: #131f6b;
  --secondary: #DF3035;
  --breakpoint-min: 320px;
  --breakpoint-small: 480px;
  --breakpoint-small-medium: 980px;
  --breakpoint-medium: 1120px;
  --breakpoint-large: 1400px;
  --breakpoint-max: 1680px;
  --font-colour: #6A6A6A;
  --heading-colour: var(--primary, #131f6b);
  --sub-heading-colour: var(--secondary, #DF3035);
  --transition-duration: 0.2s;
  --transition-ease: cubic-bezier(0.42, 0, 0.58, 1);
  --transition-delay: ;
  --breadcrumb-height: 90px;
  --anchors-height: 60px;
  --breadcrumb-and-anchors-sticky-underset: -15px;
  --main-max-width: 900px;
  --navigation-height: 143px;
  --sidebar-width: clamp(300px, 30vw, 500px);
  --gap: 16px;
  --gutter: 3vw;
  --spacer: 4vh;
  --gutter0: clamp(8px, var(--gutter) * 0.5, 12px);
  --gutter0-: calc(-1 * clamp(8px, var(--gutter) * 0.5, 12px));
  --spacer0: clamp(16px, var(--spacer), 32px);
  --spacer0-: calc(-1 * clamp(16px, var(--spacer), 32px));
  --gutter1: clamp(16px, var(--gutter), 32px);
  --gutter1-: calc(-1 * clamp(16px, var(--gutter), 32px));
  --spacer1: clamp(16px, var(--spacer) * 3, 64px);
  --spacer1-: calc(-1 * clamp(16px, var(--spacer) * 3, 64px));
  --gutter2: clamp(16px, var(--gutter) * 2, 48px);
  --gutter2-: calc(-1 * clamp(16px, var(--gutter) * 2, 48px));
  --spacer2: clamp(16px, var(--spacer) * 6, 112px);
  --spacer2-: calc(-1 * clamp(16px, var(--spacer) * 6, 112px));
  --gutter3: clamp(16px, var(--gutter) * 3, 64px);
  --gutter3-: calc(-1 * clamp(16px, var(--gutter) * 3, 64px));
  --spacer3: clamp(16px, var(--spacer) * 9, 160px);
  --spacer3-: calc(-1 * clamp(16px, var(--spacer) * 9, 160px));
  --gutter4: clamp(16px, var(--gutter) * 4, 80px);
  --gutter4-: calc(-1 * clamp(16px, var(--gutter) * 4, 80px));
  --spacer4: clamp(16px, var(--spacer) * 12, 208px);
  --spacer4-: calc(-1 * clamp(16px, var(--spacer) * 12, 208px));
  --gutter5: clamp(16px, var(--gutter) * 5, 96px);
  --gutter5-: calc(-1 * clamp(16px, var(--gutter) * 5, 96px));
  --spacer5: clamp(16px, var(--spacer) * 15, 256px);
  --spacer5-: calc(-1 * clamp(16px, var(--spacer) * 15, 256px));
  --gutter6: clamp(16px, var(--gutter) * 6, 112px);
  --gutter6-: calc(-1 * clamp(16px, var(--gutter) * 6, 112px));
  --spacer6: clamp(16px, var(--spacer) * 18, 304px);
  --spacer6-: calc(-1 * clamp(16px, var(--spacer) * 18, 304px));
  --computed-gutter: calc(((100vw - var(--breakpoint-max)) / 2));
}
@supports not (width: clamp(1px, 5vw, 10px)) {
  :root {
    --sidebar-width: 500px;
  }
  @media (max-width: 1400px) {
    :root {
      --sidebar-width: 300px;
    }
  }
}
@supports not (width: clamp(1px, 5vw, 10px)) {
  :root {
    --gutter0: calc((var(--gutter) * 0.5) + 8px);
    --gutter0-: calc(-1 * calc((var(--gutter) * 0.5) + 8px));
    --spacer0: calc((var(--spacer) * 2) + 16px);
    --spacer0-: calc(-1 * calc((var(--spacer) * 2) + 16px));
    --gutter1: calc((var(--gutter) * 1) + 16px);
    --gutter1-: calc(-1 * calc((var(--gutter) * 1) + 16px));
    --spacer1: calc((var(--spacer) * 4) + 16px);
    --spacer1-: calc(-1 * calc((var(--spacer) * 4) + 16px));
    --gutter2: calc((var(--gutter) * 2) + 16px);
    --gutter2-: calc(-1 * calc((var(--gutter) * 2) + 16px));
    --spacer2: calc((var(--spacer) * 8) + 16px);
    --spacer2-: calc(-1 * calc((var(--spacer) * 8) + 16px));
    --gutter3: calc((var(--gutter) * 3) + 16px);
    --gutter3-: calc(-1 * calc((var(--gutter) * 3) + 16px));
    --spacer3: calc((var(--spacer) * 12) + 16px);
    --spacer3-: calc(-1 * calc((var(--spacer) * 12) + 16px));
    --gutter4: calc((var(--gutter) * 4) + 16px);
    --gutter4-: calc(-1 * calc((var(--gutter) * 4) + 16px));
    --spacer4: calc((var(--spacer) * 16) + 16px);
    --spacer4-: calc(-1 * calc((var(--spacer) * 16) + 16px));
    --gutter5: calc((var(--gutter) * 5) + 16px);
    --gutter5-: calc(-1 * calc((var(--gutter) * 5) + 16px));
    --spacer5: calc((var(--spacer) * 20) + 16px);
    --spacer5-: calc(-1 * calc((var(--spacer) * 20) + 16px));
    --gutter6: calc((var(--gutter) * 6) + 16px);
    --gutter6-: calc(-1 * calc((var(--gutter) * 6) + 16px));
    --spacer6: calc((var(--spacer) * 24) + 16px);
    --spacer6-: calc(-1 * calc((var(--spacer) * 24) + 16px));
  }
}

@font-face {
  font-family: "lato";
  src: url("../../../assets/fonts/lato-bold.woff2") format("woff2"), url("../../../assets/fonts/lato-bold.woff") format("woff"), url("../../../assets/fonts/lato-bold.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "lato";
  src: url("../../../assets/fonts/lato-semibold.woff2") format("woff2"), url("../../../assets/fonts/lato-semibold.woff") format("woff"), url("../../../assets/fonts/lato-semibold.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "lato";
  src: url("../../../assets/fonts/lato-regular.woff2") format("woff2"), url("../../../assets/fonts/lato-regular.woff") format("woff"), url("../../../assets/fonts/lato-regular.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "montserrat";
  src: url("../../../assets/fonts/montserrat-medium.woff2") format("woff2"), url("../../../assets/fonts/montserrat-medium.woff") format("woff"), url("../../../assets/fonts/montserrat-medium.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "montserrat";
  src: url("../../../assets/fonts/montserrat-medium-italic.woff2") format("woff2"), url("../../../assets/fonts/montserrat-medium-italic.woff") format("woff"), url("../../../assets/fonts/montserrat-medium-italic.otf") format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: "montserrat";
  src: url("../../../assets/fonts/montserrat-semibold.woff2") format("woff2"), url("../../../assets/fonts/montserrat-semibold.woff") format("woff"), url("../../../assets/fonts/montserrat-semibold.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}
body {
  font-family: montserrat, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--font-colour, #6A6A6A);
  line-height: 1.6;
  font-size: 1.5rem;
}
@supports (width: clamp(1px, 5vw, 10px)) {
  body {
    font-size: clamp(1.4rem, 1.5vw, 1.6rem);
  }
}
@supports not (width: clamp(1px, 5vw, 10px)) {
  @media (max-width: 950px) {
    body {
      font-size: 1.4rem;
    }
  }
  @media (min-width: 1601px) {
    body {
      font-size: 1.6rem;
    }
  }
}

h1 {
  font-size: 3.8125em;
  font-size: clamp(2.5em, 5vw, 3.8125em);
  line-height: 1.2;
}

h2 {
  font-size: 2em;
  line-height: 1.3;
}

h3 {
  font-size: 1.5625em;
  line-height: 1.6;
}

h4 {
  font-size: 1.0625em;
  line-height: 1.6;
}

h5 {
  font-size: 1.0625em;
  line-height: 1.6;
}

h6 {
  font-size: 1.0625em;
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: lato, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--heading-colour, var(--font-colour));
  text-wrap: pretty;
}
h1.sub-heading,
h2.sub-heading,
h3.sub-heading,
h4.sub-heading,
h5.sub-heading,
h6.sub-heading {
  color: var(--sub-heading-colour);
  font-family: lato, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 span,
h1 strong,
h1 p,
h2 span,
h2 strong,
h2 p,
h3 span,
h3 strong,
h3 p,
h4 span,
h4 strong,
h4 p,
h5 span,
h5 strong,
h5 p,
h6 span,
h6 strong,
h6 p {
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
}

q,
blockquote {
  quotes: "“" "”" "‘" "’";
}
q::before,
blockquote::before {
  content: open-quote;
}
q::after,
blockquote::after {
  content: close-quote;
}

a {
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  text-decoration: none;
  text-underline-position: under;
  color: var(--font-colour);
}
a.external {
  display: inline-flex;
  align-items: center;
}
a.external svg.external-link {
  opacity: 0.6;
  width: 0.8em;
  height: 0.8em;
  margin-left: 0.5em;
}

ol,
ul {
  list-style: none;
  list-style-position: inside;
}

li a {
  text-decoration: underline;
  text-underline-offset: 2px;
}

small {
  font-size: 0.625em;
  line-height: 1.8;
}

hr {
  background-color: var(--hr-colour, #dcdcdc);
  margin-top: var(--spacer0);
  margin-bottom: var(--spacer0);
}

.note {
  position: relative;
  display: block;
  background-color: #fff5bf;
  border: 1px solid #ffe13c;
  padding: var(--gutter1);
  border-radius: var(--border-radius, 6px);
}
.note a {
  text-decoration: underline;
  color: #978002;
}
.note::before {
  content: "Please Note";
  text-transform: uppercase;
  position: relative;
  font-size: 10px;
  font-weight: bold;
  color: #978002;
  display: block;
  margin-bottom: calc(var(--gap) * 0.5);
}

body {
  --row-navigation: 1;
  --row-breadcrumbs: 2;
  --row-anchors: 3;
  --row-header: 4;
  --row-main: 5;
  --row-background: 1;
  --row-sidebar: 4 / span 3;
  --row-footer: 100;
  --rows: auto;
  --column-left: 2;
  --column-main: 2 / span 2;
  --column-right: 4;
  --column-full: 2 / -2;
  --column-gutter: minmax(0, 1fr);
  --column-content: minmax(0, calc(1680px - (var(--sidebar-width) * 2) - var(--gutter4)));
  --column-sidebar: var(--sidebar-width);
  --columns: var(--column-gutter) var(--column-sidebar) var(--column-content) var(--column-sidebar) var(--column-gutter);
  display: grid;
  min-width: 320px;
  grid-gap: 0 var(--gutter1);
  grid-template-rows: var(--rows);
  grid-template-columns: var(--columns);
}
body > * {
  grid-column: var(--column-full);
}

navigation-bar {
  z-index: 999;
  grid-row: var(--row-navigation);
}
navigation-bar:not(.in-view) ~ #breadcrumbs-bar,
navigation-bar:not(.in-view) ~ #anchors-bar {
  --anchors-height: 50px;
}

#breadcrumbs-bar {
  z-index: 2;
  grid-row: var(--row-breadcrumbs);
  height: var(--breadcrumb-height);
}
#breadcrumbs-bar.sticky {
  top: var(--breadcrumb-and-anchors-sticky-underset);
}

#anchors-bar {
  z-index: 2;
  grid-row: var(--row-anchors);
  height: var(--anchors-height);
}
#anchors-bar.sticky {
  top: calc(var(--breadcrumb-height) - 1px + var(--breadcrumb-and-anchors-sticky-underset) * 2);
}

.header {
  grid-row: var(--row-header);
  grid-column: var(--column-main);
  max-width: var(--main-max-width);
}

#footer {
  grid-row: var(--row-footer);
}

main {
  grid-row: var(--row-main);
  grid-column: var(--column-main);
  max-width: var(--main-max-width);
  display: flex;
  flex-direction: column;
}
main > *,
main ~ section,
main ~ aside,
main ~ div,
main ~ service-centre-finder,
main ~ markets-summary {
  position: relative;
  padding-top: var(--spacer1);
  padding-bottom: var(--spacer1);
  flex-grow: 1;
}
main ~ div:has(div#truendo_container) {
  padding: 0;
}
main service-centre-finder picture {
  --translateX: 0;
  width: calc(100vw + var(--gutter1));
  left: var(--gutter1-);
}
@media (min-width: 1681px) {
  main service-centre-finder picture {
    left: calc((-1 * var(--computed-gutter)) - var(--gutter1));
  }
}

side-bar {
  grid-row: var(--row-sidebar);
  grid-column: var(--column-right);
  width: var(--sidebar-width);
  padding-top: var(--spacer1);
  padding-bottom: var(--spacer1);
}
side-bar > div {
  display: grid;
  grid-gap: var(--gutter1) var(--gutter1);
  grid-template-columns: repeat(1, minmax(var(--sidebar-width), 1fr));
}
side-bar > div.sticky {
  top: calc(var(--breadcrumb-height) + var(--anchors-height) + var(--gutter1));
}
side-bar > div > * {
  position: relative;
}
side-bar > div > *.red {
  --background-colour: #DF3035;
}
side-bar > div > *.blue {
  --background-colour: #131f6b;
}
side-bar > div > *.grey {
  --background-colour: #F4F4F4;
  --border-colour: #dcdcdc;
}
side-bar > div > *.white {
  --background-colour: #FFFFFF;
  --border-colour: #F4F4F4;
}

aside#support-information .glossary-terms,
tabbed-content[data-style="1"] > nav {
  top: calc(var(--breadcrumb-height) + var(--anchors-height) + var(--gutter1));
}

@media (max-width: 980px) {
  body {
    --columns: var(--column-gutter) var(--column-content) var(--column-gutter);
    --column-main: var(--column-full);
    --column-left: var(--column-full);
    --column-right: var(--column-full);
    --row-sidebar: auto;
  }
  body > * {
    width: 100%;
  }
  body side-bar {
    padding-top: var(--spacer1);
  }
  body side-bar > div {
    grid-gap: var(--spacer1) var(--gutter1);
  }
  body side-bar > div.sticky {
    position: relative;
    top: initial;
  }
}
@media (max-width: 352px) {
  body {
    --sidebar-width: 100%;
  }
}
.themed.red {
  background: #DF3035;
}
.themed.red.blowout + .themed.red {
  box-shadow: 0px 1px var(--border-top-colour);
}
.themed.red.blowout + .themed.red.light {
  --border-top-colour: #dcdcdc;
}
.themed.red.blowout + .themed.red.dark {
  --border-top-colour: rgba(255, 255, 255, 0.2);
}
.themed.blue {
  background: #131f6b;
}
.themed.blue.blowout + .themed.blue {
  box-shadow: 0px 1px var(--border-top-colour);
}
.themed.blue.blowout + .themed.blue.light {
  --border-top-colour: #dcdcdc;
}
.themed.blue.blowout + .themed.blue.dark {
  --border-top-colour: rgba(255, 255, 255, 0.2);
}
.themed.grey {
  background: #F4F4F4;
}
.themed.grey.blowout + .themed.grey {
  box-shadow: 0px 1px var(--border-top-colour);
}
.themed.grey.blowout + .themed.grey.light {
  --border-top-colour: #dcdcdc;
}
.themed.grey.blowout + .themed.grey.dark {
  --border-top-colour: rgba(255, 255, 255, 0.2);
}
.themed.white {
  background: #FFFFFF;
}
.themed.white.blowout + .themed.white {
  box-shadow: 0px 1px var(--border-top-colour);
}
.themed.white.blowout + .themed.white.light {
  --border-top-colour: #dcdcdc;
}
.themed.white.blowout + .themed.white.dark {
  --border-top-colour: rgba(255, 255, 255, 0.2);
}
.themed:not(.blowout) {
  padding-left: var(--gutter1);
  padding-right: var(--gutter1);
  margin-bottom: var(--spacer1);
  margin-top: var(--spacer1);
  border-radius: var(--border-radius, 6px);
}
.themed:not(.blowout).white {
  border: 1px solid #F4F4F4;
}
.themed:not(.blowout).white + .blowout.white,
.themed:not(.blowout).white + :not(.themed) {
  margin-top: var(--spacer1-);
}

.blowout:before {
  content: "";
  background-color: inherit;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 100%;
  transform: translateX(var(--translateX, -50%));
  background: inherit;
}
@media (max-width: 336px) {
  .blowout:before {
    transform: initial;
    left: calc(var(--gap) * -1);
    width: 100vw;
  }
}
@media (max-width: 980px) {
  .blowout:before {
    transform: initial;
    left: 0;
    margin-left: var(--gutter1-);
    width: 100vw;
  }
}
@media (min-width: 981px) {
  body:not(mobile) main .blowout:before {
    --translateX: 0;
    width: calc(100vw + var(--gutter1));
    left: var(--gutter1-);
    width: calc(100vw + var(--gutter1));
  }
}
@media (min-width: 981px) and (min-width: 1681px) {
  body:not(mobile) main .blowout:before {
    left: calc((-1 * var(--computed-gutter)) - var(--gutter1));
  }
}

body.mobile main .blowout:before {
  --translateX: 0;
  width: calc(100vw + var(--gutter1));
  left: var(--gutter1-);
  width: calc(100vw + var(--gutter1));
}
@media (min-width: 1681px) {
  body.mobile main .blowout:before {
    left: calc((-1 * var(--computed-gutter)) - var(--gutter1));
  }
}

background-image,
background-video {
  z-index: -1;
  grid-row: var(--row-background);
  grid-column: 1/-1;
}
background-image.behind-header,
background-video.behind-header {
  --row-background: 4;
}

img[data-src],
video[data-src] {
  transition: opacity 0.3s ease-in;
  opacity: 0;
}
img[data-src].loaded,
video[data-src].loaded {
  opacity: 1;
}

main ~ *:not(.themed) + :not(.themed):not(#footer):not(side-bar) {
  box-shadow: 0px -1px var(--border-top-colour, #dcdcdc);
}

main > *.themed.content-block {
  box-shadow: initial;
}

main > *.themed + :not(.themed):not(#footer) {
  margin-top: 0;
  box-shadow: initial;
}

main ~ *.themed + :not(.themed):not(#footer),
body.background-positioned-behind-header main > *:first-child {
  box-shadow: initial;
}

main > *:first-child:not(.themed),
main > *:first-child.white {
  box-shadow: 0px -1px var(--border-top-colour, #dcdcdc);
}

main ~ *:not(.themed) + .themed:not(.blowout),
main > *:not(.themed) + .themed:not(.blowout) {
  margin-top: 0;
}

.header ~ main > .themed:not(.blowout):first-child {
  margin-top: var(--spacer1);
}

main > .themed:not(.blowout):last-child {
  margin-bottom: var(--spacer1);
}

main > .themed:not(.blowout) + .blowout {
  margin-top: var(--spacer1);
}

.header ~ main > .themed:not(.blowout) {
  margin-top: 0;
}

main ~ div:has(#CookiebotWidget) {
  padding-top: 0;
  padding-bottom: 0;
}

body:not([data-components*=side-bar]) {
  --main-max-width: auto;
  --column-main: var(--column-full);
}

body:not([data-components*=breadcrumbs-bar]) {
  --row-navigation: 1;
  --row-anchors: 2;
  --row-header: 3;
  --row-main: 4;
}
body:not([data-components*=breadcrumbs-bar]) background-image.full,
body:not([data-components*=breadcrumbs-bar]) background-video.full {
  --row-background: 1 / span 4;
}
body:not([data-components*=breadcrumbs-bar]) background-image.behind-header,
body:not([data-components*=breadcrumbs-bar]) background-video.full {
  --row-background: 3;
}
body:not([data-components*=breadcrumbs-bar]) #anchors-bar.sticky {
  top: 0;
}
@media (min-width: 981px) {
  body:not([data-components*=breadcrumbs-bar]) {
    --row-sidebar: 3 / span 3;
  }
  body:not([data-components*=breadcrumbs-bar]) side-bar div.sticky,
  body:not([data-components*=breadcrumbs-bar]) aside#support-information .glossary-terms,
  body:not([data-components*=breadcrumbs-bar]) tabbed-content[data-style="1"] > nav {
    top: calc(var(--anchors-height) + var(--gutter1));
  }
}

body:not([data-components*=anchors-bar]) {
  --row-navigation: 1;
  --row-breadcrumbs: 2;
  --row-header: 3;
  --row-main: 4;
}
body:not([data-components*=anchors-bar]) background-image.full,
body:not([data-components*=anchors-bar]) background-video.full {
  --row-background: 1 / span 4;
}
body:not([data-components*=anchors-bar]) background-image.behind-header,
body:not([data-components*=anchors-bar]) background-video.behind-header {
  --row-background: 3;
}
body:not([data-components*=anchors-bar]) #breadcrumbs-bar.sticky {
  top: 0;
}
@media (min-width: 981px) {
  body:not([data-components*=anchors-bar]) {
    --row-sidebar: 3 / span 3;
  }
  body:not([data-components*=anchors-bar]) side-bar div.sticky,
  body:not([data-components*=anchors-bar]) aside#support-information .glossary-terms,
  body:not([data-components*=anchors-bar]) tabbed-content[data-style="1"] > nav {
    top: calc(var(--anchors-height) + var(--gutter1));
  }
}

body:not([data-components*=breadcrumbs-bar]):not([data-components*=anchors-bar]) {
  --row-navigation: 1;
  --row-header: 2;
  --row-main: 3;
  --row-background: 1;
}
body:not([data-components*=breadcrumbs-bar]):not([data-components*=anchors-bar]) background-image.full,
body:not([data-components*=breadcrumbs-bar]):not([data-components*=anchors-bar]) background-video.full {
  --row-background: 1 / span 5;
}
body:not([data-components*=breadcrumbs-bar]):not([data-components*=anchors-bar]) background-image.behind-header,
body:not([data-components*=breadcrumbs-bar]):not([data-components*=anchors-bar]) background-video.behind-header {
  --row-background: 2;
}
@media (min-width: 981px) {
  body:not([data-components*=breadcrumbs-bar]):not([data-components*=anchors-bar]) {
    --row-sidebar: 2 / span 3;
  }
  body:not([data-components*=breadcrumbs-bar]):not([data-components*=anchors-bar]) side-bar div.sticky,
  body:not([data-components*=breadcrumbs-bar]):not([data-components*=anchors-bar]) aside#support-information .glossary-terms,
  body:not([data-components*=breadcrumbs-bar]):not([data-components*=anchors-bar]) tabbed-content[data-style="1"] > nav {
    top: var(--gutter1);
  }
}

@media (max-width: 980px) {
  body.rule-last-main-section-is-themed side-bar {
    padding-top: 0;
  }
}

.layout-content-right-sidebar, .layout-content-left-sidebar {
  display: grid;
  grid-gap: var(--gutter1);
  grid-template-columns: 1fr;
}
.layout-content-right-sidebar .left,
.layout-content-right-sidebar .right, .layout-content-left-sidebar .left,
.layout-content-left-sidebar .right {
  grid-column: 1/-1;
  max-width: 100%;
}
.layout-content-right-sidebar .layout-content-right-sidebar, .layout-content-right-sidebar .layout-content-left-sidebar, .layout-content-left-sidebar .layout-content-right-sidebar, .layout-content-left-sidebar .layout-content-left-sidebar {
  --column-content: minmax(0, calc(1680px - (var(--sidebar-width) * 2)));
}

@media (min-width: 981px) {
  body > .layout-content-left-sidebar .left {
    grid-column: 1;
  }
  body > .layout-content-left-sidebar .right {
    grid-column: 2/4;
  }
  body > .layout-content-right-sidebar .left {
    grid-column: 1/3;
    max-width: var(--main-max-width);
  }
  body > .layout-content-right-sidebar .right {
    grid-column: 3;
    padding-left: var(--gutter1);
    padding-right: var(--gutter1);
  }
  body > .layout-content-right-sidebar, body > .layout-content-left-sidebar {
    grid-template-columns: var(--column-sidebar) var(--column-content) var(--column-sidebar);
  }
}
@media (min-width: 1121px) {
  body:not([data-components*=side-bar]) main .layout-content-left-sidebar .left {
    grid-column: 1;
  }
  body:not([data-components*=side-bar]) main .layout-content-left-sidebar .right {
    grid-column: 2/4;
  }
  body:not([data-components*=side-bar]) main .layout-content-right-sidebar .left {
    grid-column: 1/3;
    max-width: var(--main-max-width);
  }
  body:not([data-components*=side-bar]) main .layout-content-right-sidebar .right {
    grid-column: 3;
    padding-left: var(--gutter1);
    padding-right: var(--gutter1);
  }
  body:not([data-components*=side-bar]) main .layout-content-right-sidebar, body:not([data-components*=side-bar]) main .layout-content-left-sidebar {
    grid-template-columns: var(--column-sidebar) var(--column-content) var(--column-sidebar);
  }
}

.content-block > * {
  grid-column: 1/-1;
}
@media (min-width: 701px) {
  .content-block {
    --column-count: 6;
  }
  .content-block .col-1-1 {
    grid-column: 1/span 1;
  }
  .content-block .col-1-2 {
    grid-column: 1/span 2;
  }
  .content-block .col-1-3 {
    grid-column: 1/span 3;
  }
  .content-block .col-1-4 {
    grid-column: 1/span 4;
  }
  .content-block .col-1-5 {
    grid-column: 1/span 5;
  }
  .content-block .col-1-6 {
    grid-column: 1/span 6;
  }
  .content-block .col-2-1 {
    grid-column: 2/span 1;
  }
  .content-block .col-2-2 {
    grid-column: 2/span 2;
  }
  .content-block .col-2-3 {
    grid-column: 2/span 3;
  }
  .content-block .col-2-4 {
    grid-column: 2/span 4;
  }
  .content-block .col-2-5 {
    grid-column: 2/span 5;
  }
  .content-block .col-3-1 {
    grid-column: 3/span 1;
  }
  .content-block .col-3-2 {
    grid-column: 3/span 2;
  }
  .content-block .col-3-3 {
    grid-column: 3/span 3;
  }
  .content-block .col-3-4 {
    grid-column: 3/span 4;
  }
  .content-block .col-4-1 {
    grid-column: 4/span 1;
  }
  .content-block .col-4-2 {
    grid-column: 4/span 2;
  }
  .content-block .col-4-3 {
    grid-column: 4/span 3;
  }
  .content-block .col-5-1 {
    grid-column: 5/span 2;
  }
  .content-block .col-5-2 {
    grid-column: 5/span 2;
  }
  .content-block .col-6-1 {
    grid-column: 6/span 1;
  }
}
@media (min-width: 700px) and (max-width: 1400px) {
  .content-block {
    --column-count: 4;
  }
  .content-block .col-1-3 {
    grid-column: 1/span 2;
  }
  .content-block .col-1-4 {
    grid-column: 1/span 3;
  }
  .content-block .col-1-5 {
    grid-column: 1/span 3;
  }
  .content-block .col-4-3 {
    grid-column: 3/-1;
  }
  .content-block .col-1-6 {
    grid-column: 1/-1;
  }
  .content-block .col-2-3,
  .content-block .col-2-4,
  .content-block .col-2-5 {
    grid-column: 2/-1;
  }
  .content-block .col-3-2,
  .content-block .col-3-3,
  .content-block .col-3-4 {
    grid-column: 3/-1;
  }
  .content-block .col-4-1,
  .content-block .col-4-2,
  .content-block .col-5-1,
  .content-block .col-6-1 {
    grid-column: 4/-1;
  }
  .content-block .col-5-2 {
    grid-column: 1/-1;
  }
}
@media (min-width: 320px) and (max-width: 699px) {
  .content-block {
    --column-count: 2;
  }
  .content-block .col-1-1,
  .content-block .col-3-1,
  .content-block .col-5-1 {
    grid-column: 1;
  }
  .content-block .col-2-1,
  .content-block .col-4-1,
  .content-block .col-6-1 {
    grid-column: 2;
  }
}
@media (min-width: 320px) and (max-width: 699px) and (max-width: 500px) {
  .content-block .col-1-1 {
    grid-column: 1/-1;
  }
  .content-block .col-1-5 + .col-6-1 {
    grid-column: 1/-1;
  }
}
@media (min-width: 320px) and (max-width: 699px) and (min-width: 501px) {
  .content-block .col-2-5 {
    grid-column: 2;
  }
  .content-block .col-1-5 {
    grid-column: 1;
  }
}

navigation-bar {
  position: relative;
  display: flex;
  padding: calc(var(--spacer0) + var(--gap)) 0;
  align-items: flex-end;
  justify-content: space-between;
  align-items: center;
  background-color: #10124d;
  z-index: 5;
  gap: var(--gutter1);
  height: min-content;
  --top-level-link-gutters: var(--gap);
  --top-level-link-colour: white;
  --marker-line-thickness: 3px;
  --spacer-line-thickness: 1px;
}
@media (max-width: 980px) {
  navigation-bar {
    align-items: center;
  }
}
navigation-bar:before {
  content: "";
  background-color: inherit;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 100%;
  transform: translateX(var(--translateX, -50%));
  background: inherit;
}
navigation-bar > *:not(.image) {
  position: relative;
  z-index: 1;
}
body[data-components*=background-image] navigation-bar.hide-background {
  background-color: initial;
  background: initial;
}
body[data-components*=background-image] navigation-bar.hide-background::before {
  display: none;
}

body[data-components*=background-video] navigation-bar.hide-background {
  background-color: initial;
  background: initial;
}
body[data-components*=background-video] navigation-bar.hide-background::before {
  display: none;
}

navigation-bar.invert {
  background-color: initial;
  background: initial;
  --top-level-link-colour: #131f6b;
}
navigation-bar.invert::before {
  display: none;
}
navigation-bar.invert a.logo img[src*="tagline.svg"] {
  filter: invert(0.9);
}
navigation-bar a.logo {
  max-width: 320px;
  max-width: 280px;
  width: 100%;
  min-width: 200px;
}
navigation-bar a.logo img {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}
navigation-bar a.logo img[src*="logo.svg"] {
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0 90%);
}
navigation-bar a.logo img[src*="tagline.svg"] {
  margin-top: calc(var(--gap) * 0.5);
}
navigation-bar a.logo::after {
  content: "";
  width: 100%;
  height: 3px;
  position: absolute;
  left: 0;
  bottom: 0;
  bottom: calc(var(--logo-tagline-height, calc(var(--gap) * -0.5)) + calc(var(--gap) * 0.5));
  background-color: #DF3035;
}
navigation-bar nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  gap: 8px;
}
navigation-bar nav main-menu > ul > li.top-level-link:not(:nth-last-child(2)):not(:nth-last-child(3)):not(:last-of-type)::before,
navigation-bar nav secondary-menu > ul > li:not(:last-of-type)::before {
  content: "";
  width: var(--spacer-line-thickness);
  height: 40%;
  position: absolute;
  right: calc(-1 * var(--spacer-line-thickness) / 2);
  top: 30%;
  background-color: var(--spacer-line-colour, #DF3035);
}
navigation-bar nav personalise-experience {
  display: none;
}
navigation-bar nav .personalised {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--gap);
}
navigation-bar nav .personalised personalise-experience {
  display: flex;
  width: fit-content;
}
navigation-bar nav .personalised personalise-experience a.button.personalised {
  --font-size: 0.8em;
  --padding: 4px 10px;
}
@media (max-width: 940px) {
  navigation-bar nav .personalised personalise-experience a.button.personalised {
    --font-size: 16px;
    --padding: 8px 20px;
    --icon-size: 20px;
  }
  navigation-bar nav .personalised personalise-experience a.button.personalised p {
    display: none;
  }
  navigation-bar nav .personalised personalise-experience a.button.personalised svg {
    margin-left: 0;
  }
}
@media (max-width: 600px) {
  navigation-bar nav .personalised personalise-experience a.button.personalised {
    max-height: 36px;
    --icon-size: 1.1em;
  }
}
navigation-bar nav .personalised personalise-experience + secondary-menu {
  width: fit-content;
  justify-content: flex-end;
}
@media (max-width: 440px) {
  navigation-bar nav .personalised {
    top: unset;
    flex-wrap: nowrap;
  }
  navigation-bar nav .personalised .button,
  navigation-bar nav .personalised personalise-experience a.button.personalised {
    --padding: 8px;
    --icon-size: 0.8em;
  }
}
navigation-bar nav main-menu > ul > li.top-level-link + .search {
  margin-left: -2px;
}
navigation-bar nav .link {
  font-size: var(--font-size);
  text-decoration: none;
}
navigation-bar nav .link svg {
  margin-left: 0.4em;
  height: 0.5em;
  fill: #FFFFFF;
}
navigation-bar nav .link svg:not(.arrow-right):not(.arrow-left) {
  height: 0.8em;
}
navigation-bar nav .link.overview {
  font-weight: bold;
}
navigation-bar nav .link svg,
navigation-bar nav .button svg {
  pointer-events: none;
}
navigation-bar nav main-menu {
  --font-size: 0.95em;
  order: 2;
  align-self: flex-end;
  position: relative;
}
navigation-bar nav main-menu .top-level-links {
  display: flex;
  align-items: center;
}
navigation-bar nav main-menu .top-level-links li {
  position: relative;
}
navigation-bar nav main-menu .top-level-links li.top-level-link a {
  font-weight: bold;
  text-transform: uppercase;
  color: var(--top-level-link-colour);
  padding: calc(var(--gap) * 0.5) var(--top-level-link-gutters);
  height: 100%;
  white-space: nowrap;
  display: flex;
  place-items: center;
}
navigation-bar nav main-menu .top-level-links li.top-level-link a::after {
  content: "";
  height: 3px;
  width: calc(100% - var(--top-level-link-gutters) * 2);
  background-color: var(--top-level-link-colour, #DF3035);
  position: absolute;
  bottom: 0;
  left: var(--top-level-link-gutters);
  opacity: 0;
  transition: opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
navigation-bar nav main-menu .top-level-links li.top-level-link a.active::after {
  opacity: 1 !important;
}
navigation-bar nav main-menu .top-level-links li.top-level-link a.current::after {
  opacity: 1;
}
navigation-bar nav main-menu .top-level-links li.top-level-link[data-nav=menu] {
  order: 1;
}
navigation-bar nav main-menu .top-level-links li.top-level-link[data-nav=submenu] {
  order: 2;
  display: none;
}
navigation-bar nav main-menu .top-level-links li.search {
  white-space: nowrap;
}
navigation-bar nav main-menu .top-level-links li.search a {
  text-decoration: none;
}
navigation-bar nav main-menu .top-level-links li.search svg {
  margin-left: 0;
}
navigation-bar nav main-menu .top-level-links li.search span {
  margin-right: calc(var(--gap) * 0.5);
  pointer-events: none;
}
navigation-bar nav main-menu .top-level-links li.search, navigation-bar nav main-menu .top-level-links li.menu {
  order: 3;
}
navigation-bar nav main-menu .top-level-links li.cart {
  margin-right: calc(var(--top-level-link-gutters) / 2);
  left: -2px;
}
navigation-bar nav main-menu .top-level-links li.cart a {
  text-decoration: none;
  padding: 8px 10px;
}
navigation-bar nav main-menu .top-level-links li.cart a span {
  display: none;
}
navigation-bar nav main-menu .top-level-links li.cart svg {
  order: 1;
  margin-left: 0;
}
li.cart a[data-total]::after {
  content: attr(data-total);
  min-width: 2ch;
  width: fit-content;
  padding: 0 0.4em;
  margin-left: 0.5em;
  height: 2ch;
  align-self: center;
  background-color: #DF3035;
  color: white;
  line-height: 0.2;
  text-align: center;
  display: flex;
  place-content: center;
  place-items: center;
  font-size: 0.8em;
  border-radius: var(--border-radius, 6px);
  order: 2;
}
@media (hover: hover) {
  li.cart a[data-total]:hover {
    background-color: #131f6b;
    color: #FFFFFF;
  }
  li.cart a[data-total]:hover::after {
    color: #FFFFFF;
  }
  li.cart a[data-total]:hover svg {
    fill: #DF3035;
  }
}
@media (hover: hover) {
  li.cart a:not([data-total]):hover {
    background-color: #131f6b;
  }
  li.cart a:not([data-total]):hover::after {
    color: #FFFFFF;
  }
  li.cart a:not([data-total]):hover svg {
    fill: #DF3035;
  }
}

@media (min-width: 550px) {
  navigation-bar nav main-menu .top-level-links li.cart svg {
    display: none;
  }
  navigation-bar nav main-menu .top-level-links li.cart a {
    padding: 8px 20px;
  }
  navigation-bar nav main-menu .top-level-links li.cart a span {
    display: block;
  }
}
navigation-bar nav main-menu.loading::before {
  --size: 12px;
  content: "";
  position: absolute;
  top: calc(50% - var(--size) / 2);
  left: calc(50% - var(--size) / 2);
  opacity: 0;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border: 2px solid var(--loader-colour, white);
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation-name: loader-rotate;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  transition: opacity 0.2s cubic-bezier(0.42, 0, 0.58, 1);
  animation-play-state: paused;
}
@keyframes loader-rotate {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@media (hover: hover) {
  navigation-bar nav main-menu.loading:hover::before {
    opacity: 1;
    animation-play-state: running;
    transition-delay: 0s;
  }
}
navigation-bar nav secondary-menu {
  --font-size: 0.8em;
  display: flex;
  order: 1;
  align-self: flex-end;
  z-index: 6;
}
navigation-bar nav secondary-menu > ul {
  display: flex;
  position: relative;
  right: calc(var(--gap) * -1);
}
navigation-bar nav secondary-menu > ul li {
  position: relative;
}
navigation-bar nav secondary-menu > ul li a {
  text-transform: uppercase;
  color: var(--top-level-link-colour);
  padding: calc(var(--gap) * 0.5) var(--gap);
  display: flex;
  place-items: center;
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
@media (hover: hover) {
  navigation-bar nav secondary-menu > ul li a:hover {
    color: #0095FF;
  }
}
navigation-bar nav secondary-menu > ul li .sub-menu {
  display: grid;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 50%;
  min-width: calc(100% + calc(var(--gap) * 2));
  width: 200px;
  transform: translate(50%, calc(var(--gap) * -0.5));
  border-radius: var(--border-radius, 6px);
  background-color: var(--primary, #131f6b);
  transition: opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), transform var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  border: 1px solid rgba(255, 255, 255, 0.1);
}
navigation-bar nav secondary-menu > ul li .sub-menu li:nth-of-type(1) {
  padding-top: calc(var(--gap) * 0.5);
}
navigation-bar nav secondary-menu > ul li .sub-menu li:last-of-type {
  padding-bottom: calc(var(--gap) * 0.5);
}
navigation-bar nav secondary-menu > ul li .sub-menu a {
  padding: calc(calc(var(--gap) * 0.5) / 2) var(--gap);
  text-transform: initial;
  color: white;
}
navigation-bar nav secondary-menu > ul li .sub-menu a.asset {
  display: flex;
}
navigation-bar nav secondary-menu > ul li .sub-menu a.asset svg {
  order: -1;
  margin: 0 0.4em 0 0;
  align-self: flex-start;
  margin-top: 0.4em;
}
navigation-bar nav secondary-menu > ul li .sub-menu[data-level="1"] li:first-of-type {
  order: 100;
}
:root:not([data-browser-feature*=touch]) navigation-bar nav secondary-menu > ul li .sub-menu[data-level="1"] li:first-of-type {
  display: none;
}

@media (hover: hover) {
  navigation-bar nav secondary-menu > ul li:hover > a {
    color: #0095FF;
  }
  navigation-bar nav secondary-menu > ul li:hover .sub-menu {
    opacity: 1;
    transform: translate(50%, 0);
    pointer-events: all;
  }
}
navigation-bar nav secondary-menu > ul li:last-of-type .sub-menu {
  right: calc(100% + var(--gap)) !important;
}
navigation-bar nav secondary-menu > ul > li.has-children::after {
  content: "";
  top: calc(100% + 1px);
  left: 50%;
  width: 10px;
  height: 10px;
  transform: translate(-50%, calc(-50% + calc(var(--gap) * -0.5))) rotate(45deg);
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: var(--primary, #131f6b);
  position: absolute;
  clip-path: polygon(0 0, 100% 0%, 50% 50%, 0 100%);
  opacity: 0;
  transition: opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), transform var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  pointer-events: none;
}
@media (hover: hover) {
  navigation-bar nav secondary-menu > ul > li.has-children:hover::after {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}
@media (max-width: 1400px) {
  navigation-bar {
    --top-level-link-gutters: var(--gap);
  }
  navigation-bar nav main-menu > ul li.search span {
    display: none;
  }
}
@media (max-width: 980px) {
  navigation-bar nav main-menu {
    --font-size: 0.9em;
  }
}
navigation-bar li.menu {
  display: none;
  margin-left: var(--top-level-link-gutters);
}
navigation-bar button.menu {
  width: 40px;
  height: 40px;
}
navigation-bar button.menu svg {
  width: 100%;
  height: 100%;
  display: block;
  pointer-events: none;
}
navigation-bar button.menu svg line {
  stroke-width: 3px;
  stroke: var(--top-level-link-colour);
}
navigation-bar button.menu svg line:nth-child(1) {
  transform-origin: 15% 30%;
  transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
}
navigation-bar button.menu svg line:nth-child(2) {
  transition: opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
navigation-bar button.menu svg line:nth-child(3) {
  transform-origin: 15% 70%;
  transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
}
navigation-bar button.menu.active svg line:nth-child(1) {
  transform: rotate(45deg);
}
navigation-bar button.menu.active svg line:nth-child(2) {
  opacity: 0;
}
navigation-bar button.menu.active svg line:nth-child(3) {
  transform: rotate(-45deg);
}
body.mobile navigation-bar {
  padding: var(--gutter1) 0;
}
body.mobile navigation-bar li.menu {
  display: block;
  height: 40px;
}
@media (min-width: 980px) and (max-width: 1200px) {
  body.mobile navigation-bar .button.search {
    --font-size: 0.8em;
    --padding: 4px 10px;
  }
  body.mobile navigation-bar li.menu {
    display: flex;
    align-items: center;
  }
  body.mobile navigation-bar li.menu button.menu {
    height: 30px;
    width: 30px;
  }
}
body.mobile navigation-bar a.logo {
  max-width: initial;
  width: 200px;
  min-width: initial;
}
body.mobile navigation-bar nav main-menu .top-level-link,
body.mobile navigation-bar nav secondary-menu {
  display: none;
}
body.mobile navigation-bar nav main-menu > ul li.search span,
body.mobile navigation-bar nav main-menu > ul li[data-nav=submenu] {
  display: block;
}
@media (max-width: 980px) {
  body.mobile navigation-bar nav main-menu > ul li.search span {
    display: none;
  }
}

dropdown-menu {
  --min-height: 430px;
  --max-height: 600px;
  --dropdown-group-nav-width: 270px;
  --dropdown-image-width: 300px;
  --dropdown-image-height: 80px;
  --dropdown-open-offset: 6px;
  position: absolute;
  top: calc(100% - var(--marker-line-thickness) - 1px);
  background-color: white;
  border-radius: var(--border-radius, 6px);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: var(--dropdown-group-nav-width) 1fr;
  pointer-events: none;
  opacity: 0;
  margin-top: 0px;
  overscroll-behavior: contain;
  user-select: none;
  transition: opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1), margin-top 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  font-size: 16px;
}
navigation-bar nav main-menu.active .top-level-links .top-level-link .link.current::after {
  opacity: 0;
}
navigation-bar nav main-menu.active dropdown-menu {
  margin-top: var(--dropdown-open-offset);
  opacity: 1;
  pointer-events: all;
}

dropdown-menu::after {
  content: "";
  width: 100%;
  height: calc(var(--marker-line-thickness));
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 5;
  background-color: #DF3035;
  border-top-left-radius: var(--border-radius, 6px);
  border-top-right-radius: var(--border-radius, 6px);
}
body.mobile navigation-bar dropdown-menu {
  --dropdown-open-offset: var(--gap);
  width: var(--dropdown-group-nav-width) !important;
  right: 0;
}
@media (max-width: 480px) {
  body.mobile navigation-bar dropdown-menu {
    --dropdown-group-nav-width: calc(100vw - var(--gutter3)) !important;
  }
}

dropdown-group {
  position: relative;
  grid-column: 1;
  grid-row: 1;
  overflow-x: hidden;
  background-color: white;
  opacity: 0;
  height: inherit;
  pointer-events: none;
  will-change: transform;
  transition: opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1), transform 0.3s cubic-bezier(0.42, 0, 0.58, 1);
}
dropdown-group[data-level="0"] {
  z-index: 5;
}
dropdown-group[data-level="1"] {
  z-index: 4;
}
dropdown-group[data-level="2"] {
  z-index: 3;
}
dropdown-group[data-level="3"] {
  z-index: 2;
}
dropdown-group[data-level="4"] {
  z-index: 1;
}
dropdown-group::-webkit-scrollbar {
  width: 0.9em;
  height: 0.9em;
}
dropdown-group::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
dropdown-group::-webkit-scrollbar-thumb {
  background-color: #969695;
  border-radius: 10px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0.15);
}
dropdown-group::-webkit-scrollbar-track {
  background-color: transparent;
}
dropdown-group::-webkit-scrollbar-corner {
  background-color: transparent;
}
dropdown-group::-webkit-scrollbar-track {
  border-radius: 10px;
}
dropdown-group .link {
  --font-size: 0.9em;
  display: flex;
  position: relative;
  align-items: center;
  text-align: left;
  line-height: 1.4;
  padding: 4px calc(var(--gap) * 2);
  width: 100%;
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), background-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  justify-content: space-between;
}
dropdown-group .link.current {
  color: #DF3035;
}
dropdown-group .link.active {
  color: white;
  background-color: #131f6b;
  z-index: 1;
}
:root:not([data-browser-feature*=touch]) dropdown-group .link.active::after {
  content: "";
  display: block;
  position: absolute;
  transform: translateY(-50%) rotate(-45deg);
  left: calc(100% - 50px);
  top: 50%;
  width: 100px;
  height: 100px;
}

:not([data-browser-feature*=touch]) dropdown-group .last .link.active::after {
  transform: translateY(-100%) rotate(0deg) skew(-45deg, 0);
}

dropdown-group .link.has-children svg {
  margin-left: calc(var(--gap) * 0.5);
}
@media (hover: hover) {
  dropdown-group .link:hover {
    color: white;
    background-color: #131f6b;
  }
}
dropdown-group ul {
  display: flex;
  flex-direction: column;
  overscroll-behavior: contain;
}
dropdown-group ul li.first {
  padding-top: var(--gap);
}
dropdown-group ul li.last {
  padding-bottom: var(--gap);
}
dropdown-group ul li.overview {
  margin-top: var(--gap);
}
@media (max-width: 768px) {
  dropdown-group ul li.overview ~ .first {
    padding-top: 0;
  }
}
dropdown-group ul li.overview .link {
  color: #131f6b;
  font-weight: bold;
}
@media (hover: hover) {
  dropdown-group ul li.overview .link:hover {
    color: white;
  }
}
dropdown-group ul li.overview .link.active {
  color: white;
}
:root:not([data-browser-feature*=touch]) dropdown-group ul li.overview {
  display: none;
}

dropdown-group ul li.back {
  padding-top: var(--gap);
}
:root:not([data-browser-feature*=touch]) dropdown-group ul li.back {
  display: none;
}

dropdown-group ul li.back .link svg {
  margin-right: 0.4em;
  margin-left: 0;
}
dropdown-group[data-level="1"] {
  border-top-left-radius: var(--border-radius, 6px);
  border-bottom-left-radius: var(--border-radius, 6px);
}
dropdown-group[data-level="1"] li.back {
  display: none;
}
dropdown-group[data-level="2"] {
  width: 95%;
  left: 100%;
  opacity: 0;
  transform: translateX(-5%);
  background-color: #F4F4F4;
}
dropdown-group[data-level="3"] {
  width: calc(95% + 2px);
  opacity: 0;
  left: calc(195% - 1px);
  border-top-right-radius: var(--border-radius, 6px);
  border-bottom-right-radius: var(--border-radius, 6px);
  transform: translateX(-5%);
  background-color: #fafafa;
  transition-delay: 0.3s, 0.3s;
}
dropdown-group.allow-scroll {
  overflow-y: auto;
}
dropdown-group.active {
  opacity: 1;
  pointer-events: all;
}
dropdown-group.active[data-level="2"], dropdown-group.active[data-level="3"] {
  transform: translateX(0%);
}
dropdown-group:not(.active)[data-level="1"], dropdown-group:not(.active)[data-level="2"], dropdown-group:not(.active)[data-level="3"] {
  left: 0%;
  transform: translateX(0%);
}
body:not(.mobile) navigation-bar dropdown-group:not([data-level="1"]) .link {
  padding-top: 6px;
  padding-bottom: 6px;
}

body.mobile navigation-bar dropdown-group {
  border-radius: var(--border-radius, 6px) var(--border-radius, 6px) 0 0 !important;
  left: 0 !important;
  width: inherit !important;
  transition-duration: 0.5s, 0.5s;
  transform: initial !important;
}
body.mobile navigation-bar dropdown-group[data-level="0"] {
  z-index: 1;
}
body.mobile navigation-bar dropdown-group[data-level="1"] {
  z-index: 2;
}
body.mobile navigation-bar dropdown-group[data-level="2"] {
  z-index: 3;
}
body.mobile navigation-bar dropdown-group[data-level="3"] {
  z-index: 4;
}
body.mobile navigation-bar dropdown-group[data-level="4"] {
  z-index: 5;
}
body.mobile navigation-bar dropdown-group .link {
  --font-size: 1em;
  padding-top: calc(var(--gap) * 0.5);
  padding-bottom: calc(var(--gap) * 0.5);
}
body.mobile navigation-bar dropdown-group ul li.overview, body.mobile navigation-bar dropdown-group ul li.back {
  display: block !important;
}

dropdown-image {
  display: block;
  background-position: var(--position, center 33%);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  height: 100%;
  grid-column: 2;
  background-color: #F4F4F4;
  border-bottom-right-radius: var(--border-radius, 6px);
  border-top-right-radius: var(--border-radius, 6px);
  display: grid;
  justify-items: flex-end;
}
dropdown-image img {
  display: block;
  position: absolute;
  object-fit: cover;
  object-position: var(--image-position, center 33.33%);
  width: var(--image-size, var(--image-width-size, 100%));
  height: var(--image-size, var(--image-height-size, 100%));
  max-width: var(--image-size, var(--image-width-size, 100%));
  max-height: var(--image-size, var(--image-height-size, 100%));
  top: 0;
  left: 0;
}
dropdown-image .image {
  width: clamp(var(--dropdown-image-width) / 2, 30vw, var(--dropdown-image-width));
  grid-row: 1;
  grid-column: 1;
  transition: opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  opacity: 0;
}
dropdown-image .image.active {
  opacity: 1;
  pointer-events: all;
}
dropdown-image .image.active img {
  opacity: 1;
}
body.mobile navigation-bar dropdown-image {
  grid-column: 1;
  z-index: 10;
  width: inherit;
  height: var(--dropdown-image-height);
  border-top-right-radius: initial;
  border-bottom-left-radius: var(--border-radius, 6px);
}
body.mobile navigation-bar dropdown-image .image {
  width: inherit;
  height: inherit;
}

.header {
  padding-top: var(--spacer1);
  padding-bottom: var(--spacer1);
}
.header > h1 sup {
  font-size: 0.6em;
}
.header > h1 span {
  display: block;
  font-size: 0.375em;
  color: #969695;
}
.header > h1 + *, .header > h2 + * {
  margin-top: var(--gutter1);
}
.header > h1 + h2 {
  margin-top: var(--gutter0);
}
.header > h2 {
  color: var(--sub-heading-colour);
  font-family: lato, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.header > h2 a {
  --font-colour: var(--sub-heading-colour);
  text-decoration: underline;
}
@media (hover: hover) {
  .header > h2 a:hover {
    --font-colour: var(--primary, #131f6b);
  }
}
.header time {
  border-radius: var(--border-radius, 6px);
  margin: var(--gutter1) 0;
  padding: 4px 8px;
  background-color: #F4F4F4;
  border: 1px solid #dcdcdc;
  font-size: 0.75em;
  display: inline-block;
  width: fit-content;
  border-radius: 4px;
  border-left: 4px solid var(--tag-colour, #DF3035);
}
.header button-container {
  margin-bottom: calc(var(--gap) * -1);
  margin-top: var(--spacer0);
}
@media (max-width: 480px) {
  .header button-container .button span {
    white-space: normal;
  }
}
.header notice-messages {
  margin-top: var(--spacer0);
}
.header.dark {
  --heading-colour:white;
  --sub-heading-colour:white;
}
.header.dark .button.brochure {
  --font-colour:white;
}
.header.dark .button.brochure svg {
  fill: white;
}
.header.no-devider-line ~ main {
  --border-top-colour: rgba(255, 255, 255, 0.2);
}
.header.no-devider-line ~ main > .content-block:first-of-type {
  padding-top: 0;
  box-shadow: initial;
}

footer#footer {
  --font-colour: white;
  font-size: 0.875em;
  padding-top: var(--spacer1);
  padding-bottom: var(--spacer1);
  background-color: var(--primary, #131f6b);
  grid-gap: var(--gutter1);
  display: grid;
  position: relative;
  align-items: flex-start;
  grid-template-columns: 1fr 300px;
}
footer#footer a {
  text-decoration: none;
  position: relative;
  display: block;
  color: var(--font-colour);
}
@media (hover: hover) {
  footer#footer a:hover {
    color: #e44f53;
  }
}
footer#footer a.current {
  color: #e44f53;
}
footer#footer a.current {
  pointer-events: none;
}
footer#footer .back-to-top {
  width: fit-content;
  margin: calc(var(--gap) * -1) auto var(--gap) auto;
}
@media (min-width: 481px) {
  footer#footer .back-to-top {
    display: none;
  }
}
footer#footer .tag-line-sign-off {
  grid-column: 1/-1;
  display: flex;
  justify-content: center;
  margin-bottom: var(--spacer0);
  font-size: 2em;
  line-height: 1.3;
}
@supports (width: clamp(1px, 5vw, 10px)) {
  footer#footer .tag-line-sign-off tag-line span {
    font-size: clamp(12px, 1.5vw, 0.4em);
  }
}
@supports not (width: clamp(1px, 5vw, 10px)) {
  @media (max-width: 950px) {
    footer#footer .tag-line-sign-off tag-line span {
      font-size: 12px;
    }
  }
  @media (min-width: 1601px) {
    footer#footer .tag-line-sign-off tag-line span {
      font-size: 0.4em;
    }
  }
}
@media (max-width: 900px) {
  footer#footer .tag-line-sign-off tag-line {
    flex-direction: row;
    margin: 0;
  }
}
@media (max-width: 900px) and (max-width: 600px) {
  footer#footer .tag-line-sign-off tag-line {
    flex-direction: column;
    gap: calc(var(--gap) * 0.5);
  }
  footer#footer .tag-line-sign-off tag-line span {
    font-size: 16px;
  }
}
footer#footer > nav {
  display: grid;
  flex-grow: 1;
  grid-gap: var(--gutter1);
  align-items: flex-start;
  grid-template-columns: 1fr;
}
footer#footer > nav ul {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
footer#footer > nav ul li + li {
  margin-bottom: calc(var(--gap) * 0.5);
}
footer#footer > nav ul:last-of-type {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(333px, 1fr));
  grid-gap: 0 var(--gap);
}
footer#footer > nav ul:last-of-type li:first-of-type {
  grid-column: 1/-1;
}
footer#footer .heading {
  margin-bottom: var(--gap);
  color: var(--secondary, #DF3035);
  font-weight: bold;
  font-size: 1.0625em;
  line-height: 1.6;
}
footer#footer .heading::before, footer#footer .heading::after {
  display: none;
}
footer#footer .heading a {
  color: var(--secondary, #DF3035);
}
footer#footer company-details {
  display: flex;
  flex-direction: colunm;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: calc(var(--gap) * 0.5) var(--gap);
}
footer#footer company-details small,
footer#footer company-details .small {
  font-size: 0.625em;
  color: rgba(255, 255, 255, 0.7);
}
footer#footer company-details small br,
footer#footer company-details .small br {
  display: none;
}
footer#footer .base {
  margin-top: var(--spacer1);
  grid-column: 1/-1;
  position: relative;
  display: flex;
  justify-content: space-between;
}
footer#footer .base a.logo {
  width: 100%;
  max-width: 240px;
  position: initial;
}
footer#footer .base a.logo img {
  display: block;
}
@media (min-width: 981px) {
  footer#footer .base a.logo::after {
    content: "";
    background-color: #DF3035;
    height: 3px;
    width: 100%;
    position: absolute;
    bottom: -1.5px;
    left: 0;
  }
}
footer#footer .base a.yello {
  display: flex;
  position: absolute;
  top: calc(100% + var(--gap));
  font-size: 0.875em;
  right: 0;
  color: rgba(255, 255, 255, 0.5);
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
footer#footer .base a.yello span {
  margin-left: 0.3em;
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
@media (hover: hover) {
  footer#footer .base a.yello:hover {
    color: white;
  }
  footer#footer .base a.yello:hover span {
    color: #FFCB08;
  }
}
footer#footer .base admin-links {
  display: flex;
  position: absolute;
  top: calc(100% + var(--gap));
  left: 0;
}
footer#footer .base admin-links a {
  font-size: 12px;
  margin-right: calc(var(--gap) * 0.5);
}
footer#footer .base:has(.cep-logo) {
  --logo-spacer: 200px;
}
footer#footer .base:has(.cep-logo) .cep-logo {
  filter: grayscale(1) invert(1);
}
@media (min-width: 981px) {
  footer#footer .base:has(.cep-logo) {
    margin-right: var(--logo-spacer);
  }
  footer#footer .base:has(.cep-logo) .cep-logo {
    position: absolute;
    height: calc(100% + calc(var(--gap) * 2));
    top: 0;
    right: calc(var(--logo-spacer) * -1);
  }
}
@media (max-width: 980px) {
  footer#footer .base:has(.cep-logo) .cep-logo {
    margin-bottom: var(--gutter2);
  }
}
@media (max-width: 1400px) {
  footer#footer > nav ul:not(:last-of-type) {
    display: none;
  }
  footer#footer company-details small br {
    display: none;
  }
}
@media (max-width: 980px) {
  footer#footer {
    grid-template-columns: 1fr;
  }
  footer#footer .base {
    flex-direction: column;
    align-items: center;
  }
  footer#footer .base a.logo {
    order: 1;
    width: 160px;
  }
  footer#footer .base a.logo img {
    max-width: 100%;
  }
  footer#footer .base social-media {
    margin-top: var(--spacer0-);
    margin-bottom: var(--spacer0);
  }
  footer#footer .base admin-links {
    order: 3;
    position: initial;
    margin-top: var(--gap);
  }
}

body > background-image {
  pointer-events: none;
  display: block;
  background-position: var(--position, center 33%);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  --background-theme-one: #131f6b;
  --background-theme-two: #292B68;
}
body > background-image img {
  display: block;
  position: absolute;
  object-fit: cover;
  object-position: var(--image-position, center 33.33%);
  width: var(--image-size, var(--image-width-size, 100%));
  height: var(--image-size, var(--image-height-size, 100%));
  max-width: var(--image-size, var(--image-width-size, 100%));
  max-height: var(--image-size, var(--image-height-size, 100%));
  top: 0;
  left: 0;
}
body > background-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background: linear-gradient(to bottom, var(--background-theme-one), var(--background-theme-two));
}
body > background-image.none::after {
  content: initial;
}
body > background-image.red {
  --background-theme-one: #DF3035;
  --background-theme-two: #DF3035;
}
body > background-image.black {
  --background-theme-one: #1E1A1A;
  --background-theme-two: #1E1A1A;
}
body > background-image.tile {
  background-repeat: repeat;
  background-size: auto;
}
body > background-image.tile img {
  display: none;
}
body > background-image:not(.tile) {
  max-height: 100vh;
}
body > background-image.behind-header::after {
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  mask-image: linear-gradient(to right, black 20%, transparent 100%);
}

body.mobile > background-image, body.mobile > background-video {
  max-height: initial;
}

body > background-video {
  pointer-events: none;
  display: block;
  background-position: var(--position, center 33%);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
}
body > background-video img {
  display: block;
  position: absolute;
  object-fit: cover;
  object-position: var(--image-position, center 33.33%);
  width: var(--image-size, var(--image-width-size, 100%));
  height: var(--image-size, var(--image-height-size, 100%));
  max-width: var(--image-size, var(--image-width-size, 100%));
  max-height: var(--image-size, var(--image-height-size, 100%));
  top: 0;
  left: 0;
}
body > background-video video {
  display: block;
  position: absolute;
  object-fit: cover;
  object-position: var(--image-position, center 33.33%);
  width: var(--image-size, var(--image-width-size, 100%));
  height: var(--image-size, var(--image-height-size, 100%));
  max-width: var(--image-size, var(--image-width-size, 100%));
  max-height: var(--image-size, var(--image-height-size, 100%));
  top: 0;
  left: 0;
}
body > background-video::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, var(--background-theme-one, transparent), var(--background-theme-two, black));
}

#breadcrumbs-bar {
  background-color: var(--primary, #131f6b);
  display: flex;
  align-items: center;
  transition: height var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), top var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
#breadcrumbs-bar ul {
  display: flex;
  position: relative;
  left: calc(var(--gap) * -1);
  width: 100%;
}
#breadcrumbs-bar ul li {
  align-self: stretch;
  align-items: center;
  position: relative;
}
#breadcrumbs-bar ul li + li {
  margin-left: calc(var(--gap) * -1);
}
#breadcrumbs-bar .link {
  display: flex;
  place-items: center;
  height: 100%;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.125em;
  padding: var(--gap);
  text-decoration: none;
  color: white;
}
#breadcrumbs-bar .link svg {
  color: rgba(255, 255, 255, 0.8);
  width: 0.7em;
  height: 0.7em;
  margin-left: var(--gap);
}
@media (hover: hover) {
  #breadcrumbs-bar .link:hover {
    color: var(--secondary, #DF3035);
  }
}
#breadcrumbs-bar .link.current {
  color: var(--secondary, #DF3035);
}
#breadcrumbs-bar .link.current::after {
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--secondary, #DF3035);
  position: absolute;
}

body.mobile > #breadcrumbs-bar {
  max-height: initial;
  display: none;
}

#anchors-bar {
  background-color: #F4F4F4;
  transition: height var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), top var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  display: flex;
  align-items: center;
  max-width: 100vw;
}
#anchors-bar .container {
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 100%;
}
@media (max-width: 1680px) {
  :root:not([data-browser-feature*=touch]) #anchors-bar {
    width: 100vw;
    margin-left: var(--gutter2-);
  }
  :root:not([data-browser-feature*=touch]) #anchors-bar .container {
    overflow-x: auto;
    scroll-behavior: smooth;
    padding-left: var(--gutter1);
    padding-right: var(--gutter1);
  }
}

:root[data-browser-feature*=touch] #anchors-bar {
  width: calc(100% + var(--gutter2) + calc(var(--gap) * 0.5));
  margin-left: var(--gutter1-);
}
:root[data-browser-feature*=touch] #anchors-bar::before {
  content: initial;
}
:root[data-browser-feature*=touch] #anchors-bar .container {
  overflow-x: auto;
  padding-left: var(--gutter1);
  padding-right: var(--gutter1);
}

#anchors-bar::before {
  border-bottom: 1px solid #dcdcdc;
}
#anchors-bar a {
  grid-row: 1;
  font-size: 0.875em;
  text-transform: uppercase;
  font-weight: bold;
  white-space: nowrap;
  display: flex;
  place-items: center;
  position: relative;
  height: 100%;
  color: #131f6b;
}
#anchors-bar a + a {
  margin-left: var(--gutter1);
}
@media (hover: hover) {
  #anchors-bar a:hover {
    color: var(--secondary, #DF3035);
  }
}
#anchors-bar a::after {
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--secondary, #DF3035);
  position: absolute;
  transform: scaleY(0);
  transition: transform 0.2s cubic-bezier(0.42, 0, 0.58, 1) 0.2s;
}
#anchors-bar a.current {
  color: var(--secondary, #DF3035);
}
#anchors-bar a.current::after {
  transform: scaleY(1);
}

side-bar > div > * {
  --background-colour: var(--primary, #131f6b);
  order: 3;
  border-radius: var(--border-radius, 6px);
  padding: var(--gutter2);
  background-color: var(--background-colour);
  border: 1px solid var(--border-colour, rgba(255, 255, 255, 0.2));
}
side-bar > div > *:not(.white) {
  --font-colour: white;
  --heading-colour: white;
}
side-bar > div service-centre-finder {
  order: 1;
}
side-bar > div service-centre-finder ~ #grades-sidebar-list {
  order: 2;
}
side-bar > div .heading {
  margin-bottom: var(--gutter0);
  font-size: 1.5625em;
  line-height: 1.6;
}
side-bar > div > div.sidebar-logos {
  --background-colour: transparent;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 2rem;
  justify-content: center;
}
side-bar > div > div.sidebar-logos img {
  max-width: 50%;
  object-fit: contain;
}
side-bar > div > div.sidebar-logos svg {
  max-width: 150px;
  max-height: 100px;
}
side-bar #powder-tubs,
side-bar #greener-future,
side-bar #liberty-powder {
  position: relative;
  overflow: hidden;
}
side-bar #powder-tubs h3 span,
side-bar #greener-future h3 span,
side-bar #liberty-powder h3 span {
  display: block;
}
side-bar #powder-tubs div,
side-bar #greener-future div,
side-bar #liberty-powder div {
  z-index: 1;
  position: relative;
}
side-bar #powder-tubs .background,
side-bar #greener-future .background,
side-bar #liberty-powder .background {
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 100% 0%;
}
side-bar #powder-tubs .image img,
side-bar #greener-future .image img,
side-bar #liberty-powder .image img {
  object-fit: contain;
}
side-bar #liberty-powder {
  background: transparent;
  border: 1px solid #dcdcdc;
}
side-bar #liberty-powder .image {
  height: 60px;
  margin-bottom: calc(var(--gap) * 2);
}
side-bar #liberty-powder .image img {
  width: auto;
}
side-bar #powder-tubs {
  background: #333236;
}
side-bar #powder-tubs h3 {
  padding-bottom: 225px;
  font-size: 22px;
}
side-bar #powder-tubs h3 span {
  color: #DF3035;
}
side-bar #powder-tubs .background {
  opacity: 1;
}
side-bar #powder-tubs .image {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 250px;
  width: 100%;
}
side-bar #powder-tubs .image img {
  width: 100%;
}
@media (min-width: 1120px) {
  side-bar #powder-tubs h3 {
    padding-bottom: 300px;
  }
  side-bar #powder-tubs .image {
    height: 350px;
  }
}
side-bar #greener-future {
  --greener-green: #4ecb3d;
}
side-bar #greener-future h3 {
  margin-bottom: calc(var(--gap) * 2);
}
side-bar #greener-future h3 span {
  color: var(--greener-green);
  line-height: 1;
}
side-bar #greener-future .image {
  height: 90px;
}
side-bar #greener-future .image img {
  width: auto;
}
side-bar #greener-future ul {
  margin-bottom: var(--gap);
}
side-bar #greener-future ul li {
  color: white;
  padding-left: calc(var(--gap) * 2);
  position: relative;
  margin-bottom: var(--gap);
}
side-bar #greener-future ul li:before {
  background: var(--greener-green);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 4px;
  left: 0;
  content: "";
}
side-bar #greener-future ul li:after {
  height: 12px;
  width: 6px;
  content: "";
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  position: absolute;
  left: 7px;
  top: 6px;
  transform: rotate(45deg);
}
@media (min-width: 1120px) {
  side-bar #greener-future ul {
    column-count: 2;
  }
}

.sidebar-block\:local-cta {
  background-color: #676767;
  color: white;
  background-repeat: no-repeat;
  background-position: -1rem bottom;
  background-size: auto 220px;
  display: flex;
  flex-direction: column;
  gap: var(--gutter0);
}
.sidebar-block\:local-cta p {
  font-size: 2rem;
  text-wrap: pretty;
}
@media (width > 1400px) {
  .sidebar-block\:local-cta {
    background-image: url("/assets/uploads/images/20mph-sign.png");
    padding-inline-start: 160px;
  }
}

.sidebar-block\:iso {
  background-color: #74bf03;
  border: initial;
  padding: 0;
  object-fit: cover;
  object-position: center center;
  border-radius: var(--border-radius, 6px);
  overflow: hidden;
}
.sidebar-block\:iso img {
  display: block;
  width: 100%;
}

social-media {
  --icon-colour: #FFFFFF;
  --icon-size: 20px;
  display: flex;
  align-items: center;
}
social-media a {
  margin-left: var(--gap);
  display: flex !important;
}
social-media a svg {
  width: var(--icon-size);
  height: var(--icon-size);
  fill: var(--icon-colour);
  transition: var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
@media (hover: hover) {
  social-media a:hover.facebook {
    --icon-colour: #1877f2;
  }
  social-media a:hover.twitter {
    --icon-colour: #1da1f2;
  }
  social-media a:hover.google {
    --icon-colour: #4285f4;
  }
  social-media a:hover.youtube {
    --icon-colour: #ff0000;
  }
  social-media a:hover.linkedin {
    --icon-colour: #007bb5;
  }
  social-media a:hover.instagram {
    --icon-colour: #c32aa3;
  }
  social-media a:hover.pinterest {
    --icon-colour: #bd081c;
  }
  social-media a:hover.dailymotion {
    --icon-colour: #0066DC;
  }
  social-media a:hover.snapchat {
    --icon-colour: #fffc00;
  }
  social-media a:hover.flickr {
    --icon-colour: #f40083;
  }
  social-media a:hover.vimeo {
    --icon-colour: #1ab7ea;
  }
  social-media a:hover.thumbler {
    --icon-colour: #35465d;
  }
  social-media a:hover.reddit {
    --icon-colour: #ff4500;
  }
  social-media a:hover.whatsapp {
    --icon-colour: #25d366;
  }
  social-media a:hover.skype {
    --icon-colour: #00aff0;
  }
  social-media a:hover.discord {
    --icon-colour: #7289da;
  }
}
social-media a span {
  display: none;
}

.article {
  display: flex;
}
.article .container {
  display: flex;
  flex-direction: column;
  padding: var(--gutter1);
  background-color: white;
  border: 1px solid #F4F4F4;
  border-radius: var(--border-radius, 6px);
  overflow: hidden;
  align-content: flex-start;
  width: 100%;
}
.article .container > * {
  order: 2;
  margin-bottom: var(--gap);
}
.article .container .heading {
  font-size: 1.5625em;
  line-height: 1.6;
  line-height: 1.2;
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
.article .container .subheading {
  font-size: 1.0625em;
  line-height: 1.6;
  color: #6A6A6A;
  max-width: 75ch;
  margin-bottom: auto;
}
.article .container .subheading + .timestamp {
  margin-top: var(--gap);
}
.article .container .introduction {
  font-size: 0.875em;
  max-width: 75ch;
}
.article .container .button {
  justify-self: flex-start;
  margin-bottom: 0;
  margin-top: auto;
}
.article .container time {
  font-weight: bold;
  font-size: 0.875em;
}
.article .container .author {
  --photo-size: 40px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
}
.article .container .author .name {
  font-weight: bold;
  white-space: nowrap;
  color: #131f6b;
  font-size: 0.875em;
  line-height: 1.4;
  order: 1;
  min-height: var(--photo-size);
  display: flex;
  align-items: center;
}
.article .container .author .name em {
  font-size: 0.75em;
  margin-right: 5px;
}
.article .container .author .timestamp {
  white-space: nowrap;
  font-size: 0.875em;
  line-height: 1.4;
  order: 2;
}
.article .container .author .timestamp, .article .container .author .timestamp ~ .name {
  min-height: calc(var(--photo-size) / 2);
}
.article .container .author .image {
  width: var(--photo-size);
  height: var(--photo-size);
  top: calc(50% - var(--photo-size) / 2);
  border-radius: 50%;
  left: 0;
  position: absolute;
  border: 1px solid #dcdcdc;
}
.article .container .author .image ~ * {
  padding-left: calc(var(--photo-size) + var(--gap));
}
.article .container > .image {
  order: 1;
  margin-bottom: var(--gutter1);
  height: var(--image-height, 180px);
  width: calc(100% + var(--gutter3));
  left: var(--gutter1-);
  margin-top: var(--gutter1-);
}
.article a {
  transition: border-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
@media (hover: hover) {
  .article a:hover {
    border-color: var(--primary, #131f6b);
  }
}
.article[data-contains*=title][data-contains*=timestamp][data-contains*=author][data-contains*=button][data-contains*=image]:not([data-contains*=subheading]):not([data-contains*=introduction]) .container .timestamp {
  margin-top: auto;
}

.article[data-contains*=title][data-contains*=image]:not([data-contains*=timestamp]):not([data-contains*=author]):not([data-contains*=button]):not([data-contains*=subheading]):not([data-contains*=introduction]) .container .heading {
  margin-bottom: auto;
  font-size: 1.5625em;
  line-height: 1.6;
  line-height: 1.2;
}
.article[data-contains*=title][data-contains*=image]:not([data-contains*=timestamp]):not([data-contains*=author]):not([data-contains*=button]):not([data-contains*=subheading]):not([data-contains*=introduction]) .container > .image {
  --image-height: 130px;
}

.article[data-contains*=serviceCentres] .container div {
  margin-bottom: var(--gap);
}
.article[data-contains*=serviceCentres] .container h4 {
  margin-bottom: 0;
}

tag-line {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  gap: var(--gap);
  font-weight: 400;
  letter-spacing: 5px;
  font-size: 1.5625em;
  line-height: 1.6;
}
tag-line span {
  display: flex;
  align-items: center;
  font-size: 0.5em;
  color: white;
  grid-row: 1;
  line-height: 2;
}
tag-line svg {
  color: #DF3035;
  display: block;
  width: 24px;
  height: 24px;
  margin-right: var(--gap);
}
@media (max-width: 800px) {
  tag-line {
    gap: calc(var(--gap) * 0.5);
    margin: var(--spacer0) 0;
  }
  tag-line span {
    letter-spacing: 1px;
  }
  tag-line svg {
    margin-right: calc(var(--gap) * 0.5);
  }
}

side-bar form .freeform-form-success {
  background-color: #50BC08;
  color: white;
  width: calc(100% + 30px);
  padding: calc(var(--gap) * 0.5) var(--gap);
  border-radius: var(--border-radius, 6px);
  margin: 0 -15px var(--gap);
}
side-bar form .submit-row {
  margin-top: var(--gap);
}

form select {
  --font-colour: #6A6A6A;
  --background-colour: #FFFFFF;
  --border-radius: var(--border-radius, 6px);
  --border-colour: var(--font-colour);
  --border: 1px solid var(--border-colour);
  --padding: var(--gap) var(--gap);
  border-radius: var(--border-radius, 6px);
  background-color: var(--background-colour);
  border: var(--border);
  padding: var(--padding);
  font-size: 0.875em;
  color: var(--font-colour);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  background-repeat: no-repeat;
  background-position: calc(100% - var(--gap)) center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.4' height='10.1'%3E%3Cpath d='M16.7.7l-8 8-8-8' fill='none' stroke='%231E1A1A' stroke-width='2' stroke-miterlimit='10'/%3E%3C/svg%3E");
}
form select::placeholder {
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
form select:focus {
  outline-color: #131f6b;
}
form select:focus::placeholder {
  color: transparent;
}
form select.autocomplete:not([value=""]):focus {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
form input:not([type=checkbox]):not([type=radio]) {
  --font-colour: #6A6A6A;
  --background-colour: #FFFFFF;
  --border-radius: var(--border-radius, 6px);
  --border-colour: var(--font-colour);
  --border: 1px solid var(--border-colour);
  --padding: var(--gap) var(--gap);
  border-radius: var(--border-radius, 6px);
  background-color: var(--background-colour);
  border: var(--border);
  padding: var(--padding);
  font-size: 0.875em;
  color: var(--font-colour);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
form input:not([type=checkbox]):not([type=radio])::placeholder {
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
form input:not([type=checkbox]):not([type=radio]):focus {
  outline-color: #131f6b;
}
form input:not([type=checkbox]):not([type=radio]):focus::placeholder {
  color: transparent;
}
form input:not([type=checkbox]):not([type=radio]).autocomplete:not([value=""]):focus {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
form textarea {
  --font-colour: #6A6A6A;
  --background-colour: #FFFFFF;
  --border-radius: var(--border-radius, 6px);
  --border-colour: var(--font-colour);
  --border: 1px solid var(--border-colour);
  --padding: var(--gap) var(--gap);
  border-radius: var(--border-radius, 6px);
  background-color: var(--background-colour);
  border: var(--border);
  padding: var(--padding);
  font-size: 0.875em;
  color: var(--font-colour);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
form textarea::placeholder {
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
form textarea:focus {
  outline-color: #131f6b;
}
form textarea:focus::placeholder {
  color: transparent;
}
form textarea.autocomplete:not([value=""]):focus {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
form input:-webkit-autofill,
form input:-webkit-autofill:hover,
form input:-webkit-autofill:focus,
form textarea:-webkit-autofill,
form textarea:-webkit-autofill:hover,
form textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--font-colour, inherit);
  -webkit-box-shadow: 0 0 0px 1000px var(--background-colour, white) inset;
}
form[data-freeform] label + div {
  font-size: 0.75em;
  margin-bottom: calc(var(--gap) * 0.5);
}
form[data-freeform] .freeform-row + .freeform-row > div {
  margin-top: var(--gap);
}
form[data-freeform] .freeform-column {
  margin: 0;
  padding: 0;
}
side-bar form label {
  color: white;
}
side-bar form label ~ div {
  color: white;
}

form#login {
  display: flex;
  flex-direction: column;
}
form#login > label ~ label {
  margin-top: var(--gap);
}
form#login div {
  display: flex;
  justify-content: space-between;
  margin-top: var(--gap);
  font-size: 0.75em;
}
@media (hover: hover) {
  form#login div a:hover {
    color: #DF3035;
  }
}
form#login button {
  margin-top: var(--gap);
}

form#reset-password {
  background-color: #131f6b;
  border-radius: var(--border-radius, 6px);
  color: white;
  padding: var(--gutter1);
  display: flex;
  flex-direction: column;
}
form#reset-password button {
  margin-top: var(--gap);
}

required-notice {
  display: block;
  width: 5px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: var(--notice-colour, #50BC08);
  border-top-right-radius: 6px;
  transition: background-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  border-bottom-right-radius: 6px;
}
required-notice span {
  pointer-events: none;
  white-space: nowrap;
  font-size: 0.75em;
  top: 50%;
  right: calc(100% + calc(var(--gap) * 0.5));
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  background-color: white;
  padding: 4px calc(var(--gap) * 0.5);
  position: absolute;
  opacity: 0;
  transform: translate(10px, -50%);
  transition: var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
@media (hover: hover) {
  required-notice:hover span {
    transform: translate(0px, -50%);
    opacity: 1;
  }
}

form[data-handle=contactUs] .freeform-form-success,
form[data-handle=contactUs] .freeform-form-errors,
form[data-handle=powderContact] .freeform-form-success,
form[data-handle=powderContact] .freeform-form-errors {
  background-color: #50BC08;
  color: white;
  grid-column: 1/-1;
  width: 100%;
  padding: calc(var(--gap) * 0.5) var(--gap);
  border-radius: var(--border-radius, 6px);
  margin-bottom: var(--gutter1);
}
form[data-handle=contactUs] .freeform-form-errors,
form[data-handle=powderContact] .freeform-form-errors {
  background-color: #DF3035;
}
form[data-handle=contactUs] .form-fields,
form[data-handle=powderContact] .form-fields {
  display: grid;
  grid-gap: var(--gutter1) var(--gutter1);
}
@media (min-width: 601px) {
  form[data-handle=contactUs] .form-fields,
  form[data-handle=powderContact] .form-fields {
    grid-template-columns: repeat(2, 1fr);
  }
  form[data-handle=contactUs] .form-fields .freeform-row:first-child,
  form[data-handle=powderContact] .form-fields .freeform-row:first-child {
    grid-row: 1;
  }
  form[data-handle=contactUs] .form-fields .freeform-row.first-name, form[data-handle=contactUs] .form-fields .freeform-row.last-name, form[data-handle=contactUs] .form-fields .freeform-row.email, form[data-handle=contactUs] .form-fields .freeform-row.company-name, form[data-handle=contactUs] .form-fields .freeform-row.contact-no, form[data-handle=contactUs] .form-fields .freeform-row.y-rqg181k-d,
  form[data-handle=powderContact] .form-fields .freeform-row.first-name,
  form[data-handle=powderContact] .form-fields .freeform-row.last-name,
  form[data-handle=powderContact] .form-fields .freeform-row.email,
  form[data-handle=powderContact] .form-fields .freeform-row.company-name,
  form[data-handle=powderContact] .form-fields .freeform-row.contact-no,
  form[data-handle=powderContact] .form-fields .freeform-row.y-rqg181k-d {
    grid-column: 1;
  }
  form[data-handle=contactUs] .form-fields .freeform-row.detailsofenquiry,
  form[data-handle=powderContact] .form-fields .freeform-row.detailsofenquiry {
    grid-column: 2;
    grid-row: 2/span 5;
  }
  form[data-handle=contactUs] .form-fields .freeform-row.submit-row,
  form[data-handle=powderContact] .form-fields .freeform-row.submit-row {
    grid-column: 2;
  }
  form[data-handle=contactUs] .form-fields button[type=submit],
  form[data-handle=powderContact] .form-fields button[type=submit] {
    align-self: flex-end;
  }
}
form[data-handle=contactUs] .form-fields h2,
form[data-handle=powderContact] .form-fields h2 {
  display: flex;
  align-items: center;
}
form[data-handle=contactUs] .form-fields h2 svg.email,
form[data-handle=powderContact] .form-fields h2 svg.email {
  width: 1em;
  margin-right: 0.5em;
  height: 1em;
  fill: #DF3035;
}
form[data-handle=contactUs] .form-fields .freeform-row,
form[data-handle=powderContact] .form-fields .freeform-row {
  display: flex;
  flex-direction: column;
  position: relative;
  grid-column: 1/span 2;
}
form[data-handle=contactUs] .form-fields .freeform-row.detailsofenquiry .freeform-instructions,
form[data-handle=powderContact] .form-fields .freeform-row.detailsofenquiry .freeform-instructions {
  display: none;
}
form[data-handle=contactUs] .form-fields .freeform-row.detailsofenquiry textarea,
form[data-handle=powderContact] .form-fields .freeform-row.detailsofenquiry textarea {
  height: 100%;
}
form[data-handle=contactUs] .form-fields .freeform-row.detailsofenquiry .freeform-column,
form[data-handle=powderContact] .form-fields .freeform-row.detailsofenquiry .freeform-column {
  height: 100%;
}
form[data-handle=contactUs] .form-fields .freeform-row .freeform-column,
form[data-handle=powderContact] .form-fields .freeform-row .freeform-column {
  margin: 0;
  display: flex;
  flex-direction: column;
  position: relative;
}
form[data-handle=contactUs] .form-fields .freeform-row .freeform-column label:not(.freeform-input-only-label),
form[data-handle=powderContact] .form-fields .freeform-row .freeform-column label:not(.freeform-input-only-label) {
  display: none;
}
form[data-handle=contactUs] .form-fields .freeform-row.service-centre-contact, form[data-handle=contactUs] .form-fields .freeform-row.lead-status, form[data-handle=contactUs] .form-fields .freeform-row.internal-notes, form[data-handle=contactUs] .form-fields .freeform-row.order-value,
form[data-handle=powderContact] .form-fields .freeform-row.service-centre-contact,
form[data-handle=powderContact] .form-fields .freeform-row.lead-status,
form[data-handle=powderContact] .form-fields .freeform-row.internal-notes,
form[data-handle=powderContact] .form-fields .freeform-row.order-value {
  display: none;
}
form[data-handle=contactUs] .form-fields label.freeform-input-only-label,
form[data-handle=powderContact] .form-fields label.freeform-input-only-label {
  font-size: 0.875em;
  grid-column: 1;
}
form[data-handle=contactUs] .form-fields label.freeform-input-only-label input,
form[data-handle=powderContact] .form-fields label.freeform-input-only-label input {
  width: 20px;
  height: 20px;
  margin-right: calc(var(--gap) * 0.5);
}
form[data-handle=contactUs] .form-fields label.freeform-input-only-label input:checked,
form[data-handle=powderContact] .form-fields label.freeform-input-only-label input:checked {
  background-color: #50BC08;
}
form[data-handle=contactUs] .form-fields select,
form[data-handle=contactUs] .form-fields textarea,
form[data-handle=contactUs] .form-fields input:not([type=submit]),
form[data-handle=powderContact] .form-fields select,
form[data-handle=powderContact] .form-fields textarea,
form[data-handle=powderContact] .form-fields input:not([type=submit]) {
  --border-colour: none;
  --background-colour: white;
}
form[data-handle=contactUs] .freeform-errors,
form[data-handle=powderContact] .freeform-errors {
  color: #DF3035;
  font-weight: bold;
  font-size: 0.625em;
  padding: 2px var(--gap);
  position: absolute;
  top: calc(100% - 4px);
  background-color: #DF3035;
  color: white;
  width: 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
@media (max-width: 400px) {
  form[data-handle=contactUs] button[type=submit],
  form[data-handle=powderContact] button[type=submit] {
    width: 100%;
  }
}
form[data-handle=contactUs] .freeform-input[required]:not(:valid) ~ required-notice,
form[data-handle=powderContact] .freeform-input[required]:not(:valid) ~ required-notice {
  --notice-colour: #DF3035;
}
form[data-handle=contactUs]:not(:valid) button[type=submit],
form[data-handle=powderContact]:not(:valid) button[type=submit] {
  pointer-events: none;
  --background-colour: transparent;
  --font-colour: #969695;
  --border-colour: #969695;
  --hover-background-colour: #969695;
  --hover-font-colour: #969695;
  --hover-border-colour: transparent;
}
form[data-handle=contactUs] .service-centre,
form[data-handle=powderContact] .service-centre {
  display: none !important;
}

@media (min-width: 601px) {
  form[data-handle=contactUs] .form-fields {
    grid-template-columns: repeat(2, 50%);
  }
  form[data-handle=contactUs] .form-fields .freeform-row:first-child {
    grid-row: 1;
  }
  form[data-handle=contactUs] .form-fields .freeform-row.first-name, form[data-handle=contactUs] .form-fields .freeform-row.last-name, form[data-handle=contactUs] .form-fields .freeform-row.email, form[data-handle=contactUs] .form-fields .freeform-row.company-name, form[data-handle=contactUs] .form-fields .freeform-row.contact-no, form[data-handle=contactUs] .form-fields .freeform-row.y-rqg181k-d, form[data-handle=contactUs] .form-fields .freeform-row.product {
    grid-column: 1/2;
  }
  form[data-handle=contactUs] .form-fields .freeform-row.detailsofenquiry {
    grid-column: 2/-1;
    grid-row: 2/span 6;
  }
}

body#edit form textarea,
body#edit form select,
body#edit form input {
  border: 1px solid black;
}
body#edit form .submit-row {
  order: 20;
}
body#edit form .freeform-row {
  display: none;
}
body#edit form .first-name,
body#edit form .last-name,
body#edit form .email,
body#edit form .product,
body#edit form .detailsofenquiry,
body#edit form .service-centre-contact,
body#edit form .lead-status,
body#edit form .internal-notes,
body#edit form .order-value,
body#edit form .submit-row {
  display: block;
}
body#edit form .first-name .freeform-column select,
body#edit form .first-name .freeform-column textarea,
body#edit form .first-name .freeform-column input,
body#edit form .last-name .freeform-column select,
body#edit form .last-name .freeform-column textarea,
body#edit form .last-name .freeform-column input,
body#edit form .email .freeform-column select,
body#edit form .email .freeform-column textarea,
body#edit form .email .freeform-column input,
body#edit form .product .freeform-column select,
body#edit form .product .freeform-column textarea,
body#edit form .product .freeform-column input,
body#edit form .detailsofenquiry .freeform-column select,
body#edit form .detailsofenquiry .freeform-column textarea,
body#edit form .detailsofenquiry .freeform-column input,
body#edit form .service-centre-contact .freeform-column select,
body#edit form .service-centre-contact .freeform-column textarea,
body#edit form .service-centre-contact .freeform-column input,
body#edit form .lead-status .freeform-column select,
body#edit form .lead-status .freeform-column textarea,
body#edit form .lead-status .freeform-column input,
body#edit form .internal-notes .freeform-column select,
body#edit form .internal-notes .freeform-column textarea,
body#edit form .internal-notes .freeform-column input,
body#edit form .order-value .freeform-column select,
body#edit form .order-value .freeform-column textarea,
body#edit form .order-value .freeform-column input,
body#edit form .submit-row .freeform-column select,
body#edit form .submit-row .freeform-column textarea,
body#edit form .submit-row .freeform-column input {
  display: block !important;
  visibility: visible !important;
}
body#edit form .detailsofenquiry {
  grid-row: 1/span 6;
}
body#edit form .first-name,
body#edit form .last-name,
body#edit form .email,
body#edit form .product,
body#edit form .service-centre-contact {
  pointer-events: none;
}
body#edit form .first-name select,
body#edit form .first-name textarea,
body#edit form .first-name input,
body#edit form .last-name select,
body#edit form .last-name textarea,
body#edit form .last-name input,
body#edit form .email select,
body#edit form .email textarea,
body#edit form .email input,
body#edit form .product select,
body#edit form .product textarea,
body#edit form .product input,
body#edit form .service-centre-contact select,
body#edit form .service-centre-contact textarea,
body#edit form .service-centre-contact input {
  background-color: #eee;
}
body#edit form .detailsofenquiry {
  pointer-events: all;
}
body#edit form .detailsofenquiry textarea {
  background-color: #eee;
}
body#edit form .service-centre-contact .freeform-column label,
body#edit form .lead-status .freeform-column label,
body#edit form .internal-notes .freeform-column label,
body#edit form .order-value .freeform-column label {
  display: block !important;
  visibility: visible !important;
}

modal-wrapper {
  --wrapper-background-colour: rgba(255, 255, 255, 0.95);
  --container-background-colour: white;
  --min-width:300px;
  --max-width:1120px;
  --width:clamp(var(--min-width), 90vw, fit-content);
  --min-height:600px;
  --max-height:calc(100vh - var(--gutter2));
  --height:clamp(var(--min-height), 90vw, fit-content);
  --close-button-size: 30px;
  --nav-button-size: 40px;
  position: fixed;
  inset: 0;
  height: 100%;
  width: 100%;
  background-color: var(--wrapper-background-colour);
  z-index: 10;
  display: flex;
  place-items: center;
  place-content: center;
  transition: opacity 0.5s cubic-bezier(0.42, 0, 0.58, 1);
  overscroll-behavior: contain;
}
@supports (--v:-webkit-fill-available ) {
  modal-wrapper {
    --max-height:-webkit-fill-available;
  }
}
modal-wrapper.loading {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1) 0.3s;
}
modal-wrapper.loading modal-container {
  opacity: 0;
  transform: translateY(2vh);
  transition: opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1), transform 0.3s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
modal-wrapper button.close {
  position: absolute;
  top: var(--gutter1);
  right: var(--gutter1);
  width: var(--close-button-size);
  height: var(--close-button-size);
}
modal-wrapper button.close svg {
  border: 2px solid var(--secondary, #DF3035);
  border-radius: 50%;
  --fill:var(--secondary, #DF3035);
}
modal-wrapper button.close svg circle, modal-wrapper button.close svg path {
  transition: 0.3s cubic-bezier(0.42, 0, 0.58, 1);
}
@media (hover: hover) {
  modal-wrapper button.close:hover svg {
    --fill:white;
    --stroke:var(--secondary, #DF3035);
  }
}
modal-wrapper button.navigation {
  position: absolute;
  width: var(--nav-button-size);
  height: var(--nav-button-size);
  top: calc(50% - var(--nav-button-size) / 2);
  background-color: var(--secondary, #DF3035);
  border-radius: 50%;
  border: 2px solid var(--secondary, #DF3035);
  color: white;
  display: flex;
  place-content: center;
  place-items: center;
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), background-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
modal-wrapper button.navigation.previous {
  left: var(--gutter1);
}
modal-wrapper button.navigation.next {
  right: var(--gutter1);
}
modal-wrapper button.navigation svg {
  width: 50%;
  height: 50%;
}
@media (hover: hover) {
  modal-wrapper button.navigation:hover {
    transform: scale(1.1);
    color: var(--secondary, #DF3035);
    background-color: white;
  }
}

modal-container {
  transform: translateY(0);
  transition: opacity 0.5s cubic-bezier(0.42, 0, 0.58, 1) 0.3s, transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
  width: var(--width);
  height: var(--height);
  max-height: var(--max-height);
  max-width: var(--max-width);
  border: 1px solid #dcdcdc;
  border-radius: var(--border-radius, 6px);
  background-color: var(--container-background-colour);
  position: relative;
  display: flex;
  flex-direction: column;
  margin: var(--gutter1);
  overscroll-behavior: contain;
}
@supports not (width: clamp(1px, 5vw, 10px)) {
  modal-container {
    --width: 60vw;
    max-width: var(--max-width);
    min-width: var(--min-width);
    --height: 80vw;
    max-height: var(--max-height);
    min-height: var(--min-height);
  }
}
modal-container:empty {
  width: calc(var(--max-width) / 2);
  height: calc(var(--max-height) / 2);
}
modal-container:empty:after {
  --size: 20px;
  content: "";
  position: absolute;
  top: calc(50% - var(--size) / 2);
  left: calc(50% - var(--size) / 2);
  opacity: 0;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border: 2px solid var(--loader-colour, #969695);
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation-name: loader-rotate;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  transition: opacity 0.2s cubic-bezier(0.42, 0, 0.58, 1);
  animation-play-state: paused;
  opacity: 1;
  animation-play-state: running;
}
@keyframes loader-rotate {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
modal-container > button.close {
  position: relative;
  order: -1;
  align-self: flex-end;
  top: initial;
  right: initial;
  margin-top: calc(-1 * var(--close-button-size) / 2);
  margin-bottom: calc(-1 * var(--close-button-size) / 2);
  margin-right: calc(-1 * var(--close-button-size) / 2);
}

modal-content {
  display: block;
  position: sticky;
  overscroll-behavior: contain;
  width: inherit;
  height: inherit;
  max-height: inherit;
  max-width: inherit;
  overflow-y: auto;
  overflow-x: hidden;
  padding: var(--gutter1);
}
modal-content > * {
  max-width: 100%;
}

modal-wrapper[data-type=image] .image {
  max-height: calc(var(--max-height) - var(--gutter6));
  border: none;
}
modal-wrapper[data-type=image] .image img {
  max-height: calc(100vh - var(--gutter3) - var(--gutter4));
  width: auto;
  height: auto;
  margin: auto;
}

modal-wrapper[data-type=default] modal-content > img[src*=".svg"] {
  width: 100%;
  height: auto;
}

modal-wrapper[data-type=video] {
  --wrapper-background-colour: rgba(0, 0, 0, 0.95);
}
modal-wrapper[data-type=video] modal-container {
  --aspect-ratio: 16/9;
  aspect-ratio: var(--aspect-ratio);
  display: block;
  position: relative;
  width: 100%;
  background-color: black;
  background-color: white;
  padding: var(--gap);
}
@supports not (aspect-ratio: 16/9) {
  modal-wrapper[data-type=video] modal-container {
    aspect-ratio: initial;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
    height: 0;
  }
  modal-wrapper[data-type=video] modal-container > * {
    position: absolute;
  }
}
modal-wrapper[data-type=video] modal-container iframe, modal-wrapper[data-type=video] modal-container canvas {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100% !important;
  width: 100% !important;
}
modal-wrapper[data-type=video] modal-container modal-content {
  height: 100%;
  border-radius: var(--border-radius, 6px);
}

.open-in-modal {
  cursor: pointer;
  transition: opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
.open-in-modal:after {
  --size: 20px;
  content: "";
  position: absolute;
  top: calc(50% - var(--size) / 2);
  left: calc(50% - var(--size) / 2);
  opacity: 0;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border: 2px solid var(--loader-colour, white);
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation-name: loader-rotate;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  transition: opacity 0.2s cubic-bezier(0.42, 0, 0.58, 1);
  animation-play-state: paused;
}
@keyframes loader-rotate {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.open-in-modal.loading {
  pointer-events: none;
}
.open-in-modal.loading:after {
  opacity: 1;
  animation-play-state: running;
}

img.open-in-modal, .open-in-modal img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.content-block {
  display: grid;
  grid-gap: var(--gutter1);
  grid-template-columns: repeat(var(--column-count), 1fr);
}
.content-block:empty {
  display: none;
}
.content-block.blowout.themed ~ .content-block:not(.blowout).themed {
  margin-top: var(--spacer0);
}
.content-block:not(.blowout).themed {
  border-radius: var(--border-radius, 6px);
}
.content-block:not(.blowout).themed:not(:last-of-type) {
  margin-bottom: var(--spacer0);
}
.content-block + .content-block {
  box-shadow: 0px -1px var(--border-top-colour, #dcdcdc);
}
.content-block h2, .content-block h3, .content-block h4, .content-block h5, .content-block h6 {
  margin-bottom: var(--gap);
}
.content-block.grey {
  background: #F4F4F4;
}
.content-block.red {
  background: #DF3035;
}
.content-block.blue {
  background: #131f6b;
}

.text a {
  --font-colour: var(--primary, #131f6b);
}
@media (hover: hover) {
  .text a:hover {
    --font-colour: var(--secondary, #DF3035);
  }
}
.text a a.button {
  text-decoration: none !important;
}
.text ul > li::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Ccircle fill='%23DF3035' cx='8' cy='8' r='8'/%3E%3Cpath fill='none' stroke='white' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M3.5 8.4l2.7 2.7 6.3-6.3'/%3E%3C/svg%3E");
}
.text ul > li {
  position: relative;
  padding-left: calc(var(--list-style-size, 1em) * 1.8);
}
.text ul > li + li {
  margin-top: var(--line-style-spacing, var(--gap));
}
.text ul > li::before {
  display: inline-block;
  top: 0.1em;
  left: 0;
  position: absolute;
  width: var(--list-style-size, 1em);
  height: var(--list-style-size, 1em);
}
.text ol {
  counter-reset: counter;
}
.text ol > li {
  position: relative;
  display: block;
  padding-left: 22px;
  margin-left: var(--list-style-offset, 0);
}
.text ol > li + li {
  margin-top: calc(var(--gap) * 0.5);
}
.text ol > li::before {
  content: counter(counter) ".";
  counter-increment: counter;
  color: var(--list-style-colour, var(--primary));
  position: relative;
  margin-right: 0.5em;
}
.text p:empty, .text p > br {
  display: none;
}
.text > *:not(:last-child) {
  margin-bottom: var(--gap);
}
.text > * ul, .text > * ol {
  margin-top: var(--gap);
}
.text h2, .text h3, .text h4, .text h5, .text h6 {
  margin-bottom: var(--gap);
}
.text .heading-h3 {
  color: var(--secondary, #DF3035);
}
.text [style*="text-align: center"] {
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.text .quotes {
  border-left: 5px solid #dcdcdc;
  padding-left: var(--gap);
  font-style: italic;
}
.text figure {
  display: block;
  border-radius: var(--border-radius, 6px);
  background-color: white;
  padding: var(--gap);
}
.text figure img {
  max-width: 100%;
}
.text hr {
  margin-top: var(--spacer0) !important;
  margin-bottom: var(--spacer0) !important;
}
.text .note {
  margin: calc(var(--gap) * 2) 0;
}

.dark {
  --hr-colour: rgba(255, 255, 255, 0.3);
  --list-style-colour: white;
}
.dark .text {
  color: white;
  --heading-colour:white;
}
.dark .text a.button {
  --font-colour: white;
}
.dark .text a:not(.button) {
  --font-colour: rgba(255, 255, 255, 0.7);
  text-decoration: underline;
  text-underline-offset: 2px;
}
@media (hover: hover) {
  .dark .text a:not(.button):hover {
    --font-colour: white;
  }
}
.dark.red .text ul > li::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Ccircle fill='%23FFFFFF' cx='8' cy='8' r='8'/%3E%3Cpath fill='none' stroke='%23DF3035' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M3.5 8.4l2.7 2.7 6.3-6.3'/%3E%3C/svg%3E");
}
.dark.blue .text ul > li::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Ccircle fill='%23FFFFFF' cx='8' cy='8' r='8'/%3E%3Cpath fill='none' stroke='%23131F6B' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M3.5 8.4l2.7 2.7 6.3-6.3'/%3E%3C/svg%3E");
}

.contains-svgs .text figure {
  display: block;
  margin: 0 auto;
  padding: 0 var(--gap);
}
.contains-svgs .text figure img {
  height: 120px;
}

table-container {
  display: grid;
  grid-gap: var(--gap);
}
table-container > div {
  overflow: auto;
  border-radius: var(--border-radius, 6px);
  display: grid;
}
table-container > div:not(:hover) {
  scroll-behavior: smooth;
}
table-container > div::-webkit-scrollbar {
  width: 0.9em;
  height: 0.9em;
}
table-container > div::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
table-container > div::-webkit-scrollbar-thumb {
  background-color: #969695;
  border-radius: 10px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0.15);
}
table-container > div::-webkit-scrollbar-track {
  background-color: transparent;
}
table-container > div::-webkit-scrollbar-corner {
  background-color: transparent;
}
table-container h3 {
  margin-bottom: 0 !important;
}
table-container table.table {
  width: auto;
  grid-row: 1;
  grid-column: 1;
}
table-container table.table.fake {
  pointer-events: none;
  will-change: transform;
  transform: translateX(var(--x));
  width: calc(100% - var(--x));
  overflow: hidden;
  background-color: initial;
}
table-container table.table.fake tbody tr td {
  background: white;
  border-right: 1px #dcdcdc dashed;
}
table-container table.table.fake tbody tr:nth-of-type(odd) td {
  background: #F4F4F4;
}
table-container table.table.fake thead tr th:first-of-type {
  border-right: 1px rgba(255, 255, 255, 0.1) dashed;
}
table-container table.table.fake thead tr th:not(:first-of-type), table-container table.table.fake tbody tr td:not(:first-of-type) {
  visibility: hidden;
}
@supports (writing-mode: vertical-lr) {
  table-container.enable-vertical-headings table.table thead tr th:not(:first-of-type) span {
    display: inline-block;
    writing-mode: vertical-lr;
    max-width: 50px;
    text-align: right;
    transform: rotate(180deg);
  }
}
table-container nav {
  align-items: flex-end;
  justify-content: flex-end;
  grid-row: 1;
  grid-column: 2;
  display: none;
}
table-container nav button.navigation {
  align-self: flex-end;
}
table-container nav button.navigation + button {
  margin-left: calc(var(--gap) * 0.5);
}
table-container nav + div {
  grid-column: 1/span 2;
}
table-container.scrolled-right button.next, table-container.scrolled-left button.previous {
  pointer-events: none;
}
table-container.enable-scroll-buttons nav {
  display: flex;
}
table-container button.navigation {
  --button-size: 40px;
  background-color: white;
  border: 1px solid #dcdcdc;
  border-radius: 50%;
  z-index: 1;
  width: var(--button-size);
  height: var(--button-size);
  position: relative;
  display: flex;
  place-items: center;
  place-content: center;
  align-self: center;
  color: #6A6A6A;
  transition: background-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
table-container button.navigation.previous svg {
  margin-left: -2px;
}
table-container button.navigation.next svg {
  margin-left: 2px;
}
table-container button.navigation svg {
  width: calc(var(--button-size) / 2.5);
  height: calc(var(--button-size) / 2.5);
}
@media (hover: hover) {
  table-container button.navigation:hover {
    transform: scale(1.1);
    background-color: #131f6b;
    color: white;
    opacity: 1 !important;
    border-color: transparent;
  }
}

table.table {
  width: 100%;
  background-color: white;
  border-collapse: collapse;
  border-radius: var(--border-radius, 6px);
  text-align: left;
  position: relative;
  border-collapse: collapse;
}
table.table thead tr th {
  background-color: var(--primary, #131f6b);
  padding: var(--gap);
  color: white;
  white-space: nowrap;
}
table.table thead tr th:first-of-type {
  border-top-left-radius: var(--border-radius, 6px);
  overflow: hidden;
}
table.table thead tr th:last-of-type {
  border-top-right-radius: var(--border-radius, 6px);
  overflow: hidden;
}
table.table tbody tr td {
  padding: calc(var(--gap) * 0.5) var(--gap);
  position: relative;
}
table.table tbody tr td:first-of-type {
  min-width: 200px;
}
table.table tbody tr td strong {
  font-weight: 700;
  color: #6A6A6A;
}
table.table tbody tr td a {
  color: #131f6b;
  text-decoration: underline;
}
@media (hover: hover) {
  table.table tbody tr td a:hover {
    color: #DF3035;
  }
}
table.table tbody tr td svg {
  --fill: var(--primary, #131f6b);
  width: 1em;
  height: 1em;
}
table.table tbody tr td svg.cross {
  --fill: #DF3035;
}
table.table tbody tr td svg.tick {
  --fill: #50BC08;
}
table.table tr {
  position: relative;
}
table.table tr:not(:last-of-type) {
  border-bottom: 1px solid #dcdcdc;
}
table.table tr:nth-of-type(odd) {
  background-color: #F4F4F4;
}
table.table.alternating-row-colours tbody tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}
table.table.last-column-is-green tbody tr td:last-of-type {
  background-color: #131f6b;
  color: white;
}

.dark table-container > h3, .dark.content-block .tab-content > h3 {
  color: white;
}
.dark table-container > div, .dark.content-block .tab-content > div {
  border-radius: initial;
}
.dark table-container table.table, .dark.content-block .tab-content table.table {
  color: white;
  background-color: initial;
}
.dark table-container table.table tr th, .dark.content-block .tab-content table.table tr th {
  background-color: rgba(255, 255, 255, 0.15);
}
.dark table-container table.table tr, .dark.content-block .tab-content table.table tr {
  border: none !important;
}
.dark table-container table.table tr:nth-of-type(odd), .dark.content-block .tab-content table.table tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.15);
}

figure.image.static {
  border-radius: var(--border-radius, 6px);
  background-color: white;
  border: 1px solid #F4F4F4;
  height: fit-content;
}
figure.image.static img {
  --image-height-size: auto;
  position: relative;
}
figure.image.static figcaption {
  font-size: 0.75em;
  font-style: italic;
  padding: calc(var(--gap) * 0.5) var(--gap);
}
figure.image.static.no-caption {
  border: none;
}
figure.image.static.crop-image {
  --aspect-ratio: 16/9;
  aspect-ratio: var(--aspect-ratio);
  --aspect-ratio: inherit;
}
figure.image.static.crop-image figcaption {
  bottom: 0;
  position: absolute;
  left: 0;
  width: 100%;
  background-color: white;
}
@supports not (aspect-ratio: 16/9) {
  figure.image.static.crop-image {
    aspect-ratio: initial;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
    height: 0;
  }
  figure.image.static.crop-image > * {
    position: absolute;
  }
}
figure.image.static.crop-image img {
  --image-height-size: 100%;
}

.image.load-in-view img {
  transition: opacity 0.5s cubic-bezier(0.42, 0, 0.58, 1);
}
.image.load-in-view img:not([src]) {
  opacity: 0;
}

images-container {
  width: 100%;
  display: grid;
  grid-gap: var(--gutter0);
  grid-template-columns: repeat(auto-fill, minmax(calc(var(--main-max-width) / (var(--images-per-row) + 1)), 1fr));
}
images-container.crop-images .image {
  --aspect-ratio: 4/3;
  aspect-ratio: var(--aspect-ratio);
  --aspect-ratio: inherit;
}
@supports not (aspect-ratio: 4/3) {
  images-container.crop-images .image {
    aspect-ratio: initial;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
    height: 0;
  }
  images-container.crop-images .image > * {
    position: absolute;
  }
}
images-container.crop-images .image img {
  --image-height-size: 100%;
}
@supports not (aspect-ratio: 1/1) {
  images-container.crop-images .image img {
    position: absolute;
  }
}
images-container.crop-images .image figcaption {
  bottom: 0;
  position: absolute;
  left: 0;
  width: 100%;
  background-color: white;
}

tabbed-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
tabbed-content > nav button {
  transition: var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
tabbed-content > nav button[aria-selected=true] {
  pointer-events: none;
}
tabbed-content > nav button svg {
  width: 10px;
  height: 10px;
}
tabbed-content summary {
  display: none;
  --font-colour: white;
  --background-colour: var(--primary, #131f6b);
  background-color: var(--background-colour);
  position: relative;
  cursor: pointer;
  padding-top: var(--gap);
  padding-bottom: var(--gap);
  padding-left: var(--gutter1);
  padding-right: var(--gutter1);
  border-radius: var(--border-radius, 6px);
  align-items: center;
  transition: background-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), border-radius var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  outline: none;
  justify-content: space-between;
}
tabbed-content summary::marker {
  display: none;
}
tabbed-content summary, tabbed-content summary .heading {
  color: var(--font-colour);
  font-family: lato, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.0625em;
  line-height: 1.6;
}
tabbed-content summary .heading {
  margin-bottom: 0;
}
tabbed-content summary svg {
  height: 8px;
  transition: transform var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  position: absolute;
  right: var(--gutter1);
  top: calc(50% - 4px);
}
tabbed-content summary:hover, tabbed-content details[open] summary:hover {
  --background-colour:#1c2d9c;
}
tabbed-content .tab-content {
  grid-gap: var(--spacer0) 0;
  outline: none;
  position: relative;
  grid-auto-rows: min-content;
}
tabbed-content .tab-content:not([hidden]) {
  display: grid;
}
tabbed-content .tab-content .heading-h4 {
  font-size: 1.5625em;
  line-height: 1.6;
  color: #DF3035;
}
tabbed-content .tab-content .heading-h5 {
  font-size: 1.0625em;
  line-height: 1.6;
}
tabbed-content .tab-content .table-container {
  display: inline-flex;
  flex-direction: column;
}
tabbed-content .tab-content .table-container.halves {
  max-width: 40%;
  margin: 0 5% 0 0;
}
tabbed-content .tab-content .table-container.halves:nth-of-type(2n) {
  margin: 0 0 0 5%;
}
tabbed-content .tab-content .table-container.thirds {
  max-width: 30%;
}
tabbed-content .tab-content .table-container.thirds:nth-of-type(3n - 1) {
  margin: 0 5%;
}
tabbed-content table-container {
  width: 100%;
}
tabbed-content:not([data-style="3"]).in-view > nav button[aria-selected=true] {
  color: var(--secondary, #DF3035);
}
@media (min-width: 980px) and (max-width: 1300px), (max-width: 700px) {
  tabbed-content:not(.markets).allow-breakpoint-restyling {
    grid-gap: var(--gap) !important;
    display: grid;
  }
  tabbed-content:not(.markets).allow-breakpoint-restyling > nav {
    display: none !important;
  }
  tabbed-content:not(.markets).allow-breakpoint-restyling details {
    grid-column: 1/-1;
  }
  tabbed-content:not(.markets).allow-breakpoint-restyling details[hidden] {
    display: block;
  }
  tabbed-content:not(.markets).allow-breakpoint-restyling details summary {
    display: flex;
  }
  tabbed-content:not(.markets).allow-breakpoint-restyling details .tab-content {
    padding: var(--spacer0) var(--gutter1) !important;
    border-bottom: 1px solid #dcdcdc;
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
    overflow: hidden;
    background-color: white;
    border-bottom-left-radius: var(--border-radius, 6px);
    border-bottom-right-radius: var(--border-radius, 6px);
  }
  tabbed-content:not(.markets).allow-breakpoint-restyling details .tab-content > * {
    transition: opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
    opacity: 0;
  }
  tabbed-content:not(.markets).allow-breakpoint-restyling details .tab-content figure.image {
    flex-direction: column;
  }
  tabbed-content:not(.markets).allow-breakpoint-restyling details[open] summary {
    --background-colour:#0f1956;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  tabbed-content:not(.markets).allow-breakpoint-restyling details[open] summary svg {
    transform: rotate(180deg);
  }
  tabbed-content:not(.markets).allow-breakpoint-restyling details[open] .tab-content > * {
    opacity: 1;
  }
}
tabbed-content[data-style="4"].allow-breakpoint-restyling {
  grid-gap: var(--gap) !important;
  display: grid;
}
tabbed-content[data-style="4"].allow-breakpoint-restyling > nav {
  display: none !important;
}
tabbed-content[data-style="4"].allow-breakpoint-restyling details {
  grid-column: 1/-1;
}
tabbed-content[data-style="4"].allow-breakpoint-restyling details[hidden] {
  display: block;
}
tabbed-content[data-style="4"].allow-breakpoint-restyling details summary {
  display: flex;
}
tabbed-content[data-style="4"].allow-breakpoint-restyling details .tab-content {
  padding: var(--spacer0) var(--gutter1) !important;
  border-bottom: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  overflow: hidden;
  background-color: white;
  border-bottom-left-radius: var(--border-radius, 6px);
  border-bottom-right-radius: var(--border-radius, 6px);
}
tabbed-content[data-style="4"].allow-breakpoint-restyling details .tab-content > * {
  transition: opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  opacity: 0;
}
tabbed-content[data-style="4"].allow-breakpoint-restyling details .tab-content figure.image {
  flex-direction: column;
}
tabbed-content[data-style="4"].allow-breakpoint-restyling details[open] summary {
  --background-colour:#0f1956;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
tabbed-content[data-style="4"].allow-breakpoint-restyling details[open] summary svg {
  transform: rotate(180deg);
}
tabbed-content[data-style="4"].allow-breakpoint-restyling details[open] .tab-content > * {
  opacity: 1;
}
@media (max-width: 1000px) {
  tabbed-content.markets.allow-breakpoint-restyling {
    grid-gap: var(--gap) !important;
    display: grid;
  }
  tabbed-content.markets.allow-breakpoint-restyling > nav {
    display: none !important;
  }
  tabbed-content.markets.allow-breakpoint-restyling details {
    grid-column: 1/-1;
  }
  tabbed-content.markets.allow-breakpoint-restyling details[hidden] {
    display: block;
  }
  tabbed-content.markets.allow-breakpoint-restyling details summary {
    display: flex;
  }
  tabbed-content.markets.allow-breakpoint-restyling details .tab-content {
    padding: var(--spacer0) var(--gutter1) !important;
    border-bottom: 1px solid #dcdcdc;
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
    overflow: hidden;
    background-color: white;
    border-bottom-left-radius: var(--border-radius, 6px);
    border-bottom-right-radius: var(--border-radius, 6px);
  }
  tabbed-content.markets.allow-breakpoint-restyling details .tab-content > * {
    transition: opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
    opacity: 0;
  }
  tabbed-content.markets.allow-breakpoint-restyling details .tab-content figure.image {
    flex-direction: column;
  }
  tabbed-content.markets.allow-breakpoint-restyling details[open] summary {
    --background-colour:#0f1956;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  tabbed-content.markets.allow-breakpoint-restyling details[open] summary svg {
    transform: rotate(180deg);
  }
  tabbed-content.markets.allow-breakpoint-restyling details[open] .tab-content > * {
    opacity: 1;
  }
}

tabbed-content[data-style="1"] {
  --side-nav-width: 200px;
  display: grid;
  grid-template-columns: var(--side-nav-width) 1fr;
  grid-gap: var(--gutter1);
}
tabbed-content[data-style="1"] > nav {
  display: flex;
  flex-direction: column;
  position: sticky;
  height: fit-content;
  top: calc(var(--breadcrumb-height) + var(--anchors-height) - var(--gutter1));
}
tabbed-content[data-style="1"] > nav button {
  padding: var(--gap) 0;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
tabbed-content[data-style="1"] > nav button svg {
  margin-left: var(--gap);
}
tabbed-content[data-style="1"] > nav button + button {
  border-top: 1px solid #dcdcdc;
}

tabbed-content[data-style="2"] > nav {
  display: flex;
  justify-content: center;
}
tabbed-content[data-style="2"] > nav button {
  padding: calc(var(--gap) * 2) var(--gutter2);
  text-align: center;
  border: 1px solid #dcdcdc;
  border-bottom: none;
  border-top-left-radius: var(--border-radius, 6px);
  border-top-right-radius: var(--border-radius, 6px);
  margin-left: calc(var(--gap) * 0.5);
  margin-right: calc(var(--gap) * 0.5);
  font-size: 0.875em;
  background-color: white;
  color: var(--primary, #131f6b);
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), background-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), border-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
tabbed-content[data-style="2"] > nav button svg {
  display: none;
}
@media (hover: hover) {
  tabbed-content[data-style="2"] > nav button:hover {
    color: var(--secondary, #DF3035);
  }
}
tabbed-content[data-style="2"] > nav button[aria-selected=true] {
  background-color: #F4F4F4;
  border-color: transparent;
}
tabbed-content[data-style="2"] .tab-content {
  padding: var(--spacer1) var(--gutter1);
  background: linear-gradient(#F4F4F4, white);
}
tabbed-content[data-style="2"].inview nav button[aria-selected=true] {
  background-color: #F4F4F4;
  color: var(--secondary, #DF3035);
}
@media (max-width: 480px) {
  tabbed-content[data-style="2"]:not(.allow-breakpoint-restyling)[data-count="2"] [role=tab] {
    width: 50%;
    padding: var(--gap);
  }
}

tabbed-content[data-style="3"] > nav {
  display: flex;
  justify-content: center;
  margin-bottom: var(--gap);
  border-radius: 50px;
  border: 1px solid #dcdcdc;
  align-self: center;
  background-color: white;
  position: relative;
}
tabbed-content[data-style="3"] > nav button {
  position: relative;
  text-align: center;
  border: none !important;
  text-transform: uppercase;
  font-size: 0.875em;
  border-radius: inherit;
  z-index: 1;
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), background-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  padding: var(--gap) var(--gutter1);
}
tabbed-content[data-style="3"] > nav button svg {
  display: none;
}
tabbed-content[data-style="3"] > nav button[aria-selected=true] {
  color: white;
}
@media (hover: hover) {
  tabbed-content[data-style="3"] > nav button:not([aria-selected=true]):hover {
    color: #DF3035;
  }
}
tabbed-content[data-style="3"] > nav:not(.has-marker) button[aria-selected=true] {
  background-color: #131f6b;
}
tabbed-content[data-style="3"] > nav mark {
  background-color: initial;
  color: inherit;
  border-radius: initial;
  padding: initial;
  transition: initial;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #131f6b;
  width: 50%;
  border-radius: inherit;
  pointer-events: none;
  opacity: 0;
  transition: opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
tabbed-content[data-style="3"] > nav mark.active {
  opacity: 1;
}
tabbed-content[data-style="3"] .tab-content {
  padding: var(--gutter1) 0;
}
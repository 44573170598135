@use '_modules' as *;



.header {

  padding-top:$spacer;
  padding-bottom:$spacer;

  > h1 sup {
    font-size:0.6em;
  }

  > h1 span{
    display: block;
    font-size:font-size(6);
    color: $grey;
  }

  > h1 + *, > h2 + * {
    margin-top:$gutter;
  }

  > h1 + h2 { margin-top:$gutter0; }

  > h2 {
    color:var(--sub-heading-colour); 
    @include font-lato(semi-bold);
    a { 
      --font-colour : var(--sub-heading-colour); 
      text-decoration: underline;
      @include hover { --font-colour : #{$primary}; }
    }
  }

  time {
    border-radius: $border-radius;
    margin:$gutter 0; 
    padding:4px 8px;
    background-color:$grey3;
    border:1px solid $grey4;  
    font-size:font-size(12);
    display: inline-block;
    width: fit-content;
    border-radius: 4px;
    border-left:4px solid var(--tag-colour, $red);
  }

  button-container {
    margin-bottom:$gap-; 
    margin-top:$spacer0;
    @include break-in(small) {
      .button span {
        white-space: normal;
      }
    }
  }

  notice-messages { margin-top:$spacer0; }

  &.dark {
    --heading-colour:white;
    --sub-heading-colour:white;
    .button.brochure {
      --font-colour:white;
      svg { fill: white; }
    }
  }

  &.no-devider-line {
    ~ main {
      --border-top-colour : #{rgba(white, 0.2)};
      > .content-block:first-of-type {
        padding-top:0; 
        box-shadow: initial;
      }
    }    
  }

}
@use '_modules' as *;

////////////////////////////////////////////////////////////////////////////////
// Layout
////////////////////////////////////////////////////////////////////////////////

// Layout styling should be used to adjust layout properties only.
// Where possible, it should not be used to manage the aestetics of a page.
// Instead only widths, height, display, grid, position, padding, margins, order, and alike
// should be the properties defined in these files. Keep the layouts here and
// compnent styling in their respective files.

@mixin sidebar-blowout-offset() {
  --translateX: 0;
  width: calc(100vw + #{$gutter});
  left: #{$gutter-};
  @include break-out(max) {
    left: calc((-1 * var(--computed-gutter)) - #{$gutter});
  }
}

$component-themes: (
  'red': $red,
  'blue': $blue,
  'grey': $grey3,
  'white': $white,
);

body {
  // Grid Positions
  // I'm using numbers rather than grid asreas because the positioning doesn't
  // need to be any more complex than a number between 1 and 4 depending on
  // the layout requirements. This is essentially done to handle circomstances
  // where some pages don't require a breadcrumb bar or anchor bar.

  --row-navigation: 1;
  --row-breadcrumbs: 2;
  --row-anchors: 3;
  --row-header: 4;
  --row-main: 5;
  --row-background: 1;
  --row-sidebar: 4 / span 3;
  --row-footer: 100;
  --rows: auto;

  --column-left: 2;
  --column-main: 2 / span 2;
  --column-right: 4;
  --column-full: 2 / -2;
  --column-gutter: minmax(0, 1fr);
  --column-content: minmax(0, calc(#{$media-max} - (var(--sidebar-width) * 2) - #{$gutter4}));
  --column-sidebar: var(--sidebar-width);
  --columns: var(--column-gutter) var(--column-sidebar) var(--column-content) var(--column-sidebar) var(--column-gutter);

  display: grid;
  min-width: $media-min;
  grid-gap: 0 $gutter;
  grid-template-rows: var(--rows);
  grid-template-columns: var(--columns);

  > * {
    grid-column: var(--column-full);
  }
}

navigation-bar {
  z-index: 999;
  grid-row: var(--row-navigation);
  &:not(.in-view) {
    ~ #breadcrumbs-bar,
    ~ #anchors-bar {
      --anchors-height: 50px;
      // --breadcrumb-height: 70px;
    }
  }
}

// Breadcrumbs Bar =============================================================

#breadcrumbs-bar {
  z-index: 2;
  grid-row: var(--row-breadcrumbs);
  height: var(--breadcrumb-height);
  &.sticky {
    top: var(--breadcrumb-and-anchors-sticky-underset);
  }
}

// Anchor Bar ==================================================================

#anchors-bar {
  z-index: 2;
  grid-row: var(--row-anchors);
  height: var(--anchors-height);
  &.sticky {
    top: calc(var(--breadcrumb-height) - 1px + (var(--breadcrumb-and-anchors-sticky-underset) * 2));
    // top:calc(var(--breadcrumb-height) - 1px);
  }
}

// Header Content ==============================================================

.header {
  grid-row: var(--row-header);
  grid-column: var(--column-main);
  max-width: var(--main-max-width);
}

#footer {
  grid-row: var(--row-footer);
}

// Main Content ================================================================

main {
  grid-row: var(--row-main);
  grid-column: var(--column-main);
  max-width: var(--main-max-width);
  display: flex;
  flex-direction: column;

  // First level elements for main childen and siblins -------------------------
  // to the main element (excluding side-bar and footer)

  > *,
  ~ section,
  ~ aside,
  ~ div,
  ~ service-centre-finder,
  ~ markets-summary {
    position: relative;
    padding-top: $spacer;
    padding-bottom: $spacer;
    flex-grow: 1;
  }

  ~ div:has(div#truendo_container) {
    padding: 0;
  }

  service-centre-finder picture {
    @include sidebar-blowout-offset();
  }
}

// Sidebar Content =============================================================

side-bar {
  grid-row: var(--row-sidebar);
  grid-column: var(--column-right);
  width: var(--sidebar-width);
  padding-top: $spacer;
  padding-bottom: $spacer;

  > div {
    display: grid;
    grid-gap: $gutter $gutter;
    grid-template-columns: repeat(1, minmax(var(--sidebar-width), 1fr));
    &.sticky {
      top: calc(var(--breadcrumb-height) + var(--anchors-height) + #{$gutter});
    }
    > * {
      position: relative;
    }
  }

  @each $theme, $colour in $component-themes {
    > div > *.#{$theme} {
      --background-colour: #{$colour};
      @if ($theme == 'white') {
        --border-colour: #{$grey3};
      }
      @if ($theme == 'grey') {
        --border-colour: #{$grey4};
      }
    }
  }
}

aside#support-information .glossary-terms,
tabbed-content[data-style='1'] > nav {
  top: calc(var(--breadcrumb-height) + var(--anchors-height) + #{$gutter});
}

@include break-in('small-medium') {
  body {
    > * {
      width: 100%;
    }
    --columns: var(--column-gutter) var(--column-content) var(--column-gutter);
    --column-main: var(--column-full);
    --column-left: var(--column-full);
    --column-right: var(--column-full);
    --row-sidebar: auto;

    side-bar {
      padding-top: $spacer;
      > div {
        grid-gap: $spacer $gutter;
        &.sticky {
          position: relative;
          top: initial;
        }
      }
    }
  }
}

// This will fix sidebar components from blowing out of the viewport on devices
// where the screen is smaller than the minium (320px)
@include break-in($media-min + ($base-gap * 2)) {
  body {
    --sidebar-width: 100%;
  }
}

// Blowout Backgrounds =========================================================

.themed {
  @each $theme, $colour in $component-themes {
    &.#{$theme} {
      background: $colour;
      &.blowout + .themed.#{$theme} {
        box-shadow: 0px 1px var(--border-top-colour);
        &.light {
          --border-top-colour: #{$grey4};
        }
        &.dark {
          --border-top-colour: #{rgba(white, 0.2)};
        }
      }
    }
  }
  &:not(.blowout) {
    padding-left: $gutter;
    padding-right: $gutter;
    margin-bottom: $spacer;
    margin-top: $spacer;
    border-radius: $border-radius;
    &.white {
      border: 1px solid $grey3;
      + .blowout.white,
      + :not(.themed) {
        margin-top: $spacer-;
      }
    }
  }
}

.blowout {
  @include background-blowout {
    @include break-in($media-min + $base-gap) {
      transform: initial;
      left: $gap-;
      width: 100vw;
    }
    @include break-in(small-medium) {
      transform: initial;
      left: 0;
      margin-left: $gutter-;
      width: 100vw;
    }

    background: inherit;
  }

  @at-root body:not(mobile) main .blowout:before {
    @include break-out(small-medium) {
      @include sidebar-blowout-offset();
      width: calc(100vw + #{$gutter});
    }
  }
  @at-root body.mobile main .blowout:before {
    @include sidebar-blowout-offset();
    width: calc(100vw + #{$gutter});
  }
}

// Background Image ============================================================

background-image,
background-video {
  z-index: -1;
  grid-row: var(--row-background);
  grid-column: 1 / -1;
  &.behind-header {
    --row-background: 4;
  }
}

img[data-src],
video[data-src] {
  transition: opacity 0.3s ease-in;
  opacity: 0;

  &.loaded {
    opacity: 1;
  }
}

// Special Rules ===============================================================

// Adds a top border (without shifting elements around by 1px) to none themed siblings of the main element
main ~ *:not(.themed) + :not(.themed):not(#footer):not(side-bar) {
  box-shadow: 0px -1px var(--border-top-colour, #{$grey4});
}
// main > *.themed + :not(.themed) { box-shadow: initial; }
main > *.themed.content-block {
  box-shadow: initial;
}
main > *.themed + :not(.themed):not(#footer) {
  margin-top: 0;
  box-shadow: initial;
}
main ~ *.themed + :not(.themed):not(#footer),
body.background-positioned-behind-header main > *:first-child {
  box-shadow: initial;
}

// Adds a border on the first child if it's not themed or is white
main > *:first-child:not(.themed),
main > *:first-child.white {
  box-shadow: 0px -1px var(--border-top-colour, #{$grey4});
}

// main ~ section, main ~ aside, main ~ div, main ~ logo-scroller { grid-row: none; }
// main ~ :not(side-bar):not(background-image):not(#footer) { grid-row: none; }

// Removes margin on none themed siblings of and in the main element that also are not blownout
main ~ *:not(.themed) + .themed:not(.blowout),
main > *:not(.themed) + .themed:not(.blowout) {
  margin-top: 0;
}

// Addds a larger spacer to the last item in the main element that are themed but not blown out
.header ~ main > .themed:not(.blowout):first-child {
  margin-top: $spacer;
}
main > .themed:not(.blowout):last-child {
  margin-bottom: $spacer;
}

// Adds a larger spacer to themed non blownout elements
main > .themed:not(.blowout) + .blowout {
  margin-top: $spacer;
}

.header ~ main > .themed:not(.blowout) {
  margin-top: 0;
}

main ~ div:has(#CookiebotWidget) {
  padding-top: 0;
  padding-bottom: 0;
}

@include component-doesnt-exists('side-bar') {
  --main-max-width: auto;
  --column-main: var(--column-full);
}

@include component-doesnt-exists('breadcrumbs-bar') {
  --row-navigation: 1;
  --row-anchors: 2;
  --row-header: 3;
  --row-main: 4;

  background-image.full,
  background-video.full {
    --row-background: 1 / span 4;
  }
  background-image.behind-header,
  background-video.full {
    --row-background: 3;
  }

  #anchors-bar.sticky {
    top: 0;
  }

  @include break-out('small-medium') {
    --row-sidebar: 3 / span 3;
    side-bar div.sticky,
    aside#support-information .glossary-terms,
    tabbed-content[data-style='1'] > nav {
      top: calc(var(--anchors-height) + #{$gutter});
    }
  }
}

@include component-doesnt-exists('anchors-bar') {
  --row-navigation: 1;
  --row-breadcrumbs: 2;
  --row-header: 3;
  --row-main: 4;

  background-image.full,
  background-video.full {
    --row-background: 1 / span 4;
  }
  background-image.behind-header,
  background-video.behind-header {
    --row-background: 3;
  }

  #breadcrumbs-bar.sticky {
    top: 0;
  }

  @include break-out('small-medium') {
    --row-sidebar: 3 / span 3;
    side-bar div.sticky,
    aside#support-information .glossary-terms,
    tabbed-content[data-style='1'] > nav {
      top: calc(var(--anchors-height) + #{$gutter});
    }
  }
}

@include component-doesnt-exists('breadcrumbs-bar', 'anchors-bar') {
  --row-navigation: 1;
  --row-header: 2;
  --row-main: 3;
  --row-background: 1;

  background-image.full,
  background-video.full {
    --row-background: 1 / span 5;
  }
  background-image.behind-header,
  background-video.behind-header {
    --row-background: 2;
  }

  @include break-out('small-medium') {
    side-bar div.sticky,
    aside#support-information .glossary-terms,
    tabbed-content[data-style='1'] > nav {
      top: $gutter;
    }
    --row-sidebar: 2 / span 3;
  }
}

body.rule-last-main-section-is-themed {
  @include break-in('small-medium') {
    side-bar {
      padding-top: 0;
    }
  }
}

// Faux Layouts ================================================================

.layout {
  &-content-right-sidebar,
  &-content-left-sidebar {
    display: grid;
    grid-gap: $gutter;
    grid-template-columns: 1fr;
    .left,
    .right {
      grid-column: 1 / -1;
      max-width: 100%;
    }

    .layout {
      &-content-right-sidebar,
      &-content-left-sidebar {
        --column-content: minmax(0, calc(#{$media-max} - (var(--sidebar-width) * 2)));
      }
    }
  }
}

@mixin faux-layout-grid($selector: 'body > .layout', $breakpoint: 'small-medium') {
  @include break-out($breakpoint) {
    #{$selector} {
      // Content on the right, sidebar on the left
      &-content-left-sidebar {
        .left {
          grid-column: 1;
        }
        .right {
          grid-column: 2 / 4;
        }
      }
      // Content on the left, sidebar on the right
      &-content-right-sidebar {
        .left {
          grid-column: 1 / 3;
          max-width: var(--main-max-width);
        }
        .right {
          grid-column: 3;
          padding-left: $gutter;
          padding-right: $gutter;
        }
      }
      &-content-right-sidebar,
      &-content-left-sidebar {
        grid-template-columns: var(--column-sidebar) var(--column-content) var(--column-sidebar);
      }
    }
  }
}

@include faux-layout-grid();

// This handles when there is not sidebar but there is a content block component
// that would normally be full width. This keeps the layout styling consistent
// accross full width components, content block components. and sidebar components.
@include component-doesnt-exists('side-bar') {
  @include faux-layout-grid('main .layout', 'medium');
}

// Little Layout field column controls =========================================

.content-block {
  > * {
    grid-column: 1 / -1;
  }

  @include break-out(700) {
    --column-count: 6;

    // The first number in the .col-X-X class name referes to the column starting point,
    // The second number is how many columns it spans for.
    // You may be wondering why I didn't just stick with the one-liner using custom properties.
    // That method of column grids is too strict and would never reliable breakdown
    // natively because the content would always become overblown.
    // We had to go the manual way so that we can address special situations as speicfic break points.

    // One Column ------------------------
    .col-1-1 {
      grid-column: 1 / span 1;
    }
    .col-1-2 {
      grid-column: 1 / span 2;
    }
    .col-1-3 {
      grid-column: 1 / span 3;
    }
    .col-1-4 {
      grid-column: 1 / span 4;
    }
    .col-1-5 {
      grid-column: 1 / span 5;
    }
    .col-1-6 {
      grid-column: 1 / span 6;
    }

    // Two Columns -----------------------
    .col-2-1 {
      grid-column: 2 / span 1;
    }
    .col-2-2 {
      grid-column: 2 / span 2;
    }
    .col-2-3 {
      grid-column: 2 / span 3;
    }
    .col-2-4 {
      grid-column: 2 / span 4;
    }
    .col-2-5 {
      grid-column: 2 / span 5;
    }

    // Three Columns ---------------------
    .col-3-1 {
      grid-column: 3 / span 1;
    }
    .col-3-2 {
      grid-column: 3 / span 2;
    }
    .col-3-3 {
      grid-column: 3 / span 3;
    }
    .col-3-4 {
      grid-column: 3 / span 4;
    }

    // Four Columns ----------------------
    .col-4-1 {
      grid-column: 4 / span 1;
    }
    .col-4-2 {
      grid-column: 4 / span 2;
    }
    .col-4-3 {
      grid-column: 4 / span 3;
    }

    // Five Columns ----------------------
    .col-5-1 {
      grid-column: 5 / span 2;
    }
    .col-5-2 {
      grid-column: 5 / span 2;
    }

    // Six Columns -----------------------
    .col-6-1 {
      grid-column: 6 / span 1;
    }
  }

  @include break-between(700, large) {
    --column-count: 4;

    // Special Rules ------------------------
    .col-1-3 {
      grid-column: 1 / span 2;
    }
    .col-1-4 {
      grid-column: 1 / span 3;
    }
    .col-1-5 {
      grid-column: 1 / span 3;
    }
    .col-4-3 {
      grid-column: 3 / -1;
    }

    // Breakdown Rules ----------------------
    .col-1-6 {
      grid-column: 1 / -1;
    }
    .col-2-3,
    .col-2-4,
    .col-2-5 {
      grid-column: 2 / -1;
    }
    .col-3-2,
    .col-3-3,
    .col-3-4 {
      grid-column: 3 / -1;
    }
    .col-4-1,
    .col-4-2,
    .col-5-1,
    .col-6-1 {
      grid-column: 4 / -1;
    }
    .col-5-2 {
      grid-column: 1/ -1;
    }
  }

  @include break-between(min, 699) {
    --column-count: 2;

    .col-1-1,
    .col-3-1,
    .col-5-1 {
      grid-column: 1;
    }
    .col-2-1,
    .col-4-1,
    .col-6-1 {
      grid-column: 2;
    }

    @include break-in(500) {
      .col-1-1 {
        grid-column: 1 / -1;
      }
      .col-1-5 + .col-6-1 {
        grid-column: 1 / -1;
      }
    }
    @include break-out(500) {
      .col-2-5 {
        grid-column: 2;
      }
      .col-1-5 {
        grid-column: 1;
      }
    }
  }
}

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// Dev Styling -----------------------------------------------------------------

// navigation-bar, header, main, main > *, side-bar, #footer, aside {
//   position:relative;
// }

// navigation-bar {
//   background-color:rgba($red, 0.5);
//   border-color:$red;
// }

// .header {
//   background-color:rgba($orange, 0.5);
//   border-color:$orange;
// }

// #breadcrumbs-bar {
//   background-color:rgba($blue, 0.5);
//   border-color:$blue;
// }

// #anchors-bar {
//   background-color:rgba($purple, 0.5);
//   border-color:$purple;
// }

// main {
//   background-color:rgba($green, 0.5);
//   border-color:$green;
// }

// side-bar {
//   background-color:rgba($purple, 0.5);
//   border-color:$purple;
// }

// #footer {
//   background-color:rgba($brown, 0.5);
//   border-color:$brown;
// }

////////////////////////////////////////////////////////////////////////////////
// Buttons
////////////////////////////////////////////////////////////////////////////////

@use '../_variables' as *;
@use "styling" as *;
 
@mixin button($colour : var(--primary)) {

  // Static Styling
  --font-colour       : white;
  --background-colour : #{$colour};
  --border-colour     : transparent;
  --padding           : 8px 20px;
  --font-size         : 16px;
  --border-radius     : 50px;

  // Hover / Active styling
  --hover-font-colour       : #{$colour};
  --hover-background-colour : white;
  --hover-border-colour     : initial;
  --hover-padding           : var(--padding);
  --hover-font-size         : var(--font-size);
  --hover-border-radius     : var(--border-radius);

  --icon-size : 1.1em;

  cursor         : pointer;
  display        : inline-flex;
  position       : relative;
  user-select    : none;  
  border-style   : solid;
  border-width   : 1px;
  word-break     : break-all; 
  font-weight    : bold;;
  place-content  : center;
  place-items    : center;
  align-self     : flex-start;
  text-transform : uppercase;
  
  color            : var(--font-colour); 
  background-color : var(--background-colour);
  border-color     : var(--border-colour); 
  padding          : var(--padding);
  font-size        : var(--font-size);
  border-radius    : var(--border-radius);
  transition       : background-color var(--transition-duration) var(--transition-ease), 
                     color var(--transition-duration) var(--transition-ease), 
                     border var(--transition-duration) var(--transition-ease);

  // Icon ----------------------------------------------------------------------
  
  svg {
    width        : var(--icon-size); 
    height       : var(--icon-size); 
    order        : 3;
    fill         : var(--fill, currentColor);
    margin-left  : calc(var(--icon-size) / 2);
    transition   : fill var(--transition-duration) var(--transition-ease), 
                   stroke var(--transition-duration) var(--transition-ease);

    &[class*="arrow"] { 
      --icon-size : 0.9em;
    }               
    &.arrow-left {
      margin-right : calc(var(--icon-size) / 2);
      margin-left  : 0 !important;
      order        : 1;
    }
  }

  > span { order : 2; }

  // Variant Declarations ------------------------------------------------------
  
  &.small  { @include small; }
  &.large  { @include large; }
  &.outline { @include outline-theme($blue); }

  &.blue   { 
    @include solid-theme($blue, white);
    &.outline { @include outline-theme( $blue, $white ) }
  }
  &.red    { 
    @include solid-theme($red, white);
    &.outline { @include outline-theme( $red, $white ) }
  }
  &.green    { 
    @include solid-theme($green2, white);
    &.outline { @include outline-theme( $green2, $white ) }
  }
  &.white  { 
    @include solid-theme($white, $primary);
    &.outline { @include outline-theme($white, $primary) }
  }
  &.disabled  { 
    pointer-events: none;
    @include solid-theme($grey4);
    &.outline { @include outline-theme($grey4) }
  }
  
  // Hover State ---------------------------------------------------------------
  
  @include hover {
    color            : var(--hover-font-colour); 
    background-color : var(--hover-background-colour);
    border-color     : var(--hover-border-colour); 
    border-radius    : var(--hover-border-radius); 
    padding          : var(--hover-padding);
    font-size        : var(--hover-font-size );
  }
}

/// Large Button ===============================================================
/// These extend the styling of markup elements with the .buttton class 
/// @content is passed through is for the hover state only. 
/// @example 
/// .button {
///   @include button-large {
///     --background-colour : green;
///     --font-colour     : red;
///   }
/// }

@mixin large {
  --padding   : 12px 24px;
  --font-size : 24px;
  @content;
}

/// Small Button ===============================================================
/// These extend the styling of markup elements with the .buttton class 
/// @content is passed through is for the hover state only.  
/// @example 
/// .button {
///   @include button-small {
///     --background-colour : green;
///     --font-colour     : red;
///   }
/// }

@mixin small {
  --padding    : 6px 18px;
  --font-size  : 12px;

  svg { 
    margin-left : $gap0; 
    &.arrow-left { margin-right : $gap0; }
  }

  @content;
}

/// Varient Styling ============================================================

@mixin solid-theme($static : $primary, $hover : white) {
  --background-colour       : #{$static};
  --font-colour             : #{$hover};
  --hover-background-colour : #{$hover};
  --hover-font-colour       : #{$static};
}

@mixin outline-theme($static : $primary, $hover : transparent) {
  --background-colour       : transparent;
  --font-colour             : #{$static};
  --border-colour           : #{$static};
  --hover-background-colour : #{$static};
  --hover-font-colour       : #{if($hover == transparent, $static, $hover)};
  --hover-border-colour     : transparent;
}

/// Button Container ===========================================================

@mixin container {
  display:flex;
  // margin-left:$gap0-;
  // width:calc(100% + #{$gap});
  flex-wrap: wrap;
  width:100%;
  position:relative;
  .button {
    white-space: nowrap;
    margin:$gap0; 
  }
}

/// Bespoke Buttons ============================================================

@mixin brochure {
  --font-colour         : #{$blue};
  --background-colour   : transparent;
  --padding             : 5px 0px;
  --icon-size           : 32px;
  --border-colour       : transparent;
  --border-radius       : 0; 
  --hover-font-colour   : #{$red};
  --hover-border-colour : transparent;
  --hover-background-colour : transparent;

  flex-direction: row-reverse;
  text-transform : uppercase;
  svg { 
    margin-right:$gap0; 
    margin-left:0; 
    fill:$red; 
  }
}
////////////////////////////////////////////////////////////////////////////////
// Custom Properties
////////////////////////////////////////////////////////////////////////////////

@use '_modules' as *;
@use 'sass:math';

:root {

  // Colours -------------------------------------------------------------------

  --primary         : #{$blue}; 
  --secondary       : #{$red}; 

  // Breakpoints ---------------------------------------------------------------
  
  @each $name, $value in $breakpoints {
    --breakpoint-#{$name} : #{$value};
  }

  // Fonts ---------------------------------------------------------------------
  
  --font-colour        : #{$font-colour};
  --heading-colour     : #{var(--primary, $blue)};
  --sub-heading-colour : #{var(--secondary, $red)};

  // Transitions ---------------------------------------------------------------

  --transition-duration : #{$transition-duration};
  --transition-ease     : #{$transition-ease};
  --transition-delay    : #{$transition-delay};

  // Layout --------------------------------------------------------------------

  --breadcrumb-height : 90px;
  --anchors-height : 60px;
  --breadcrumb-and-anchors-sticky-underset : -15px;
  --main-max-width : 900px;
  --navigation-height: 143px;

  --sidebar-width : clamp(#{$sidebar-width - math.div($sidebar-width, 100) * 40}, 30vw, #{$sidebar-width});
  
  @supports not ( width:clamp(1px, 5vw, 10px)) {
    --sidebar-width : #{$sidebar-width};
    @include break-in(large) {
      --sidebar-width : 300px;
    }
  }

  // Units ---------------------------------------------------------------------

  --gap    : #{$base-gap};
  --gutter : #{$base-gutter};
  --spacer : #{$base-spacer};
 
  /// Set the gutters and spacers with clamp 
  /// @see https://developer.mozilla.org/en-US/docs/Web/CSS/clamp
  @for $i from 0 through 6 {

    --gutter#{$i}  : #{gutter($i)}; 
    --gutter#{$i}- : #{gutter($i, $use-negative : true)};

    $spacer-growth : if($i == 0, 1, $i * 3);
    --spacer#{$i}  : #{spacer($spacer-growth)}; 
    --spacer#{$i}- : #{spacer($spacer-growth, $use-negative : true)};
  }
  
  /// Sets the gutters and spacers using the calc 
  /// method for browsers that don't support clamp
  @supports not ( width:clamp(1px, 5vw, 10px)) {
    @for $i from 0 through 6 {

      --gutter#{$i}  : #{gutter($i, $use-clamp : false )}; 
      --gutter#{$i}- : #{gutter($i, $use-clamp : false, $use-negative : true )};

      $spacer-growth : if($i == 0, 2, $i * 4);
      --spacer#{$i}  : #{spacer($spacer-growth, $use-clamp : false )}; 
      --spacer#{$i}- : #{spacer($spacer-growth, $use-clamp : false, $use-negative : true )};
    }    
  }

  /// This is the calculated width of a single gutter that occurs when the users
  /// viewport is larger than the largest media query breakpoint. Primarly used to
  /// offset overblown content for preventing horizontal scrolling on the <body>.
  // --computed-gutter : calc(((100vw - #{var(--breakpoint-max)}) / 2) - #{var(--gutter2)});
  --computed-gutter : calc(((100vw - #{var(--breakpoint-max)}) / 2));


}
@use '_modules' as *;

#breadcrumbs-bar {

  background-color:$primary;
  display:flex;
  align-items: center;
  transition: height $transition, top $transition;

  ul {
    display: flex;
    position: relative; 
    left:$gap-;
    width: 100%;;
    li {
      align-self: stretch;
      align-items: center;
      position:relative;
      + li { margin-left:$gap-; }
    }
  }

  .link {
    display: flex;
    place-items: center;
    height:100%;
    text-transform: uppercase;
    font-weight:bold; 
    font-size : font-size(18);
    padding:$gap; 
    text-decoration: none;
    color:white; 
    svg {
      color:rgba(white, 0.8); 
      width:0.7em;
      height:0.7em;
      margin-left:$gap;
    }
    @include hover('.current') {
      color:$secondary;
    }
    &.current::after {
      content:"";
      bottom:0;
      left:0; 
      width: 100%;
      height:4px; 
      background-color: $secondary;
      position: absolute;
    }
  }

  // a {
  //   grid-row:1; 
  //   font-size : font-size(22);
  //   font-weight:bold; 
  //   white-space: nowrap;
  //   display:block; 
  //   --font-colour : white; 
  //   @include hover {
  //     color:$secondary;
  //   }
  // }


  // @include component-doesnt-exists('background-image') {
		// &::before { border-top:1px solid rgba(white, 0.2); }
	// }

}

body.mobile > #breadcrumbs-bar {
  max-height: initial;
  
  /// If you want this back in, you'll have to edit the toggle logic in the isMobileBreakPoint method here: 
  ///  @see src/scripts/components/sections/navigation-bar.js
  display:none; 
}


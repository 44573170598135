@use '_modules' as *;

// Dropdown Menu =============================================================

dropdown-menu {
  --min-height: 430px;
  --max-height: 600px;
  --dropdown-group-nav-width: 270px;
  --dropdown-image-width: 300px;
  --dropdown-image-height: 80px;
  --dropdown-open-offset: 6px;

  position: absolute;
  top: calc(100% - var(--marker-line-thickness) - 1px);
  background-color: white;
  border-radius: $border-radius;
  box-shadow: 0px 2px 4px rgba(black, 0.2);
  display: grid;
  grid-template-columns: var(--dropdown-group-nav-width) 1fr;
  pointer-events: none;
  opacity: 0;
  margin-top: 0px;
  overscroll-behavior: contain;
  user-select: none;
  transition: opacity 0.3s $ease-in-out, margin-top 0.3s $ease-in-out-sine;
  font-size: 16px;

  @at-root navigation-bar nav main-menu.active {
    .top-level-links .top-level-link .link.current::after {
      opacity: 0;
    }
    dropdown-menu {
      margin-top: var(--dropdown-open-offset);
      opacity: 1;
      pointer-events: all;
    }
  }

  &::after {
    content: '';
    width: 100%;
    height: calc(var(--marker-line-thickness));
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 5;
    background-color: $red;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  @at-root body.mobile navigation-bar & {
    --dropdown-open-offset: #{$gap};
    width: var(--dropdown-group-nav-width) !important;
    right: 0;
    @include break-in(small) {
      --dropdown-group-nav-width: calc(100vw - #{$gutter3}) !important;
    }
  }

  // @at-root body::after {
  //   content:"";
  //   opacity:0;
  //   pointer-events: none;;
  //   transition: $transition;
  //   inset:0;
  //   top:var(--navigation-height, 0);
  //   width:100vw;
  //   height: 100vh;
  //   position: fixed;
  //   background-color: rgba($white, 0.90);
  // }
  // @at-root body.mobile.menu-visible {
  //   @include break-in(small) {
  //     &::after {
  //       opacity:1;
  //     }
  //   }
  // }
}

// Dropdown Groups -----------------------------------------------------------

dropdown-group {
  position: relative;
  grid-column: 1;
  grid-row: 1;
  overflow-x: hidden;
  // overflow-y: hidden;
  background-color: white;
  opacity: 0;
  height: inherit;
  pointer-events: none;
  will-change: transform;
  transition: opacity 0.3s $ease-in-out, transform 0.3s $ease-in-out;
  // transition-delay: 0.3s, 0.3s;

  $max-depth: 4;
  @for $i from 0 through $max-depth {
    &[data-level='#{$i}'] {
      z-index: ($max-depth - $i) + 1;
    }
  }

  @include scroll-styling {
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  }

  .link {
    --font-size: 0.9em;
    display: flex;
    position: relative;
    align-items: center;
    text-align: left;
    line-height: 1.4;
    padding: 4px $gap2;
    width: 100%;
    transition: color $transition, background-color $transition;
    justify-content: space-between;

    &.current {
      color: $red;
    }

    &.active {
      color: white;
      background-color: $blue;
      z-index: 1;
      // This will give links with children a angled shape to act as a hit helper.
      // It prevent users from having to move the mouse directly to the right before
      // being able to move up the new sub menu. Set a temporary background-color to see it in action
      @include browser-without-feature('touch') {
        &::after {
          content: '';
          display: block;
          position: absolute;
          transform: translateY(-50%) rotate(-45deg);
          left: calc(100% - 50px);
          top: 50%;
          width: 100px;
          height: 100px;
        }
      }
      // This adjust the hit helper on the last link to acoid unwanted scrollers
      @at-root :not([data-browser-feature*='touch']) dropdown-group .last .link.active::after {
        transform: translateY(-100%) rotate(0deg) skew(-45deg, 0);
      }
    }
    &.has-children {
      svg {
        margin-left: $gap0;
      }
    }

    @include hover() {
      color: white;
      background-color: $blue;
    }
  }

  // We need to handle the top and bottom padding slightly
  // differently because gridded lists have some issues with the bottom
  // padding when overflow scroll is active
  ul {
    display: flex;
    flex-direction: column;
    overscroll-behavior: contain;

    li {
      &.first {
        padding-top: $gap;
      }
      &.last {
        padding-bottom: $gap;
      }
      &.overview {
        margin-top: $gap;
        & ~ .first {
          @media (max-width: 768px) {
            padding-top: 0;
          }
        }
        .link {
          color: $blue;
          font-weight: bold;
          @include hover('.active') {
            color: white;
          }
        }
        @include browser-without-feature('touch') {
          display: none;
        }
      }
      &.back {
        padding-top: $gap;
        @include browser-without-feature('touch') {
          display: none;
        }
        .link {
          svg {
            margin-right: 0.4em;
            margin-left: 0;
          }
        }
      }
    }
  }

  &[data-level='1'] {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    li.back {
      display: none;
    }
  }

  &[data-level='2'] {
    width: 95%;
    left: 100%;
    opacity: 0;
    transform: translateX(-5%);
    background-color: $grey3;
  }

  &[data-level='3'] {
    width: calc(95% + 2px);
    opacity: 0;
    left: calc(195% - 1px);
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    transform: translateX(-5%);
    background-color: #fafafa;
    transition-delay: 0.3s, 0.3s;
  }

  &.allow-scroll {
    overflow-y: auto;
  }

  &.active {
    opacity: 1;
    pointer-events: all;
    &[data-level='2'],
    &[data-level='3'] {
      transform: translateX(0%);
    }
  }

  &:not(.active) {
    &[data-level='1'],
    &[data-level='2'],
    &[data-level='3'] {
      left: 0%;
      transform: translateX(0%);
    }
  }

  @at-root body:not(.mobile) navigation-bar &:not([data-level='1']) {
    .link {
      // --font-size:0.8em;
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }

  @at-root body.mobile navigation-bar & {
    border-radius: $border-radius $border-radius 0 0 !important;
    left: 0 !important;
    width: inherit !important;
    transition-duration: 0.5s, 0.5s;
    transform: initial !important;
    // padding-bottom : var(--dropdown-image-height);
    $max-depth: 4;
    @for $i from 0 through $max-depth {
      &[data-level='#{$i}'] {
        z-index: $i + 1;
      }
    }

    .link {
      --font-size: 1em;
      padding-top: $gap0;
      padding-bottom: $gap0;
    }
    ul {
      li {
        &.overview,
        &.back {
          display: block !important;
        }
      }
    }
  }
}

// Dropdown Image ------------------------------------------------------------

dropdown-image {
  @include image-srcset();
  // height:inherit;
  height: 100%;
  grid-column: 2;
  background-color: $grey3;
  border-bottom-right-radius: $border-radius;
  border-top-right-radius: $border-radius;
  display: grid;
  justify-items: flex-end;

  .image {
    // height:var(--max-height);
    width: clamp(var(--dropdown-image-width) / 2, 30vw, var(--dropdown-image-width));
    grid-row: 1;
    grid-column: 1;
    transition: opacity 0.3s $ease-in-out;
    opacity: 0;
    &.active {
      opacity: 1;
      pointer-events: all;
      img {
        opacity: 1;
      }
    }
  }

  @at-root body.mobile navigation-bar & {
    // display:none;
    grid-column: 1;
    // grid-row:1;
    z-index: 10;
    width: inherit;
    height: var(--dropdown-image-height);
    border-top-right-radius: initial;
    border-bottom-left-radius: $border-radius;
    // margin-top:calc(-1 * var(--dropdown-image-height));
    .image {
      width: inherit;
      height: inherit;
    }
  }
}

@use '_modules' as *;

navigation-bar {
  position: relative;
  display: flex;
  padding: calc(#{$spacer0} + #{$gap}) 0;
  align-items: flex-end;
  justify-content: space-between;
  align-items: center;
  // background:linear-gradient(42deg, $blue3, $blue4);
  background-color: $blue4;
  z-index: 5;
  gap: $gutter;
  height: min-content;

  --top-level-link-gutters: #{$gap};
  --top-level-link-colour: white;
  --marker-line-thickness: 3px;
  --spacer-line-thickness: 1px;

  // @at-root body:not([style*="--logo-tagline-height"]) { --logo-tagline-height : 10px; }

  @include break-in(small-medium) {
    align-items: center;
  }

  @include background-blowout {
    background: inherit;
  }

  > *:not(.image) {
    position: relative;
    z-index: 1;
  }

  @include component-exists('background-image') {
    &.hide-background {
      background-color: initial;
      background: initial;
      &::before {
        display: none;
      }
    }
  }
  @include component-exists('background-video') {
    &.hide-background {
      background-color: initial;
      background: initial;
      &::before {
        display: none;
      }
    }
  }

  &.invert {
    background-color: initial;
    background: initial;
    --top-level-link-colour: #{$blue};
    &::before {
      display: none;
    }
    a.logo {
      img {
        &[src*='tagline.svg'] {
          filter: invert(0.9);
        }
      }
    }
  }

  // Logo ================================================================

  a.logo {
    max-width: 320px;
    max-width: 280px;
    width: 100%;
    min-width: 200px;

    img {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      &[src*='logo.svg'] {
        clip-path: polygon(0 0, 100% 0, 100% 90%, 0 90%);
      }
      &[src*='tagline.svg'] {
        margin-top: $gap0;
      }
    }

    &::after {
      content: '';
      width: 100%;
      height: 3px;
      position: absolute;
      left: 0;
      bottom: 0;
      // top:calc(90% - 3px - #{$gap0});
      // bottom:calc(var(--logo-tagline-height) - 3px - #{$gap0});
      bottom: calc(var(--logo-tagline-height, #{$gap0-}) + #{$gap0});
      background-color: $red;
    }
  }

  // Navigation ================================================================

  nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    gap: 8px;

    // Deviding Spacer Lines ---------------------------------------------------
    // We need to handle none top-level-link items in a special way.
    // If you should end up adding more buttons similar to the "search" in this
    // area, you will need amend the :nth-last-child index

    main-menu > ul > li.top-level-link:not(:nth-last-child(2)):not(:nth-last-child(3)):not(:last-of-type)::before,
    secondary-menu > ul > li:not(:last-of-type)::before {
      content: '';
      width: var(--spacer-line-thickness);
      height: 40%;
      position: absolute;
      right: calc(-1 * (var(--spacer-line-thickness) / 2));
      top: 50% - 20%;
      background-color: var(--spacer-line-colour, $red);
    }

    personalise-experience {
      display: none;
    }

    .personalised {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: $gap;

      // Personalised Experience Bar
      personalise-experience {
        display: flex;
        width: fit-content;

        a.button.personalised {
          --font-size: 0.8em;
          --padding: 4px 10px;

          @media (max-width: 940px) {
            --font-size: 16px;
            --padding: 8px 20px;
            --icon-size: 20px;
            p {
              display: none;
            }
            svg {
              margin-left: 0;
            }
          }
          @media (max-width: 600px) {
            max-height: 36px;
            --icon-size: 1.1em;
          }
        }

        & + secondary-menu {
          width: fit-content;
          justify-content: flex-end;
        }
      }

      @media (max-width: 440px) {
        top: unset;
        flex-wrap: nowrap;
        .button,
        personalise-experience a.button.personalised {
          --padding: 8px;
          --icon-size: 0.8em;
        }
      }
    }

    main-menu > ul > li.top-level-link + .search {
      margin-left: -2px;
    }

    // Generic Link Styling ----------------------------------------------------

    .link {
      font-size: var(--font-size);
      text-decoration: none;
      svg {
        margin-left: 0.4em;
        height: 0.5em;
        fill: $white;
        &:not(.arrow-right):not(.arrow-left) {
          height: 0.8em;
        }
      }
      &.overview {
        font-weight: bold;
      }
    }
    .link svg,
    .button svg {
      pointer-events: none;
    }

    // Main Menu ---------------------------------------------------------------

    main-menu {
      --font-size: 0.95em;
      order: 2;
      align-self: flex-end;
      position: relative;
      .top-level-links {
        display: flex;
        align-items: center;
        li {
          position: relative;
          &.top-level-link {
            a {
              font-weight: bold;
              text-transform: uppercase;
              color: var(--top-level-link-colour);
              padding: $gap0 var(--top-level-link-gutters);
              height: 100%;
              white-space: nowrap;
              display: flex;
              place-items: center;
              &::after {
                content: '';
                height: 3px;
                width: calc(100% - (var(--top-level-link-gutters) * 2));
                background-color: var(--top-level-link-colour, $red);
                position: absolute;
                bottom: 0;
                left: var(--top-level-link-gutters);
                opacity: 0;
                transition: opacity $transition;
              }
              &.active::after {
                opacity: 1 !important;
              }
              &.current::after {
                opacity: 1;
              }
            }
            &[data-nav='menu'] {
              order: 1;
            }
            &[data-nav='submenu'] {
              order: 2;
              display: none;
            }
          }
          // Search Button
          &.search {
            white-space: nowrap;
            a {
              text-decoration: none;
            }
            svg {
              margin-left: 0;
            }
            span {
              margin-right: $gap0;
              pointer-events: none;
            }
          }
          &.search,
          &.menu {
            order: 3;
          }
          &.cart {
            margin-right: calc(var(--top-level-link-gutters) / 2);
            left: -2px;
            a {
              text-decoration: none;
              padding: 8px 10px;
              span {
                display: none;
              }
            }
            svg {
              order: 1;
              margin-left: 0;
            }
            @at-root li.cart {
              a[data-total] {
                &::after {
                  content: attr(data-total);
                  min-width: 2ch;
                  width: fit-content;
                  padding: 0 0.4em;
                  margin-left: 0.5em;
                  height: 2ch;
                  align-self: center;
                  background-color: $red;
                  color: white;
                  line-height: 0.2;
                  text-align: center;
                  display: flex;
                  place-content: center;
                  place-items: center;
                  font-size: 0.8em;
                  border-radius: $border-radius;
                  order: 2;
                }
                @include hover {
                  &::after {
                    color: $white;
                  }
                  background-color: $blue;
                  color: $white;
                  svg {
                    fill: $red;
                  }
                }
              }
              a:not([data-total]) {
                @include hover {
                  &::after {
                    color: $white;
                  }
                  background-color: $blue;
                  svg {
                    fill: $red;
                  }
                }
              }
            }
            @media (min-width: 550px) {
              svg {
                display: none;
              }
              a {
                padding: 8px 20px;
                span {
                  display: block;
                }
              }
            }
          }
        }
      }

      &.loading {
        &::before {
          @include loader($colour: white);
        }
        @include hover() {
          &::before {
            @include loader-animation {
              transition-delay: 0s;
            }
          }
        }
      }
    }

    // Secondary Menu ----------------------------------------------------------

    secondary-menu {
      --font-size: 0.8em;
      display: flex;
      order: 1;
      align-self: flex-end;
      z-index: 6;
      > ul {
        display: flex;
        position: relative;
        right: $gap-;
        li {
          position: relative;

          a {
            text-transform: uppercase;
            color: var(--top-level-link-colour);
            padding: $gap0 $gap;
            display: flex;
            place-items: center;
            transition: color $transition;
            @include hover {
              color: $blue5;
            }
          }
          .sub-menu {
            display: grid;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            right: 50%;
            min-width: calc(100% + #{$gap2});
            width: 200px;
            transform: translate(50%, $gap0-);
            border-radius: $border-radius;
            background-color: $primary;
            transition: opacity $transition, transform $transition;
            border: 1px solid rgba(white, 0.1);

            li {
              &:nth-of-type(1) {
                padding-top: $gap0;
              }
              &:last-of-type {
                padding-bottom: $gap0;
              }
            }

            a {
              padding: calc(#{$gap0} / 2) $gap;
              text-transform: initial;
              color: white;
              &.asset {
                display: flex;
                svg {
                  order: -1;
                  margin: 0 0.4em 0 0;
                  align-self: flex-start;
                  margin-top: 0.4em;
                }
              }
            }

            // Overview <li> link element
            &[data-level='1'] li:first-of-type {
              order: 100;
              @include browser-without-feature('touch') {
                display: none;
              }
            }
          }
          @include hover {
            > a {
              color: $blue5;
            }
            .sub-menu {
              opacity: 1;
              transform: translate(50%, 0);
              pointer-events: all;
            }
          }

          &:last-of-type .sub-menu {
            right: calc(100% + #{$gap}) !important;
          }
        }

        // Triangle / Pointer
        // We render this on the <li> rather than the dropdown-group element because
        // we want this to be centred to the button. There are cases where the
        // last <li> will be aligned to the right of the viewport, that way
        // these can't ever be pushed out of view should the users screen starts
        // to become responsive below the max view width.
        > li.has-children {
          &::after {
            content: '';
            top: calc(100% + 1px);
            left: 50%;
            width: 10px;
            height: 10px;
            transform: translate(-50%, calc(-50% + #{$gap0-})) rotate(45deg);
            border: 1px solid rgba(white, 0.1);
            background-color: $primary;
            position: absolute;
            clip-path: polygon(0 0, 100% 0%, 50% 50%, 0 100%);
            opacity: 0;
            transition: opacity $transition, transform $transition;
            pointer-events: none;
          }
          @include hover {
            &::after {
              opacity: 1;
              transform: translate(-50%, -50%) rotate(45deg);
            }
          }
        }
      }
    }
  }

  // Responsive Styling ========================================================

  @include break-in(1400) {
    --top-level-link-gutters: #{$gap};
    nav main-menu > ul li.search span {
      display: none;
    }
  }

  @include break-in(980) {
    nav main-menu {
      --font-size: 0.9em;
    }
  }

  // Mobile Styling ============================================================

  li.menu {
    display: none;
    margin-left: var(--top-level-link-gutters);
  }

  button.menu {
    width: 40px;
    height: 40px;

    svg {
      width: 100%;
      height: 100%;
      display: block;
      pointer-events: none;
      line {
        stroke-width: 3px;
        stroke: var(--top-level-link-colour);
        &:nth-child(1) {
          transform-origin: 15% 30%;
          transition: transform 0.3s $ease-in-out-quint;
        }
        &:nth-child(2) {
          transition: opacity $transition;
        }
        &:nth-child(3) {
          transform-origin: 15% 70%;
          transition: transform 0.3s $ease-in-out-quint;
        }
      }
    }
    &.active {
      svg {
        line {
          &:nth-child(1) {
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  @at-root body.mobile & {
    padding: $gutter 0;
    li.menu {
      display: block;
      height: 40px;
    }

    @media (min-width: 980px) and (max-width: 1200px) {
      .button {
        &.search {
          --font-size: 0.8em;
          --padding: 4px 10px;
        }
      }
      li.menu {
        display: flex;
        align-items: center;
        button.menu {
          height: 30px;
          width: 30px;
        }
      }
    }

    a.logo {
      max-width: initial;
      width: 200px;
      min-width: initial;
    }
    nav {
      main-menu .top-level-link,
      secondary-menu {
        display: none;
      }
      main-menu > ul li.search span,
      main-menu > ul li[data-nav='submenu'] {
        display: block;
      }
    }
    @include break-in(980) {
      nav main-menu > ul li.search span {
        display: none;
      }
    }
  }
}

/// Global typographic styling that effects everything.
/// If you want to update styling for content block text fields, introduction fields,
/// or anything assigned a .text class on it's container then edit this file:
/// @see /src/sass/components/text

@use '_modules' as *;

@font-face {
  font-family: 'lato';
  src: url('../../../assets/fonts/lato-bold.woff2') format('woff2'), url('../../../assets/fonts/lato-bold.woff') format('woff'),
    url('../../../assets/fonts/lato-bold.otf') format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'lato';
  src: url('../../../assets/fonts/lato-semibold.woff2') format('woff2'), url('../../../assets/fonts/lato-semibold.woff') format('woff'),
    url('../../../assets/fonts/lato-semibold.otf') format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'lato';
  src: url('../../../assets/fonts/lato-regular.woff2') format('woff2'), url('../../../assets/fonts/lato-regular.woff') format('woff'),
    url('../../../assets/fonts/lato-regular.otf') format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'montserrat';
  src: url('../../../assets/fonts/montserrat-medium.woff2') format('woff2'), url('../../../assets/fonts/montserrat-medium.woff') format('woff'),
    url('../../../assets/fonts/montserrat-medium.otf') format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'montserrat';
  src: url('../../../assets/fonts/montserrat-medium-italic.woff2') format('woff2'), url('../../../assets/fonts/montserrat-medium-italic.woff') format('woff'),
    url('../../../assets/fonts/montserrat-medium-italic.otf') format('opentype');
  font-display: swap;
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'montserrat';
  src: url('../../../assets/fonts/montserrat-semibold.woff2') format('woff2'), url('../../../assets/fonts/montserrat-semibold.woff') format('woff'),
    url('../../../assets/fonts/montserrat-semibold.otf') format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}

body {
  @include font-montserrat();
  color: var(--font-colour, $font-colour);
  line-height: $font-line-height;
  font-size: $font-size;
  @include responsive-fontsize($font-size-min, $font-size-max);
}

h1 {
  @include heading-h1;
}
h2 {
  @include heading-h2;
}
h3 {
  @include heading-h3;
}
h4 {
  @include heading-h4;
}
h5 {
  @include heading-h5;
}
h6 {
  @include heading-h6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include font-lato(bold);
  color: var(--heading-colour, var(--font-colour));
  text-wrap: pretty;

  &.sub-heading {
    color: var(--sub-heading-colour);
    @include font-lato(semi-bold);
  }

  span,
  strong,
  p {
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
  }
}

q,
blockquote {
  quotes: '“' '”' '‘' '’';
  &::before {
    content: open-quote;
  }
  &::after {
    content: close-quote;
  }
}

a {
  transition: color $transition;
  text-decoration: none;
  text-underline-position: under;
  color: var(--font-colour);
  &.external {
    display: inline-flex;
    align-items: center;
    svg.external-link {
      opacity: 0.6;
      width: 0.8em;
      height: 0.8em;
      margin-left: 0.5em;
    }
  }
}

ol,
ul {
  list-style: none;
  list-style-position: inside;
}

li {
  a {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
}

small {
  font-size: font-size(10);
  line-height: 1.8;
}

hr {
  background-color: var(--hr-colour, $grey4);
  margin-top: $spacer0;
  margin-bottom: $spacer0;
}

.note {
  position: relative;
  display: block;
  background-color: #fff5bf;
  border: 1px solid #ffe13c;
  padding: $gutter;
  border-radius: $border-radius;
  a {
    text-decoration: underline;
    color: #978002;
  }
  &::before {
    content: 'Please Note';
    text-transform: uppercase;
    position: relative;
    font-size: 10px;
    font-weight: bold;
    color: #978002;
    display: block;
    margin-bottom: $gap0;
  }
}

@use '_modules' as *;

side-bar form {
  .freeform-form-success {
    background-color: $green;
    color: white;
    width: calc(100% + 30px);
    padding: $gap0 $gap;
    border-radius: $border-radius;
    margin: 0 -15px $gap;
  }
  .submit-row {
    margin-top: $gap;
  }
}

form {
  @include form-select();
  @include form-input();
  @include form-textarea();

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--font-colour, inherit);
    -webkit-box-shadow: 0 0 0px 1000px var(--background-colour, white) inset;
  }

  &[data-freeform] {
    label + div {
      font-size: font-size(12);
      margin-bottom: $gap0;
    }
    .freeform-row {
      + .freeform-row > div {
        margin-top: $gap;
      }
    }
    .freeform-column {
      margin: 0;
      padding: 0;
    }

    .input-group-one-line {
      @include form-checkbox();
    }
  }
}

side-bar form {
  label {
    color: white;
    ~ div {
      color: white;
    }
  }
}

form#login {
  display: flex;
  flex-direction: column;
  > label ~ label {
    margin-top: $gap;
  }
  div {
    display: flex;
    justify-content: space-between;
    margin-top: $gap;
    font-size: font-size(12);
    a {
      @include hover {
        color: $red;
      }
    }
  }

  button {
    margin-top: $gap;
  }
}

form#reset-password {
  background-color: $blue;
  border-radius: $border-radius;
  color: white;
  padding: $gutter;
  display: flex;
  flex-direction: column;
  button {
    margin-top: $gap;
  }
}

required-notice {
  display: block;
  width: 5px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: var(--notice-colour, $green);
  border-top-right-radius: 6px;
  transition: background-color $transition;
  border-bottom-right-radius: 6px;
  span {
    pointer-events: none;
    white-space: nowrap;
    font-size: font-size(12);
    top: 50%;
    right: calc(100% + #{$gap0});
    border: 1px solid $grey4;
    border-radius: 4px;
    background-color: white;
    padding: 4px $gap0;
    position: absolute;
    opacity: 0;
    transform: translate(10px, -50%);
    transition: $transition;
  }
  @include hover {
    span {
      transform: translate(0px, -50%);
      opacity: 1;
    }
  }
}

form[data-handle='contactUs'],
form[data-handle='powderContact'] {
  .freeform-form-success,
  .freeform-form-errors {
    background-color: $green;
    color: white;
    grid-column: 1 / -1;
    width: 100%;
    padding: $gap0 $gap;
    border-radius: $border-radius;
    margin-bottom: $gutter;
  }
  .freeform-form-errors {
    background-color: $red;
  }

  .form-fields {
    display: grid;
    grid-gap: $gutter $gutter;

    @include break-out(600) {
      grid-template-columns: repeat(2, 1fr);

      .freeform-row {
        &:first-child {
          grid-row: 1;
        }

        &.first-name,
        &.last-name,
        &.email,
        &.company-name,
        &.contact-no,
        &.y-rqg181k-d {
          grid-column: 1;
        }
        &.detailsofenquiry {
          grid-column: 2;
          grid-row: 2 / span 5;
        }
        &.submit-row {
          grid-column: 2;
        }
      }

      button[type='submit'] {
        align-self: flex-end;
      }
    }

    h2 {
      display: flex;
      align-items: center;
      svg.email {
        width: 1em;
        margin-right: 0.5em;
        height: 1em;
        fill: $red;
      }
    }

    .freeform-row {
      display: flex;
      flex-direction: column;
      position: relative;
      grid-column: 1 / span 2;

      &.detailsofenquiry {
        .freeform-instructions {
          display: none;
        }
        textarea {
          height: 100%;
        }
        .freeform-column {
          height: 100%;
        }
      }
      .freeform-column {
        margin: 0;
        display: flex;
        flex-direction: column;
        position: relative;
        label:not(.freeform-input-only-label) {
          display: none;
        }
      }

      &.service-centre-contact,
      &.lead-status,
      &.internal-notes,
      &.order-value {
        display: none;
      }
    }

    label.freeform-input-only-label {
      font-size: font-size(14);
      grid-column: 1;
      input {
        width: 20px;
        height: 20px;
        margin-right: $gap0;
        &:checked {
          background-color: $green;
        }
      }
    }

    select,
    textarea,
    input:not([type='submit']) {
      --border-colour: none;
      --background-colour: white;
    }
  }

  .freeform-errors {
    color: $red;
    font-weight: bold;
    font-size: font-size(10);
    padding: 2px $gap;
    position: absolute;
    top: calc(100% - 4px);
    background-color: $red;
    color: white;
    width: 100%;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  button[type='submit'] {
    @include break-in(400) {
      width: 100%;
    }
  }

  .freeform-input[required]:not(:valid) ~ required-notice {
    --notice-colour: #{$red};
  }

  &:not(:valid) {
    button[type='submit'] {
      pointer-events: none;
      @include button-outline-theme($grey);
    }
  }

  .service-centre {
    display: none !important;
  }
}

form[data-handle='contactUs'] {
  .form-fields {
    @include break-out(600) {
      grid-template-columns: repeat(2, 50%);

      .freeform-row {
        &:first-child {
          grid-row: 1;
        }

        &.first-name,
        &.last-name,
        &.email,
        &.company-name,
        &.contact-no,
        &.y-rqg181k-d,
        &.product {
          grid-column: 1 / 2;
        }
        &.detailsofenquiry {
          grid-column: 2 / -1;
          grid-row: 2 / span 6;
        }
      }
    }
  }
}

body#edit {
  form {
    textarea,
    select,
    input {
      border: 1px solid black;
    }
    .submit-row {
      order: 20;
    }

    .freeform-row {
      display: none;
    }
    .first-name,
    .last-name,
    .email,
    .product,
    .detailsofenquiry,
    .service-centre-contact,
    .lead-status,
    .internal-notes,
    .order-value,
    .submit-row {
      display: block;
      .freeform-column {
        select,
        textarea,
        input {
          display: block !important;
          visibility: visible !important;
        }
      }
    }

    .detailsofenquiry {
      grid-row: 1 / span 6;
    }

    .first-name,
    .last-name,
    .email,
    .product,
    .service-centre-contact {
      pointer-events: none;
      select,
      textarea,
      input {
        background-color: #eee;
      }
    }

    .detailsofenquiry {
      pointer-events: all;
      textarea {
        background-color: #eee;
      }
    }

    .service-centre-contact,
    .lead-status,
    .internal-notes,
    .order-value {
      .freeform-column {
        label {
          display: block !important;
          visibility: visible !important;
        }
      }
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
// Global Styling
////////////////////////////////////////////////////////////////////////////////

@use '_modules' as *;

body {
  @include break-out(min) {
    overflow-x: hidden;
  }
}

:where(*) {
  scroll-margin-top: var(--navigation-height, 2rem);
}

.async-hide {
  opacity: 0 !important;
}

main:empty {
  display: none;
}

figure.image,
picture.image {
  @include image-srcset();
}

video-container {
  @include video-container();
}

mark {
  @include mark();
}

details {
  outline: none;
}
summary {
  -webkit-appearance: none;
  &::-webkit-details-marker,
  &::marker {
    display: none;
  }
}

.button {
  @include button();
  &.brochure {
    @include button-brochure();
  }
  &.red svg {
    &.tick,
    &.download {
      --stroke: #{$red};
    }
  }
  &.blue svg {
    &.tick,
    &.download {
      --stroke: #{$blue};
    }
  }
  &.outline svg {
    &.tick,
    &.download {
      --stroke: #{$white};
    }
  }
  @include hover {
    &.red {
      svg.tick,
      svg.download {
        --stroke: #{$white};
      }
      &.outline svg {
        &.tick,
        &.download {
          --stroke: #{$red};
        }
      }
    }
    &.blue {
      svg.tick,
      svg.download {
        --stroke: #{$white};
      }
      &.outline svg {
        &.tick,
        &.download {
          --stroke: #{$blue};
        }
      }
    }
    &.white svg {
      &.tick,
      &.download {
        --stroke: #{$blue};
      }
    }
  }
}

button-container {
  @include button-container();
}

.sticky {
  position: sticky;
  top: 0px;
}

input[type='search'] {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
    -webkit-appearance: none;
  }
}

#holding-page {
  header svg {
    max-width: 400px;
  }
  @include break-in('small-medium') {
    display: flex;
    flex-direction: column;
    padding: $gutter;
  }
}

body#error-404 {
  header h2 {
    max-width: 900px;
  }
  search-bar {
    margin-bottom: $spacer0;
    input[type='search'] {
      border: 1px solid $blue;
    }
  }
}

body > .back-to-top {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: $gutter;
  right: $gutter;
  background: $blue2;
  border: 2px solid white;
  border-radius: 50%;
  z-index: 9999;
  opacity: 0;
  display: flex;
  pointer-events: none;
  transition: opacity $transition, background-color $transition, bottom $transition;
  place-content: center;
  place-items: center;
  svg {
    position: relative;
    color: white;
    width: 50%;
    top: -4%;
  }
  @include hover {
    background-color: $red;
  }
}

footer#footer.in-view ~ .back-to-top {
  bottom: 120px;
}

navigation-bar:not(.in-view) ~ .back-to-top {
  opacity: 1;
  pointer-events: all;
}

@use '_modules' as *;

@mixin accordion() {

  &.allow-breakpoint-restyling {

    grid-gap:$gap !important;
    display:grid;

    > nav { display: none !important; }
    details {
      grid-column: 1 / -1;
      &[hidden] { display:block; }
      summary {
        display:flex;
      }
      .tab-content {
        padding:$spacer0 $gutter !important; 
        border-bottom:1px solid $grey4;
        border-left:1px solid $grey4;
        border-right:1px solid $grey4;
        overflow:hidden; 
        background-color:white; 
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        > * {
          transition:opacity $transition;
          opacity:0; 
        }
        figure.image {
          flex-direction: column;
        }
      }
      &[open] {
        summary {
          --background-colour:#{$blue-dark};
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          svg {
            transform:rotate(180deg);
          }
        }
        .tab-content > * {
          // --transition-delay : 1s; 
          opacity:1; 
        }
      }
    }
  }
}

tabbed-content {

  display:flex;
  flex-direction: column;
  width: 100%; // THIS MIGHT BREAK THINGS. NEEDS CHECKING!

  > nav {
    button {
      transition:$transition;
      &[aria-selected="true"] { pointer-events: none;}
      svg { width:10px; height:10px; }

    }
  }
  summary {
    display:none; 

    &::marker { display:none; }
    --font-colour : #{white};
    --background-colour : #{$primary};

    &, .heading {
      color:var(--font-colour);
      @include font-lato(bold);
      @include heading-h4();
    }

    .heading { margin-bottom:0; }

    background-color:var(--background-colour);

    position:relative;
    cursor: pointer;
    padding-top:$gap; 
    padding-bottom:$gap;   
    padding-left:$gutter; 
    padding-right:$gutter;
    border-radius: $border-radius;  
    align-items:center;
    transition:background-color $transition, border-radius $transition;
    outline: none;
    justify-content: space-between;

    svg {
      height:8px; 
      transition:transform $transition;
      position: absolute;
      right:$gutter;
      top:calc(50% - 4px);
    }

  }

  summary:hover, details[open] summary:hover {
    --background-colour:#{$blue-light};
  }

  .tab-content {
    // display:none; 
    grid-gap:$spacer0 0;
    outline: none;
    position:relative; 
    grid-auto-rows: min-content;
    &:not([hidden]) {
      display:grid;
    }
    .heading-h4 { @include heading-h3(); color:$red; }
    .heading-h5 { @include heading-h4(); }
    .table-container{
      display: inline-flex;
      flex-direction: column;
      &.halves{
        max-width: 40%;
        margin: 0 5% 0 0;
        &:nth-of-type(2n){
          margin: 0 0 0 5%;
        }
      }
      &.thirds{
        max-width: 30%;
        &:nth-of-type(3n - 1){
          margin: 0 5%;
        }
      }
    }
  }

  table-container {
    width:100%;
  }

  &:not([data-style="3"]).in-view > nav button[aria-selected="true"] {
    color:$secondary;
  }

  @media (min-width: 980px) and (max-width: 1300px), (max-width: 700px) {
    &:not(.markets) {
      @include accordion()
    }
  }
  &[data-style="4"] {
    @include accordion()
  }
  @include break-in(1000px) {
    &.markets { @include accordion() }
  }

}

// Tab Style One ---------------------------------------------------------------
// Left hand sidebar with vertically aligned buttons and the content on the right
tabbed-content[data-style="1"] {
  --side-nav-width : 200px;
  display:grid;
  grid-template-columns: var(--side-nav-width) 1fr;
  grid-gap:$gutter;
  > nav {
    display:flex;
    flex-direction: column;
    position: sticky;
    height: fit-content;
    top:calc(var(--breadcrumb-height) + var(--anchors-height) - #{$gutter});

    button {
      padding:$gap 0;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg { margin-left:$gap; }
      + button { 
        border-top:1px solid $grey4; 
      }
    }
  }

}

// Tab Style Two ---------------------------------------------------------------
// Stylised and centred tabs on the top with the content underneath
tabbed-content[data-style="2"] {
  > nav {
    display:flex;
    justify-content: center;
    button {
      padding:$gap2 $gutter2; 
      text-align: center;
      border:1px solid $grey4;
      border-bottom: none;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      margin-left:$gap0; 
      margin-right:$gap0; 
      font-size:font-size(14);
      background-color: white;
      color:$primary;
      transition: color $transition, background-color $transition, border-color $transition;
      svg { display:none; }
      @include hover {
        color:$secondary;
      }
      &[aria-selected="true"] {
        background-color: $grey3;
        border-color:transparent;
      }
    }
  }
  .tab-content { 
    padding:$spacer $gutter;
    background: linear-gradient($grey3, white);
  }
  &.inview nav button[aria-selected="true"] {
    background-color: $grey3;
    color:$secondary;
  }
  &:not(.allow-breakpoint-restyling)[data-count="2"] {
    @include break-in('small') {
      [role="tab"] { width:50%; padding:$gap; }
    }
  }
}

// Tab Style Three ------------------------------------------------------------
// Pill-like buttons bar at the top with the content underneath
tabbed-content[data-style="3"] {
  > nav {
    display:flex;
    justify-content: center;
    margin-bottom:$gap; 
    border-radius: 50px;
    border:1px solid $grey4;
    align-self: center;
    background-color: white;
    position: relative;
    button {
      position: relative;
      text-align: center;
      border:none !important; 
      text-transform: uppercase;
      font-size:font-size(14);
      border-radius: inherit;
      z-index:1;
      transition: color $transition, background-color $transition;
      svg { display:none; }
      padding:$gap $gutter; 
      &[aria-selected="true"] {
        color:white; 
      }

      &:not([aria-selected="true"]) {
        @include hover {
          color:$red;
        }
      }
    }
    &:not(.has-marker) button[aria-selected="true"] {
      background-color:$blue; 
    }
    mark {
      @include mark-reset();
      position: absolute;
      top:0; left:0; 
      height:100%;
      background-color: $blue;
      width: 50%;
      border-radius: inherit;
      pointer-events: none;
      opacity: 0;
      transition: opacity $transition;
      &.active { 
        opacity: 1;
      }
    }
  }
  .tab-content { 
    padding:$gutter 0;
  } 
}
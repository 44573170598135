@use '_modules' as *;

modal-wrapper {

  --wrapper-background-colour : #{rgba($white, 0.95)};
  --container-background-colour : white;

  --min-width:300px;
  --max-width:#{$break-medium};
  --width:clamp(var(--min-width), 90vw, fit-content);

  --min-height:600px;
  --max-height:calc(100vh - #{$gutter2});
  
  --height:clamp(var(--min-height), 90vw, fit-content);

  @supports ( --v:-webkit-fill-available ) {
    --max-height:-webkit-fill-available;
  }

  --close-button-size : 30px; 
  --nav-button-size : 40px; 

  position:fixed;
  inset:0;
  height:100%; 
  width:100%; 
  background-color:var(--wrapper-background-colour);
  z-index:10;
  display:flex;
  place-items: center;
  place-content: center;
  transition: opacity 0.5s $ease-in-out;
  overscroll-behavior: contain;

  &.loading { 
    opacity:0; 
    pointer-events: none;
    transition: opacity 0.3s $ease-in-out 0.3s;
    modal-container {
      opacity:0; 
      transform:translateY(2vh); 
      transition: opacity 0.3s $ease-in-out, transform 0.3s $ease-in-quart;
    }
  }

  button.close {
    position:absolute;
    top:$gutter; 
    right:$gutter; 
    width:var(--close-button-size); 
    height:var(--close-button-size); 
    svg { 
      border:2px solid var(--secondary, #{$red});
      border-radius: 50%;
      circle, path { transition:0.3s $ease-in-out; }
      --fill:var(--secondary, #{$red});
    }
    @include hover {
      svg { 
        --fill:white;
        --stroke:var(--secondary, #{$red});
      }
    }
  }

  button.navigation {
    position:absolute;
    width:var(--nav-button-size); 
    height:var(--nav-button-size); 
    top:calc(50% - (var(--nav-button-size) / 2));
    background-color:var(--secondary, #{$red});
    border-radius: 50%;
    border:2px solid var(--secondary, #{$red});
    color:white; 
    display:flex;
    place-content: center;
    place-items: center;
    transition:color $transition, background-color $transition, transform 0.2s $ease-out-back;
    &.previous { left : $gutter; }
    &.next { right : $gutter; }
    svg { 
      width:50%;
      height:50%; 
    }
    @include hover {
      transform: scale(1.1);
      color:var(--secondary, #{$red});
      background-color: white;
    }
  }
}

modal-container {

  transform:translateY(0);
  transition: opacity 0.5s $ease-in-out 0.3s, transform 0.5s $ease-out-quart 0.3s;
  width:var(--width);
  height:var(--height);
  max-height:var(--max-height);
  max-width:var(--max-width);
  border:1px solid $grey4; 
  border-radius:$border-radius;
  background-color:var(--container-background-colour);
  position: relative;
  display: flex;
  flex-direction: column;
  margin:$gutter;
  overscroll-behavior: contain;

  @supports not ( width:clamp(1px, 5vw, 10px)) {

    --width : 60vw;
    max-width:var(--max-width);
    min-width:var(--min-width);

    --height : 80vw;
    max-height:var(--max-height);
    min-height:var(--min-height);
  }

  &:empty {
    width:calc(var(--max-width) / 2);
    height:calc(var(--max-height) / 2);
    &:after {
      @include loader(20px);
      @include loader-animation();
    }
  }

  > button.close {
    position: relative;
    order:-1;
    align-self: flex-end;
    top:initial;
    right:initial;
    margin-top:calc((-1 * var(--close-button-size)) / 2);
    margin-bottom:calc((-1 * var(--close-button-size)) / 2);
    margin-right:calc((-1 * var(--close-button-size)) / 2);
  }

}

modal-content {
  display: block;
  position: sticky;
  overscroll-behavior: contain;
  width:inherit;
  height:inherit;
  max-height:inherit;
  max-width:inherit;
  overflow-y: auto;
  overflow-x: hidden;
  padding:$gutter;
  > * { max-width:100%; }
}

modal-wrapper[data-type="image"] .image { 
  max-height:calc(var(--max-height) - #{$gutter6});
  border:none;
  img {
    max-height:calc(100vh - #{$gutter3} - #{$gutter4});
    width: auto;
    height: auto;
    margin:auto;
  }
}

modal-wrapper[data-type="default"] {
  modal-content > img {
    &[src*=".svg"] { width:100%; height:auto; }
  }
}

modal-wrapper[data-type="video"] {
  --wrapper-background-colour : #{rgba(black, 0.95)};
  modal-container { 
    @include video-container();
    background-color:white;
    padding:$gap; 
    modal-content {
      height: 100%;
      border-radius: $border-radius;
    }
  }
}

.open-in-modal {
  cursor:pointer;
  transition:opacity $transition;
  &:after { @include loader(20px, white); }
  &.loading {
    pointer-events: none;
    &:after { @include loader-animation(); }
  }
}

img.open-in-modal, .open-in-modal img { 
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

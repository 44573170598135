
@use '_modules' as *;

.content-block {

  display:grid; 
  grid-gap:$gutter;
  grid-template-columns: repeat(var(--column-count), 1fr);

  &:empty { display:none; }

  &.blowout.themed ~ &:not(.blowout).themed { margin-top:$spacer0; }
  &:not(.blowout).themed {
    border-radius: $border-radius;
    &:not(:last-of-type) { margin-bottom:$spacer0; }
  }

  + .content-block {
    box-shadow: 0px -1px var(--border-top-colour, #{$grey4})
  }

  h2, h3, h4, h5, h6 {
    margin-bottom:$gap; 
  }

  &.grey { background:$grey3; }
  &.red  { background:$red; }
  &.blue { background:$blue; }

}
////////////////////////////////////////////////////////////////////////////////
// Social Media
////////////////////////////////////////////////////////////////////////////////

@use '_modules' as *;

social-media {
  
  --icon-colour : #{$white};
  --icon-size   : 20px; 

  display:flex; 
  align-items: center;

  a {

    margin-left:$gap; 
    display:flex !important; 

    svg {
      width  : var(--icon-size);
      height : var(--icon-size);
      fill   : var(--icon-colour);
      transition : $transition; 
    }

    @include hover {
      @each $name, $colour in $colours-social-media {
        &.#{$name} { --icon-colour : #{$colour}; }
      }
    }

    span { display:none; }

  }

}

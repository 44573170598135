@use '_modules' as *;

table-container {
  display: grid;
  grid-gap:$gap; 
  // width: fit-content;

  > div {
    // @at-root tabbed-content & { max-height: calc(100vh - var(--navigation-height, 200px)); }
    overflow: auto;
    border-radius: $border-radius;
    display:grid;     
    &:not(:hover) {scroll-behavior: smooth; }

    @include scroll-styling
  }
  h3 { margin-bottom:0 !important; }

  table.table {
    width:auto; 
    grid-row: 1;
    grid-column: 1;

    &.fake {
      pointer-events: none;
      will-change: transform; 
      transform:translateX(var(--x));
      width:calc(100% - var(--x));
      overflow: hidden;
      background-color: initial;
      tbody tr { 
        
        td { background:white; border-right:1px $grey4 dashed; }
        &:nth-of-type(odd) td { background : $grey3; }
      }
      thead tr th:first-of-type { border-right:1px rgba(white, 0.1) dashed; }
      thead tr th:not(:first-of-type), tbody tr td:not(:first-of-type) { visibility: hidden; }
    }
  }
  @supports (writing-mode: vertical-lr) {
    &.enable-vertical-headings table.table thead tr th:not(:first-of-type) span {
      display: inline-block;
      writing-mode: vertical-lr;
      max-width:50px; 
      text-align: right;
      transform: rotate(180deg);
    }
  }

  $button-size : 40px; 

  nav {
    align-items: flex-end;
    justify-content: flex-end;
    grid-row:1; 
    grid-column:2; 
    display:none;
    button.navigation {
      align-self: flex-end;
      + button { margin-left:$gap0; }
    }
    + div {
      grid-column: 1 / span 2;
    }
  }

  &.scrolled-right button.next, &.scrolled-left button.previous { 
    pointer-events:none;
  }

  &.enable-scroll-buttons nav { 
    display:flex;
  }

  button.navigation {
    --button-size : #{$button-size}; 
    background-color: white;
    border:1px solid $grey4;
    border-radius: 50%;
    z-index:1;
    width: var(--button-size);
    height: var(--button-size);
    position: relative;
    display:flex;
    place-items: center;
    place-content: center; 
    align-self: center;
    color:$grey2;
    transition:background-color $transition, color $transition, opacity $transition, transform 0.3s $ease-out-back;
    &.previous svg { margin-left:-2px; }
    &.next svg { margin-left:2px; }
    svg {
      width:calc(var(--button-size) / 2.5);
      height:calc(var(--button-size) / 2.5);
    }
    @include hover {
      transform: scale(1.1);
      background-color:$blue;
      color:white; 
      opacity:1 !important;
      border-color:transparent;
    }
  }

}

table.table {

  width:100%; 
  background-color:white; 
  border-collapse:collapse;
  border-radius: $border-radius;
  text-align: left;
  position: relative;
  border-collapse: collapse;
  thead {
    tr {
      th {
        background-color:$primary;
        padding:$gap;
        color:white; 
        white-space: nowrap;
        &:first-of-type {
          border-top-left-radius: $border-radius;
          overflow: hidden;
        }
        &:last-of-type {
          border-top-right-radius: $border-radius;
          overflow: hidden;
        }
      }

    }
  }

  tbody {
    tr {     
      td {
        padding:$gap0 $gap;
        position: relative;
        &:first-of-type { min-width:200px;}
        strong { font-weight: 700; color:$grey2; }
        a { 
          color:$blue; 
          text-decoration: underline; 
          @include hover {
            color:$red; 
          }
        }
        svg { 
          --fill : #{$primary};
          &.cross { --fill : #{$red}; }
          &.tick { --fill : #{$green}; }
          width:1em; height:1em; 
        }
      }
    }
  }

  tr { 
    position:relative;
    &:not(:last-of-type) { border-bottom:1px solid $grey4; }
    // &:nth-of-type(odd):before {
    //   content:"";
    //   position: absolute;
    //   left:0; right:0; 
    //   height:100%; 
    //   width:100%; 
    //   background-color:$grey3; 
    // }
    &:nth-of-type(odd) {
      background-color:$grey3; 
    }
  }


  &.alternating-row-colours tbody tr:nth-child(odd) { background-color: rgba(black, 0.1); }
  &.last-column-is-green tbody tr td:last-of-type { 
    background-color: $blue; 
    color:white; 
  }
  // &.last-column-is-green thead tr th:last-of-type { 
  // }
  // &.alternating-row-colours.last-column-is-green tbody tr:nth-child(odd) td:last-of-type { background-color: darken($green3, 3); }

}

.dark table-container, .dark.content-block .tab-content {
  > h3 { color:white; }
  > div { border-radius: initial; }
  table.table {
    color:white; 
    background-color:initial;
    tr th {
      background-color:rgba(white, 0.15);
    }
    tr {
      border:none !important;
      &:nth-of-type(odd) {
        background-color:rgba(white, 0.15);
      }
    }
  }
}
@use '_modules' as *;

body > background-image {
  pointer-events: none;
  @include image-srcset();

  --background-theme-one : #{$blue};
  --background-theme-two : #{$blue2};

  &::after {
    content:"";
    position:absolute; 
    top:0; 
    left:0; 
    width:100%;
    height:100%; 
    opacity:0.7;
    background: linear-gradient(to bottom, var(--background-theme-one), var(--background-theme-two));
  }

  &.none {
    &::after { content:initial; }
  }

  &.red {
    --background-theme-one : #{$red};
    --background-theme-two : #{$red};
  }

  &.black {
    --background-theme-one : #{$black};
    --background-theme-two : #{$black};
  }

  &.tile {
    background-repeat: repeat;
    background-size: auto;
    img { display:none; }
  }

  &:not(.tile) {
    max-height: 100vh;
  }

  &.behind-header {
    // filter: grayscale(20%);
    &::after {
      opacity:1;
      background: rgba(black, 0.5);
      // background: initial;;
      backdrop-filter: blur(10px);
      mask-image: linear-gradient(to right, black 20%,transparent 100%);
    }
  }
 
}

body.mobile > background-image, body.mobile > background-video {
  max-height: initial;
}
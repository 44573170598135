@use '_modules' as *;

body > background-video {

  pointer-events: none;
  @include image-srcset();
	
  video {
    display         : block;
    position        : absolute;
    object-fit      : cover;
    object-position : var(--image-position, center 33.33%);
		width           : var(--image-size, var(--image-width-size, 100%));
		height          : var(--image-size, var(--image-height-size, 100%)); 
		max-width       : var(--image-size, var(--image-width-size, 100%));
		max-height      : var(--image-size, var(--image-height-size, 100%));
		top             : 0; 
		left            : 0;
	}

  &::after {
    content:"";
    position:absolute; 
    top:0; 
    left:0; 
    width:100%;
    height:100%; 
    background: linear-gradient(to bottom, var(--background-theme-one, transparent), var(--background-theme-two, black));
  }

}
@use '_modules' as *;

@use "sass:list";
@use "sass:string";

$parentSelector : '.article';

// @include contains('title', 'subheading', 'not:introduction') { ... }

@mixin contains($names...) {

  $parent :if(list.nth(list.nth(&, 1), 1) == ':root', '', &);

  $selector : '';
  
  @if ( type-of($parent) == 'list') {
    @each $segment in list.nth($parent, 1) {
      @if ( $segment != $parentSelector ) {
        $selector : $selector + ' ' + $segment
      }
    }
  }

  $attributes : '';

  @each $name in $names {
    @if ( string.index($name, 'not:') ) {
      $name : string.slice($name, 5);
      $attributes : $attributes + ':not([data-contains*="#{$name}"])';
    } @else {
      $attributes : $attributes + '[data-contains*="#{$name}"]';
    }
  }

  @at-root #{$parentSelector}#{$attributes} #{$selector} {
    @content;
  }
}

#{$parentSelector} {

  display: flex;

  .container {

    // display:grid; 
    // grid-auto-columns: 1fr;
    // grid-gap:$gap;
    display:flex;
    flex-direction: column;
    padding:$gutter; 
    background-color: white;
    border:1px solid $grey3;
    border-radius: $border-radius;
    overflow: hidden;
    align-content: flex-start;
    width:100%;

    > * { 
      order:2; 
      margin-bottom:$gap;
    }

    .heading {
      @include heading-h3;
      line-height:1.2;
      transition: color $transition; 
    }

    .subheading {
      @include heading-h4;
      // color:$secondary;
      color:$font-colour;
      max-width: 75ch;

      // This needs reviewing. It won't work on all variations of the articles
      margin-bottom:auto;
      + .timestamp { margin-top:$gap;}
    }

    .introduction {
      font-size:font-size(14);
      max-width: 75ch;
    }

    .button {
      justify-self: flex-start;
      margin-bottom:0; 
      margin-top: auto;
    }

    time {
      font-weight: bold;
      font-size:font-size(14);
    }

    .author {
      --photo-size : 40px; 
      display:flex;
      position:relative; 
      flex-direction: column;
      justify-content: center;
      .name {
        font-weight: bold;
        white-space: nowrap;
        color:$blue; 
        font-size:font-size(14);
        line-height:1.4;
        order:1;
        em { font-size:font-size(12); margin-right:5px;  }
        min-height:var(--photo-size);
        display:flex;
        align-items: center;
      }
      .timestamp {
        white-space: nowrap;
        font-size:font-size(14);
        line-height:1.4;
        order:2;
        &, ~ .name { min-height:calc(var(--photo-size) / 2); }
      }
      .image {
        width:var(--photo-size);
        height:var(--photo-size);
        top:calc(50% - var(--photo-size)/2);
        border-radius: 50%;
        left:0; 
        position: absolute;
        border:1px solid $grey4;
        ~ * {
          padding-left:calc(var(--photo-size) + #{$gap}); 
        }
      }
    }

    > .image { 
      order:1;
      margin-bottom:$gutter; 
      height:var(--image-height, 180px); 
      width:calc(100% + #{$gutter3});
      left:$gutter-;
      margin-top:$gutter-;
    }

  }

  a {
    transition: border-color $transition;
    @include hover {
      border-color:$primary;
    }
  }

  @include contains('title', 'timestamp', 'author', 'button', 'image', 'not:subheading', 'not:introduction') { 
    .container {
      .timestamp { margin-top:auto; }
    }
  }
  @include contains('title', 'image', 'not:timestamp', 'not:author', 'not:button', 'not:subheading', 'not:introduction') { 
    .container {
      .heading { margin-bottom:auto; @include heading-h3; line-height:1.2; }
      > .image {
        --image-height : 130px;
      }
    }
  }

  @include contains('serviceCentres'){
    .container{
      div{
        margin-bottom: $gap;
      }
      h4{
        margin-bottom: 0;
      }
    }
  }

}
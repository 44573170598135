@use "sass:math";

@use '_modules' as *;

figure.image.static {
  border-radius: $border-radius;
  background-color:white;  
  border:1px solid $grey3;
  height: fit-content;
  img { 
    --image-height-size : auto; 
    position:relative; 
  }
  figcaption {
    font-size:font-size(12);
    font-style: italic;
    padding:$gap0 $gap;
  }
  &.no-caption {
    border:none; 
  }
  &.crop-image {
    figcaption {
      bottom:0; 
      position: absolute;
      left:0; 
      width:100%; 
      background-color: white;
    }
    @include aspect-ratio;
    --aspect-ratio : inherit; 
    img { --image-height-size : 100%; }
  }
}

.image.load-in-view img {
  transition: opacity 0.5s $ease-in-out;
  &:not([src]) { opacity:0; }
}

images-container {
  width:100%; 
  display:grid; 
  grid-gap:$gutter0; 
  grid-template-columns: repeat(auto-fill, minmax(calc(var(--main-max-width) / (var(--images-per-row) + 1)), 1fr));
  &.crop-images {
    .image { 
      @include aspect-ratio('4/3');
      --aspect-ratio : inherit; 
      img {
        --image-height-size : 100%;
        @supports not (aspect-ratio: 1/1) { position:absolute; }
      }
      figcaption {
        bottom:0; 
        position: absolute;
        left:0; 
        width:100%; 
        background-color: white;
      }
    }
  }
}
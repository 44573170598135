@use '_modules' as *;

#anchors-bar {

  background-color:$grey3;
  transition: height $transition, top $transition;
  display:flex;
  align-items: center;
  max-width: 100vw;

  .container {
    display:flex;
    align-items: center;
    overflow:hidden; 
    height:100%; 
  }

  @include browser-without-feature('touch') { 
    @include break-in(max) {
      width: 100vw;
      margin-left:$gutter2-;
      .container {
        overflow-x: auto;
        scroll-behavior: smooth;
        padding-left:$gutter;
        padding-right:$gutter;
      }
    }
  }

  @include browser-feature('touch') { 
    width: calc(100% + #{$gutter2} + #{$gap0});
    margin-left:$gutter-;
    &::before { content:initial; }
    .container {
      overflow-x: auto;
      padding-left:$gutter;
      padding-right:$gutter;
    }
  }

  &::before { border-bottom:1px solid $grey4; }

  a {
    grid-row:1; 
    font-size : font-size(14);
    text-transform: uppercase;
    font-weight:bold; 
    white-space: nowrap;
    display:flex;
    place-items: center; 
    position: relative;
    height: 100%;
    color:$blue; 
    + a { margin-left:$gutter; }
    @include hover {
      color:$secondary;
    }
    &::after {
      content:"";
      bottom:0;
      left:0; 
      width: 100%;
      height:4px; 
      background-color: $secondary;
      position: absolute;
      transform:scaleY(0);
      transition:transform $transition-duration $transition-ease 0.2s;
    }

    &.current {
      color:$secondary;
      &::after {
        transform:scaleY(1);
      }
    }
  }

}
